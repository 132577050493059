
import React from 'react'
import { ServizioResult } from '../../../api/servizi';
import { Dimensions } from 'react-native';
import { Divider, FlatList, View } from 'native-base';
import { ChiamateModalWeb, RowChiamata } from '../../chiamate';
import { getChiamate } from '../../../api/chiamate';
import { useColorMode } from 'native-base';

type Props = {
    servizioSelezionato: ServizioResult,
}


export const ChiamateModal = ({ servizioSelezionato }: Props) => {
    const { data: chiamate, isLoading, isError } = getChiamate({
        variables: {
            codice_servizio: servizioSelezionato?.codice_servizio,
            limit: 24,
            search: "",
            scopo: ""
        }
    });
    const {colorMode} = useColorMode()

    const [selected, setSelected] = React.useState(null)

    return (
        <View w="full" h="full">
            <FlatList
                maxH={Dimensions.get('window').height - 100}
                contentContainerStyle={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(25rem, 1fr)", gap: "1rem",maxWidth: "1600px", margin: "auto" }}
                data={chiamate}
                ItemSeparatorComponent={() => <Divider />}
                renderItem={({ item, index }) => (
                    <RowChiamata colorMode={colorMode} selected={selected} setSelected={(item: any) => setSelected(item)} item={item} />
                )
                }
                h="100%"
                keyExtractor={(_item, index) => `${index}`}
            />
         <ChiamateModalWeb setSelected={setSelected} selected={selected} />
        </View>
    );
}
