import React from 'react';
import { useMMKVString } from 'react-native-mmkv';
import { useColorMode } from 'native-base';
import { storage } from './utils';
import { StatusBar } from 'react-native';
import { colors } from '../ui/theme/generic-theme/colors';

const SELECTED_THEME = 'SELECTED_THEME';
export type ColorSchemeType = 'light' | 'dark' | 'system';
/**
 * this hooks should only be used while selecting the theme
 * This hooks will return the selected theme which is stored in MMKV
 * selectedTheme should be one of the following values 'light', 'dark' or 'system'
 * don't use this hooks if you want to use it to style your component based on the theme use useColorScheme from nativewind instead
 *
 */
export const useSelectedTheme = () => {
    // Take native base color scheme
    const { setColorMode  } = useColorMode();
  // this is the selected theme from the MMKV
  const [theme, _setTheme] = useMMKVString(SELECTED_THEME, storage);

  const setSelectedTheme = React.useCallback(
    (t: ColorSchemeType) => {
        setColorMode(t);
      _setTheme(t);
      // Update the status bar color
      StatusBar.setBackgroundColor(t === "dark" ? colors.dark : colors.white);
    },
    [setColorMode, _setTheme]
  );

  const selectedTheme = (theme ?? 'system') as ColorSchemeType;
  return { selectedTheme, setSelectedTheme } as const;
};