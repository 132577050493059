import Constants from "expo-constants";
import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";
import axios from "axios";

export type RecuperoPasswordVariables = {
    variables:{
        email: string
    }
}

export const authRecuperoPassword = createMutation<void ,RecuperoPasswordVariables, AxiosError>({
    mutationFn: async ({ variables }) => {
        
       return await axios.post(Constants.expoConfig?.extra?.apiUrl+"/api/v1/users/reset_password/", { email: variables.email }).then((response) => response.data);

    }
});

export default authRecuperoPassword;

