import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const Loader = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    fill="none"
    {...props}
  >
    <Path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.5 4.417v8.833M26.5 39.75v8.833M10.887 10.887l6.25 6.25M35.863 35.863l6.25 6.25M4.417 26.5h8.833M39.75 26.5h8.833M10.887 42.113l6.25-6.25M35.863 17.137l6.25-6.25"
    />
  </Svg>
)
export default Loader
