import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createSelectors } from "../utils";

export interface PanoramicaStore {
    tipo_statistica: any;
    dataSet: any;
    setTipoStatistica: (tipo:string) => void;
    setDataSet: (data: any) => void;
    enableScroll: boolean;
    setEnableScroll: (enableScroll: boolean) => void;
}

const usePanoramicaStore = create<PanoramicaStore>((set) => ({
    tipo_statistica: "6M",
    dataSet: [],
    setTipoStatistica: (tipo:string) => set({ tipo_statistica: tipo }),
    setDataSet: (data: any) => set({ dataSet: data }),
    enableScroll: true,
    setEnableScroll: (enableScroll: boolean) => set({ enableScroll: enableScroll }),
}));

export const usePanoramica = createSelectors(usePanoramicaStore);