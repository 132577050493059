import BottomSheet from '@gorhom/bottom-sheet';
import React, {useCallback, useMemo, useRef} from 'react';
import { colors } from '../../theme/generic-theme/colors';
type Props = {
    children: React.ReactNode,
    isOpen: boolean,
    onClose: () => void,
    footerComponent: (props: any) => JSX.Element | undefined ,
    colorMode: "light" | "dark" | null | undefined,
    snap: string[]
}

export const BottomActionSheet = (
{ 
   children,
    isOpen,
    onClose,
    footerComponent,
    colorMode,
    snap
}: Props
) => {

     // variables
  const snapPoints = useMemo(() => snap, []);



  return (
    <BottomSheet 
        onChange={
            (index) => {
                if(index === -1){
                    onClose()
                }
            }
        }
        footerComponent={footerComponent}
        snapPoints={snapPoints}
        index={isOpen ? 0 : -1}
        animateOnMount={true}
        enablePanDownToClose={true}
        enableContentPanningGesture={true}
        enableHandlePanningGesture={true}
        detached={true}
        handleIndicatorStyle={{
            backgroundColor: colorMode === "dark" ? colors.white : colors.dark,
        }}
        handleStyle={{
            height: 20,
            }}
        backgroundStyle={{
            backgroundColor: colorMode === "dark" ? colors.dark : colors.white,
            shadowOffset: {
                width: 0,
                height: -2,
            },
            shadowOpacity: 0.3,
            shadowRadius: 5,
            shadowColor: colorMode === "dark" ? colors.white : colors.dark,
            elevation: 3,
            }}
        >
       {children}
    </BottomSheet>
  );
};

