import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const PhoneRinging = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 50"
    {...props}
  >
    <Path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M44 34.545v6.125a4.158 4.158 0 0 1-.335 1.652c-.223.521-.55.99-.961 1.373-.41.385-.895.678-1.423.86a3.924 3.924 0 0 1-1.641.198 39.063 39.063 0 0 1-17.26-6.268 39.415 39.415 0 0 1-12-12.25 40.95 40.95 0 0 1-6.14-17.7 4.163 4.163 0 0 1 .193-1.672c.177-.537.462-1.03.836-1.45.375-.418.83-.752 1.338-.982a3.93 3.93 0 0 1 1.613-.348h6c.97-.01 1.911.341 2.647.988a4.108 4.108 0 0 1 1.353 2.524c.253 1.96.723 3.885 1.4 5.737.269.73.327 1.525.168 2.288a4.11 4.11 0 0 1-1.068 2.02l-2.54 2.593a32.341 32.341 0 0 0 12 12.25l2.54-2.593a3.977 3.977 0 0 1 1.978-1.09 3.924 3.924 0 0 1 2.242.171 25.25 25.25 0 0 0 5.62 1.43c.971.14 1.859.639 2.493 1.403.634.764.971 1.74.947 2.741ZM28.1 4.083a17.851 17.851 0 0 1 10.794 5.232 18.529 18.529 0 0 1 5.206 10.98M28.1 12.25a9.94 9.94 0 0 1 5.18 2.843A10.281 10.281 0 0 1 36 20.417"
    />
  </Svg>
)
export default PhoneRinging
