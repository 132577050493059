import { Stack, VStack, Text, Divider, ScrollView, Box, HStack } from 'native-base'
import React from 'react'
import Loader from '../../../ui/svg/icons/loader'
import Timer from '../../../ui/svg/icons/timer'
import Voice from '../../../ui/svg/icons/voice'
import MusicalNotes from '../../../ui/svg/icons/musical-notes'
import { Platform, useWindowDimensions } from 'react-native'

type Props = {
    servizioSelezionato: any,
    colorMode: "dark" | "light" | null | undefined,
}

export default function MessaggiAudio({
    servizioSelezionato,
    colorMode
}: Props) {

    const benvenuto = servizioSelezionato?.messaggi?.find((mess: any) => mess.tipo_messaggio === "Benvenuto")?.testo
    const presentazione = servizioSelezionato?.messaggi?.find((mess: any) => mess.tipo_messaggio === "Presentazione")?.testo
    const attesaInCoda = servizioSelezionato?.messaggi?.find((mess: any) => mess.tipo_messaggio === "Attesa in coda")?.testo
    const fuoriOrario = servizioSelezionato?.messaggi?.find((mess: any) => mess.tipo_messaggio === "Fuori orario")?.testo

    const dimensions = useWindowDimensions();


    return (
        <ScrollView contentContainerStyle={Platform.OS !== "web" && {
            paddingBottom: 300,
            minHeight: "100%"
        }}>
            <Stack p="2" px="4" w="full" mx="auto" space={6} mt="4">
                <HStack p="2">
                    {dimensions.width >= 768  &&  <MusicalNotes color={colorMode === "light" ? "#22272E" : "white"} width={"60"} height={"60"} style={{
                        marginRight: 5
                    }} />}
                    <VStack maxW={"80%"}  >
                        <Text fontSize={"xl"} fontWeight="600" color="dark" _dark={{ color: "white" }} >
                            Benvenuto
                        </Text>
                        <Text fontStyle={!benvenuto ? "italic" : "normal"} fontSize={"md"} color="dark" _dark={{ color: "white" }} >
                            {benvenuto ? benvenuto : "Non impostato"}
                        </Text>
                    </VStack>
                    <Box w="4" h="4" bg={benvenuto ? "success.200" : "danger.200"} rounded="full" position="absolute" top="0" left="0" />
                </HStack>
                <Divider />
                <HStack p="2">
                {dimensions.width >= 768  && <Voice color={colorMode === "light" ? "#22272E" : "white"} width={"60"} height={"60"} style={{
                        marginRight: 5
                    }} />}
                    <VStack maxW={"80%"} >
                        <Text fontSize={"xl"} fontWeight="600" color="dark" _dark={{ color: "white" }} >
                            Presentazione
                        </Text>
                        <Text fontStyle={!presentazione ? "italic" : "normal"} fontSize={"md"} color="dark" _dark={{ color: "white" }} >
                            {presentazione ? presentazione : "Non impostato"}
                        </Text>
                    </VStack>
                    <Box w="4" h="4" bg={presentazione ? "success.200" : "danger.200"} rounded="full" position="absolute" top="0" left="0" />
                </HStack>
                <Divider />
                <HStack p="2">
                {dimensions.width >= 768  &&  <Loader color={colorMode === "light" ? "#22272E" : "white"} width="60" height={"60"} />}
                    <VStack maxW={"80%"} >
                        <Text fontSize={"xl"} fontWeight="600" color="dark" _dark={{ color: "white" }} >
                            Attesa In Coda
                        </Text>
                        <Text fontStyle={!attesaInCoda ? "italic" : "normal"} fontSize={"md"} color="dark" _dark={{ color: "white" }} >
                            {attesaInCoda ? attesaInCoda : "Non impostato"}
                        </Text>
                    </VStack>
                    <Box w="4" h="4" bg={attesaInCoda ? "success.200" : "danger.200"} rounded="full" position="absolute" top="0" left="0" />
                </HStack>
                <Divider />
                <HStack p="2">
                {dimensions.width >= 768  && <Timer color={colorMode === "light" ? "#22272E" : "white"} width="60" height={"60"} />}
                    <VStack maxW={"80%"} >
                        <Text fontSize={"xl"} fontWeight="600" color="dark" _dark={{ color: "white" }} >
                            Fuori Orario
                        </Text>
                        <Text fontStyle={!fuoriOrario ? "italic" : "normal"} fontSize={"md"} color="dark" _dark={{ color: "white" }} >
                            {fuoriOrario ? fuoriOrario : "Non impostato"}
                        </Text>
                    </VStack>
                    <Box w="4" h="4" bg={attesaInCoda ? "success.200" : "danger.200"} rounded="full" position="absolute" top="0" left="0" />
                </HStack>
            </Stack>
        </ScrollView>
    )
}