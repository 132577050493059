import React from 'react'
import { MotiView } from 'moti'
import { Center, Heading, Text, Pressable, View } from 'native-base'
import MailSentIcon from '../../../ui/components/animated-email-sent/animated-email-sent'
import { openInbox } from "react-native-email-link";
import { colors } from '../../../ui/theme/generic-theme/colors';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Platform } from 'react-native';

type Props = {
    navigation: any,
    route: any
}

export default function RecuperoPasswordSuccess({
    navigation,
    route
}: Props) {

    const handleOpenInbox = () => {
        openInbox()
    }

    return (
        <SafeAreaView style={{ width: "100%", height: "100%", backgroundColor: route.params?.colorMode === "dark" ? colors.dark : "white" }}>
            <MotiView style={{
                width: "100%",
                height: "100%",
                maxHeight: "98%"
            }} from={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Center w="90%" mx="auto" h="full">
                    <View mb="2">
                        <MailSentIcon />
                    </View>
                    <Heading _dark={{
                        color: "white"
                    }} color="success.600" fontWeight={"800"} fontSize={"4xl"} mb="2" fontFamily={"body"}>Email inviata con successo</Heading>
                    <Text color="dark" _dark={{
                        color: "white"
                    }} fontWeight={"400"} fontSize={"lg"} fontFamily={"body"} >
                        Ti abbiamo inviato una email con le istruzioni per il recupero della password.
                        Controlla la tua casella di posta elettronica.
                    </Text>
                    {Platform.OS !== "web"  ? <Pressable mx="4" mt="auto" minW="350"  bg="primary.400" h="55" rounded={"md"} onPress={handleOpenInbox}>
                        {({
                            isHovered,
                            isFocused,
                            isPressed
                        }) =>

                            <Center style={{
                                opacity: isHovered || isFocused || isPressed ? 0.8 : 1,
                                transform: isHovered || isFocused || isPressed ? [{ scale: 0.98 }] : [{ scale: 1 }]
                            }} flex={1}>
                                <Text color="white" fontWeight={"bold"} textAlign={"center"} fontSize={"lg"} >Apri App Email</Text>
                            </Center>
                        }
                    </Pressable> : <Text color="primary.400" fontWeight={"bold"} textAlign={"center"} fontSize={"lg"} >
                        Apri la tua casella di posta elettronica
                    </Text>}
                </Center>
            </MotiView>
        </SafeAreaView>
    )
}