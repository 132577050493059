import { VStack, Avatar, Text, Skeleton, View, HStack } from 'native-base'
import React from 'react'
import { Nominativi } from '../../../api/servizi'
import { useServizio } from '../../../core/store/servizio-store'
import { checkIsJson, getFirstLetterToUppercase } from '../../../utils/utils'

type Props = {
    data: Nominativi,
    isLoading: boolean,
    isError: boolean,
}

export default function ContattoModal({ data, isLoading, isError }: Props) {

    const isSiriaCentralino = useServizio(state => state.isSiriaCentralino);

    if (isLoading || isError) return (
        <VStack minW={"64"} minH={"48"} alignItems={"center"} justifyContent={"center"} space={2} >
            <Skeleton w={"24"} h={"24"} rounded={"full"} />
            <Skeleton w={"48"} h={"8"} rounded={"2xl"} />
            <Skeleton w={"48"} h={"6"} rounded={"2xl"} />
        </VStack>
    )

    return (
        <HStack minH={"32"} alignItems={"center"} justifyContent={"space-evenly"} >
            <Avatar mx="4" bg="info.400" size={"20"} >
                <Text fontSize={"2xl"} fontWeight={"600"} color={"white"}>
                    {getFirstLetterToUppercase(data.descrizione)}
                </Text>
            </Avatar>
            <VStack flex={1}>
                <Text color="dark" maxW={"90%"} _dark={{ color: "white" }} fontFamily={"body"} fontSize={"2xl"} fontWeight={"600"}>
                    {data.descrizione}
                </Text>
                <Text color="dark" _dark={{ color: "white" }} fontFamily={"body"} fontSize={"lg"} fontWeight={"400"}>
                    {isSiriaCentralino ? data.telefono_ica : data.cellulare}
                </Text>
                <Text isTruncated maxW={"90%"} color="dark" _dark={{ color: "white" }} fontFamily={"body"} fontSize={"lg"} fontWeight={"400"}>
                    {isSiriaCentralino ? checkIsJson(data.email_ica) ? checkIsJson(data.email_ica)?.destinatari.join(",") : data.email_ica : data.email}
                </Text>
            </VStack>
        </HStack>
    )
}

export function Contatto({ data, isLoading, isError }: Props) {

    if (isLoading || isError) return (
        <VStack minW={"64"} minH={"48"} alignItems={"center"} justifyContent={"center"} space={2} >
            <Skeleton w={"24"} h={"24"} rounded={"full"} />
            <Skeleton w={"48"} h={"8"} rounded={"2xl"} />
            <Skeleton w={"48"} h={"6"} rounded={"2xl"} />
        </VStack>
    )

    return (
        <VStack my="4" minH={"40"} minW={"48"} maxW={"48"} alignItems={"center"} justifyContent={"space-evenly"} >
            <Avatar mx="2" bg="info.400" size={"xl"} >
                {getFirstLetterToUppercase(data.descrizione)}
            </Avatar>
            <VStack my="2" flex={1}>
                <Text textAlign={"center"} paddingY={"2"} color="dark" _dark={{ color: "white" }} fontFamily={"body"} fontSize={"xl"} lineHeight={"xs"} fontWeight={"600"}>
                    {data.descrizione}
                </Text>
            </VStack>
        </VStack>
    )
}
