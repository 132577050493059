import { Box, HStack, Text } from 'native-base'
import React from 'react'
import { Pressable } from 'react-native'
import { useWindowDimensions } from 'react-native'
import { usePanoramica } from '../../../core/store/panoramica-store';

type Props = {
  width_screen: number,
}

export default function RangeDate({
  width_screen
}: Props) {

  const setTipoStatistica = usePanoramica(state => state.setTipoStatistica);
  const tipo_statistica = usePanoramica(state => state.tipo_statistica);


  return (
    <HStack position={"relative"} top="0" space={4} alignItems={"center"} justifyContent={"space-between"}>
      <Pressable onPress={() => setTipoStatistica("6M")} >
        <Box rounded={"2xl"} bg={tipo_statistica === "6M" ? "info.200" : "transparent"} p="4"  >
          <Text fontWeight={"700"} _dark={tipo_statistica === "6M" ? {color:"white"} : {color: "info.200"}} color={tipo_statistica == "6M" ?  "white" :  "dark"} fontSize={"2xl"} lineHeight={"xs"} >6M</Text>
        </Box>
      </Pressable>
      <Pressable onPress={() => setTipoStatistica("3M")}>
        <Box rounded={"2xl"} bg={tipo_statistica === "3M" ? "info.200" : "transparent"} p="4"  >
          <Text fontWeight={"700"} _dark={tipo_statistica === "3M" ? {color:"white"} : {color: "info.200"}} color={tipo_statistica === "3M" ?  "white"  : "dark"} fontSize={"2xl"} lineHeight={"xs"} >3M</Text>
        </Box>
      </Pressable>
      <Pressable onPress={() => setTipoStatistica("1S")}>
        <Box rounded={"2xl"} bg={tipo_statistica === "1S" ? "info.200" : "transparent"} p="4"  >
          <Text fontWeight={"700"} _dark={tipo_statistica === "1S" ? {color:"white"} : {color: "info.200"}} color={tipo_statistica === "1S" ?  "white"  :  "dark"} fontSize={"2xl"} lineHeight={"xs"} >1S</Text>
        </Box>
      </Pressable>
    </HStack>
  )
}