export const convertDatetoItalian = (date: string) => {

    if (!date) return date;

    const dateObj = new Date(date.split(" - ")[0]);

    //Check if the date is equal to today
    const today = new Date();
    if (dateObj.getDate() === today.getDate() && dateObj.getMonth() === today.getMonth() && dateObj.getFullYear() === today.getFullYear()) {
        return `Oggi`;
    }

    //Check if the date is equal to yesterday
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (dateObj.getDate() === yesterday.getDate() && dateObj.getMonth() === yesterday.getMonth() && dateObj.getFullYear() === yesterday.getFullYear()) {
        return `Ieri`;
    }

    const day = dateObj.toLocaleString('it-IT', { day: 'numeric' });
    const month = dateObj.toLocaleString('it-IT', { month: 'long' });
    const year = dateObj.toLocaleString('it-IT', { year: 'numeric' });

    return `${day} ${month}`;
}


export const getHours = (date: string) => {

    if (!date) return date;

    const timeObj = (date.split(" - ")[1]).substring(0, 5);

    return timeObj;
}

export const formatDate = (date: Date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return year + "-" + month + "-" + day;
}

export const formatDateTimeLeadingZero = (date: Date) => {
    let year = date.getFullYear();

    // Add leading zero if month is less than 10
    let month = (date.getMonth() + 1).toString();
    if (month.length < 2) {
        month = "0" + month;
    }

    //Add leading zero if day is less than 10
    let day = date.getDate().toString();
    if (day.length < 2) {
        day = "0" + day;
    }

    let hours = date.getHours().toString();
    let min = date.getMinutes().toString();

    return year + "-" + month + "-" + day + "T" + hours + ":" + min;
}

export const formatDateLeadingZero = (date: Date) => {
    let year = date.getFullYear();

    // Add leading zero if month is less than 10
    let month = (date.getMonth() + 1).toString();
    if (month.length < 2) {
        month = "0" + month;
    }

    //Add leading zero if day is less than 10
    let day = date.getDate().toString();
    if (day.length < 2) {
        day = "0" + day;
    }

    return year + "-" + month + "-" + day;
}


export const firstLetterUppercase = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const replaceUnderscoreWhitespace = (string: string | null) => {
    if (!string) return string;
    return string.replace(/_/g, " ");
}

export const replaceUnderscoreWhitespaceToUppercase = (string: string | null) => {
    if (!string) return string;
    return string.split("_").map((word) => firstLetterUppercase(word)).join(" ");
}

export const replaceUnderscoreWhitespaceToUppercaseAndRemoveNumbers = (string: string | null) => {
    if (!string) return string;
    return string.split("_").map((word) => firstLetterUppercase(word)).join(" ").replace(/\d+/g, '').trimStart();
}

export const getFirstLetterToUppercase = (string: string | undefined) => {
    if (!string) return string;

    let stringArray = string.split(" ").slice(0, 3).map((str) => {
        //check if the str is an alphanumeric string
        if (str.match(/^[0-9a-zA-Z]+$/)) {
            return str.charAt(0).toUpperCase();
        }
    });

    return stringArray.join("");
}

export const getCurrentMonth = () => {
    const today = new Date();
    return firstLetterUppercase(today.toLocaleString('it-IT', { month: 'long' }));
}

export const getYearMonth = (date: string | undefined) => {
    if (!date) return "";
    let dateObj = new Date(date);

    // Get italian month and year
    return firstLetterUppercase(dateObj.toLocaleString('it-IT', { month: 'long' })) + " " + dateObj.getFullYear();
}

export const getDay = (date: string | undefined) => {
    if (!date) return "";
    let dateObj = new Date(date);

    // Get italian month and year
    return dateObj.toLocaleString('it-IT', { day: 'numeric' });
}

export const getMonth = (date: string | undefined) => {
    if (!date) return "";
    let dateObj = new Date(date);

    // Get italian month and year
    return firstLetterUppercase(dateObj.toLocaleString('it-IT', { month: 'long' }));
}

export const getYear = (date: string | undefined) => {
    if (!date) return "";
    let dateObj = new Date(date);

    // Get italian month and year
    return dateObj.getFullYear();
}

export const getItalianDay = (date: string | undefined) => {
    if (!date) return "";
    let dateObj = new Date(date);

    // Get italian month and year
    return firstLetterUppercase(dateObj.toLocaleString('it-IT', { weekday: 'long' }));
}

export const checkIsJson = (json: string | undefined) => {
    if (!json) return false;
    try {
        return JSON.parse(json);
    } catch (e) {
        return false;
    }
}

export const debounce = (func: any, wait: number) => {
    let timeout: any;
    return function executedFunction(...args: any) {
        const later = () => {
            timeout = null;
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}


export const validateTelephoneNumber = (number: string) => {
    const regex = new RegExp(/^[0-9\b]+$/);
    return regex.test(number);
}

export const validateEmailAddress = (email: string) => {
    const regex = new RegExp(/\S+@\S+\.\S+/);
    return regex.test(email);
}

// Create a function that check if the number is a valid italian mobile number and return only the number
export const validateAndReturnMobileNumber = (number: string) => {
    const regex = new RegExp(/^[0-9\b]+$/);
    /*
    the string could contains also the +39 prefix and other characters not digits
    so we need to remove all the characters that are not digits.
    A valid italian mobile number could be composed from 6 to 11 digits
    */
    const numberOnlyDigits = number.replace(/\D/g, "");
    if (regex.test(numberOnlyDigits) && numberOnlyDigits.length >= 6 && numberOnlyDigits.length <= 11) {
        return numberOnlyDigits;
    }

    return "";
}