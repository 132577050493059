import React from 'react'
import { TouchableOpacity, Platform } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { HStack, Text } from 'native-base';
import { useRegole } from '../../../../core/store/rubrica/regole/regole-store';
import FormRegola from '../../../../screens/rubrica/contatto/handler-contatto/regole/index-modal';
import { updateRegola } from '../../../../api/nominativi/update/update-regola';
import { createRegola } from '../../../../api/nominativi/create/create-regola';
import { queryClient } from '../../../../api/common/api-provider';
import { SafeAreaView } from 'react-native-safe-area-context';
import { getRegole } from '../../../../api/nominativi/get/get-regole';
import { useContatto } from '../../../../core/store/rubrica/contatto-store';

const Stack = createNativeStackNavigator();


type Props = {}

export default function RegolaNavigator({ }: Props) {

    const getAllData = useRegole(state => state.getAllData);
    const isErrorDate = useRegole(state => state.isErrorDate);
    const resetData = useRegole(state => state.resetData);
    const contatto = useContatto(state => state.contatto);

    const { mutate: createRegole } = createRegola({
        onSuccess: (data) => {
            queryClient.invalidateQueries(getRegole.getKey());
        }
    });

    const { mutate: updateRegole } = updateRegola({
        onSuccess: (data) => {
            queryClient.invalidateQueries(getRegole.getKey());
        }
    });


    const handleSaveRegole = (navigation: any) => {
        if (isErrorDate) return;
        const payload = getAllData();
        createRegole({
            variables: {
                data: payload,
                id_nominativo: contatto?.id
            }
        })
        resetData();
        navigation.goBack();
    }

    const handleUpdateRegole = (navigation: any, id_regola: number) => {
        if (isErrorDate) return;
        const payload = getAllData();
        updateRegole({
            variables: {
                data: payload,
                id: id_regola
            }
        })
        resetData();
        navigation.goBack();
    }
    return (
        <Stack.Navigator
            initialRouteName='RegolaNavigator'
            screenOptions={{
                headerShown: true,
                headerStyle: {
                    backgroundColor: "#fff",
                    margin: 10,
                },
                headerTransparent: true,
                header: ({ navigation, route }) =>
                    <HeaderFormRegola
                        navigation={navigation}
                        route={route}
                        handleSaveRegole={handleSaveRegole}
                        handleUpdateRegole={handleUpdateRegole}
                        isErrorDate={isErrorDate}

                    />
            }}>
            <Stack.Screen
                name="RegolaNavigator"
                component={FormRegola}
            />
        </Stack.Navigator>
    )
}

const HeaderFormRegola = ({ navigation, route, handleSaveRegole, handleUpdateRegole, isErrorDate }: any) => {
    return (
        <SafeAreaView
            style={{
                flex: 1,
                height: "100%",
                position: "relative",
                marginHorizontal: 5,
                paddingVertical: Platform.OS === "android"  ? 5 : 0
            }}>
            <HStack justifyContent={"space-between"}>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Text textAlign={"right"} p="2" fontFamily={"body"} fontWeight={"400"} fontSize={"md"} color="danger.600" >
                        Indietro
                    </Text>
                </TouchableOpacity>
                <Text textAlign={"right"} p="2" fontWeight={"600"} fontSize={"md"} color="dark" _dark={{
                    color: "white"
                }} >
                    {route?.params?.isEdit ? "Modifica Regola" : "Crea Regola"}
                </Text>
                {route?.params?.isEdit ? <TouchableOpacity disabled={isErrorDate} onPress={() => handleUpdateRegole(navigation, route?.params?.regola?.id)}>
                    <Text disabled={isErrorDate} textAlign={"right"} p="2" fontFamily={"body"} fontWeight={"400"} fontSize={"md"} color={isErrorDate ? "gray.800" : "success.600"} >
                        Salva
                    </Text>
                </TouchableOpacity> :
                    <TouchableOpacity disabled={isErrorDate} onPress={() => handleSaveRegole(navigation)}>
                        <Text textAlign={"right"} p="2" fontFamily={"body"} fontWeight={"400"} fontSize={"md"} color={isErrorDate ? "gray.800" : "success.600"} disabled={isErrorDate} >
                            Aggiungi
                        </Text>
                    </TouchableOpacity>}
            </HStack>
        </SafeAreaView>
    )
}