export default function useFormatIntervallo() {
    //Funzione di decodifica della stringa cron
    function decodeCronString(cronString: string)  {
      const cronFields = cronString.split(" ");
      const cronJson = {
        // minute: cronFields[0],
        hour: cronFields[0],
        dayOfMonth: cronFields[1],
        month: cronFields[2],
        dayOfWeek: cronFields[3],
      };
      return cronJson;
    }
  
    //Funzione di formattazione vera e propria
    function formatCronSchedule(cronString: string) {
      const cronJson = decodeCronString(cronString);
      const daysOfWeek = formatCronDayOfWeek(cronJson.dayOfWeek);
      const months = formatCronMonth(cronJson.month, cronJson.dayOfMonth);
      const hours = formatCronHours(cronJson.hour, cronJson.minute);
  
      // se ci sono giorni della settimana, mesi e ore
      if (daysOfWeek && months && hours) {
        return `${months}, ${daysOfWeek}, ${hours}`;
  
        // se ci sono giorni della settimana e mesi
      } else if (daysOfWeek && months) {
        return `${months}, ${daysOfWeek} `;
  
        // se ci sono mesi e ore
      } else if (hours && months) {
        return `${months}, ${hours}`;
  
        // se ci sono giorni della settimana e ore
      } else if (daysOfWeek && hours) {
        return `${daysOfWeek}, ${hours}`;
  
        // se ci sono solo ore
      } else if (hours) {
        return `${hours}`;
  
        // se ci sono solo giorni della settimana
      } else if (daysOfWeek) {
        return `${daysOfWeek}`;
  
        // se ci sono solo mesi
      } else if (months) {
        return `${months}`;
      }
  
      return "Sempre";
    }
  
    function formatCronMonth(cronMonth: string, cronDay: string) {
      const months = [
        "dicembre",
        "gennaio",
        "febbraio",
        "marzo",
        "aprile",
        "maggio",
        "giugno",
        "luglio",
        "agosto",
        "settembre",
        "ottobre",
        "novembre",
      ];
      const dayRanges = cronMonth.split(",");
      const dayNames = [];
  
      // cicla tutti i range di mesi
      for (let i = 0; i < dayRanges.length; i++) {
        const range = dayRanges[i];
  
        // se il range è * allora significa che ogni mese
        if (range === "*") {
          return "";
        } else if (range.includes("-")) {
          // se il range è un intervallo di mesi (es. 1-3)
          const [start, end] = range.split("-").map(Number);
          // aggiungi i nomi dei mesi nell'intervallo
          for (let j = start; j <= end; j++) {
            dayNames.push(months[j % 12]);
          }
  
          // se il range è un singolo mese (es. 1)
        } else {
          dayNames.push(months[Number(range) % 12]);
        }
      }
  
      let sentence = "";
  
      if (cronDay === "-") {
        const [start, end] = cronDay.split("-").map(Number);
        sentence += `I giorni dal ${start} al ${end} ${
          dayNames.length > 2 ? "dei" : "di"
        } `;
      } else {
        // se c'è un giorno del mese, allora aggiungi la forma "il giorno X"
        if (cronDay !== "*" && cronDay !== "," && cronDay) {
          // se c'è un giorno del mese, allora aggiungi la forma "il giorno X"
          sentence += ` Il giorno ${cronDay} ${
            dayNames.length > 2 ? "dei" : "di"
          } `;
        }
      }
  
      if (dayNames.length === 1) {
        // se c'è solo un mese, allora usa la forma "il mese X"
        sentence += ` ${dayNames[0]}`;
      } else if (dayNames.length === 2) {
        // se ci sono solo 2 mesi, allora usa la forma "i mesi X e Y"
        sentence +=
          sentence === ""
            ? ` I mesi di ${dayNames[0]} e ${dayNames[1]}`
            : ` I mesi di ${dayNames[0]} e ${dayNames[1]}`;
      } else {
        // se ci sono più di 3 mesi, allora usa la forma "i mesi X, Y e Z"
        const lastDay = dayNames.pop();
        sentence +=
          sentence === ""
            ? `I mesi di ${dayNames.join(", ")} e ${lastDay}`
            : `I mesi di ${dayNames.join(", ")} e ${lastDay}`;
      }
  
      return sentence;
    }
  
    function formatCronDayOfWeek(cronDayOfWeek: string) {
      const daysOfWeek = [
        "domenica",
        "lunedì",
        "martedì",
        "mercoledì",
        "giovedì",
        "venerdì",
        "sabato",
      ];
      const dayRanges = cronDayOfWeek.split(",");
      const dayNames = [];
  
      for (let i = 0; i < dayRanges.length; i++) {
        const range = dayRanges[i];
        if (range === "*") {
          return "";
        } else if (range.includes("-")) {
          const [start, end] = range.split("-").map(Number);
          for (let j = start; j <= end; j++) {
            dayNames.push(daysOfWeek[j % 7]);
          }
        } else {
          dayNames.push(daysOfWeek[Number(range) % 7]);
        }
      }
  
      if (dayNames.length === 1) {
        return ` ${dayNames[0]}`;
      } else if (dayNames.length === 2) {
        return ` ${dayNames[0]} e ${dayNames[1]}`;
      } else {
        const lastDay = dayNames.pop();
        return `i giorni ${dayNames.join(", ")} e ${lastDay}`;
      }
    }
  
    function formatCronHours(cronHours: string) {
      /*
          La stringa Crontab è stata modificata, 
          i minuti non sono presenti all'interno delle ore
          (es. "* 11-12 * * *" diventa  "* 11:30-12:30 * * *" )
          */
  
      //Se ci sono elenchi di orari, non li stampo
      if (cronHours.includes(",")) return;
  
      if (cronHours === "*") {
        return "";
      } else if (cronHours.includes("-")) {
        //Controllo se ho un intervallo di ore-minuti
        const [start, end] = cronHours.split("-");
        if (!end || !start) return "";
        return `dalle ${start} alle ${end}`;
      } else {
        return ``;
      }
    }
  
    /*  Periodi predefiniti:
              Intervallo date -> formato JSON {data_inizio: “”, data_fine: “”}
              Intervallo orario + giorni settimana -> slide orario + checkbox (es. formato cron “* 8-12 * * 1,2,3”)
              Intervallo il giorno del mese (es. il giorno 25 di Dicembre di ogni anno) -> 1 selectbox per mese + 1 slider per giorni non esclusive (es. formato cron “* * 25 12 *” ) 
         */
    function formatIntervallo(str: string) {
      // let splitStr = str.split("/");
  
      //Controllo se la stringa è in formato JSON
      if (JSON.parse(str)) {
        let json = JSON.parse(str);
  
        //Se è in formato JSON controllo se è un intervallo di date
        if (json.dateTimeRange) {
          let dataInizio = new Date(json.dateTimeRange.startDateTime);
          let dataFine = new Date(json.dateTimeRange.endDateTime);
  
          //Formatto le date in italiano e le concateno
          let options = {
            day: "numeric",
            month: "long",
            year: "numeric",
          };
          let dataInizioString = checkStartDate(dataInizio, options);
          let dataFineString = checkEndDate(dataFine, options);
  
          let intervalloInizio = "Dal " + dataInizioString;
          let intervalloFine = "Al " + dataFineString;
          return intervalloInizio + " " + intervalloFine;
        } else {
          //Altrimenti è in formato cron
          if (!json.cron) return;
  
          let cronJson = formatCronSchedule(json.cron);
          return cronJson;
        }
      }
    }
  
    function checkStartDate(dataInizio: string, options: { day: string; month: string; year: string }) {
      if (dataInizio) {
        const dateObj = new Date(dataInizio);
        if (dateObj.getHours() === 0 && dateObj.getMinutes() === 0)
          return dataInizio.toLocaleDateString("it-IT", options);
  
        return dataInizio.toLocaleDateString("it-IT", {
          ...options,
          hour: "2-digit",
          minute: "2-digit",
        });
      }
    }
  
    function checkEndDate(dataFine: string,options: { day: string; month: string; year: string }) {
      if (dataFine) {
        const dateObj = new Date(dataFine);
        if (dateObj.getHours() === 23 && dateObj.getMinutes() === 59)
          return dateObj.toLocaleDateString("it-IT", options);
  
        return dateObj.toLocaleDateString("it-IT", {
          ...options,
          hour: "2-digit",
          minute: "2-digit",
        });
      }
    }
  
    return formatIntervallo;
  }
  