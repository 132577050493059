import React from 'react'
import { Box, Pressable, Text, HStack, Menu, ScrollView } from 'native-base'
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { getServizioIcon, getServizioIconSource } from '../../../utils/get-icons';
import { Platform } from 'react-native';
import { Servizio, ServizioResult } from '../../../api/servizi';
import { useModal } from "../../../core/store/modal-store";
import { Image } from 'react-native';
import { MotiView } from 'moti';
import { TouchableOpacity } from 'react-native-gesture-handler';

type ModalProps = {
  title: string,
  icon: string,
  color: string,
  children: React.ReactNode,
  navigation: any,
  modalContent: React.ReactNode | null
}

type MenuProps = {
  title: string,
  icon: string,
  color: string,
  children: React.ReactNode,
  menuItems: ServizioResult[] | undefined,
  onMenuItemPress: (item: any) => void,
  servizioSelezionato: ServizioResult | undefined
}

export function PreviewBoxModal({ title, icon, color, children, navigation, modalContent }: ModalProps) {

  const setModalTitle = useModal(state => state.setModalTitle);
  const setModalContent = useModal(state => state.setContent);

  const handleOpenModal = () => {
    setModalTitle({ title: title, icon: icon, color: color });
    setModalContent(modalContent);
    navigation.navigate("Modals");
  }

  return (
    <MotiView
      from={{
        opacity: 0,
        translateY: 50,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      transition={{
        type: "timing",
        duration: 800,
      }}
    >
      <Box maxW={1200} bg="white" _dark={{ bg: "dark" }} w={{
        base: "98%",
        md: "95%"
      }}  p="2" minH={20} justifyContent={"center"} mx="auto" rounded={"3xl"}>
        <HStack space={2} mx="2" alignItems={"center"}>
          <Box justifyContent={"center"} alignItems={"center"} w={8} h={8} bg={color} rounded={"full"}>
            <Ionicons name={icon} size={20} md="auto" color="white" />
          </Box>
          <Text mr="auto" fontFamily={"body"} fontWeight={"700"} fontSize={"2xl"} color="neutral.800" _dark={{ color: "white" }}>{
            title
          }</Text>
          {modalContent && <TouchableOpacity onPress={handleOpenModal}>
            <Text fontFamily={"body"} fontWeight={"400"} fontSize={"sm"} color="neutral.800" _dark={{ color: "white" }}>
              Mostra
            </Text>
          </TouchableOpacity>}
        </HStack>
        {children}
      </Box>
    </MotiView>
  )
}

export function PreviewBoxMenu({ title, icon, color, children, menuItems, onMenuItemPress, servizioSelezionato }: MenuProps) {
  return (
    <MotiView
      from={{
        opacity: 0,
        translateY: 100,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      transition={{
        type: "timing",
        duration: 800,
      }}
    >
      <Box bg="white" maxW={1200} _dark={{ bg: "dark" }} w={{
        base: "98%",
        md: "95%"
      }} p="2" minH={20} justifyContent={"center"} mx="auto" rounded={"3xl"}>
        <HStack position={"relative"} space={2} mx="2" alignItems={"center"}>
          <Box justifyContent={"center"} alignItems={"center"} w={8} h={8} bg={color} rounded={"full"}>
            <Ionicons name={icon} size={20} md="auto" color="white" />
          </Box>
          <Text fontFamily={"body"} fontWeight={"700"} fontSize={"2xl"} color="neutral.800" _dark={{ color: "white" }}>{
            title
          }</Text>
          <Menu maxH={ 
            Platform.OS === "web" ? 400 : {
            base: "100%",
            md: "50vh",
          }} rounded={"3xl"} top={Platform.OS === "web" ? "10" : "150"} p="2" right={Platform.OS === "web" ? "15" : "-17%"} minW={Platform.OS === "web" ? 200 : 100} placement='left bottom' _dark={{ borderColor: "gray.800", borderWidth: 1  }} trigger={triggerProps => {
            return <Pressable {...triggerProps} ml="auto" >
              <Text fontFamily={"body"} fontWeight={"400"} fontSize={"sm"} color="neutral.800" _dark={{ color: "white" }}>
                Scegli
              </Text>
            </Pressable>
          }} >
            {/* <div style={{ maxHeight: 400,minWidth: 300,overflowX: "hidden" }}> */}
            {menuItems && menuItems.map((item, index) => {
              return (
                <Menu.Item _dark={{bg: servizioSelezionato?.id === item.id ?"gray.800" : "dark"}}  bg={servizioSelezionato?.id === item.id ? "gray.200" : "white"} onPress={() => onMenuItemPress(item)} key={index} _text={{
                  fontSize: "md",
                  fontWeight: "400",
                  color: "dark",
                  fontFamily: "body"
                }}>
                  {getServizioIcon(item.tipo_pacchetto, 80,80)}
                  <Text maxW={Platform.OS === "web" ? "250" : "200"} fontSize={"lg"} fontWeight={"400"} fontFamily={"body"}>{item.descrizione}</Text>
                </Menu.Item>
              )
            })}
            {/* </div> */}
          </Menu>
        </HStack>
        {children}
      </Box>
    </MotiView>
  )
}
