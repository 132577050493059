import { VStack, View, Divider, HStack } from 'native-base'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import PeriodoFisso from './periodo-fisso/periodo-fisso';
import TipoRegola from './tipo-regola/tipo-regola';
import MotiviAssenza from './motivi-assenza/motivi-assenza';
import { useRegole } from '../../../../../core/store/rubrica/regole/regole-store';
import { Platform } from 'react-native';
import Cron from './cron/cron';

type Props = {
}

export default function FormRegola({
}: Props) {

  const tipoRegola = useRegole(state => state.tipoRegola);
  const ripetizione = useRegole(state => state.ripetizione);



  return (
    <View h="full" w="full" bg="white" _dark={{bg:"dark"}}>
      <SafeAreaView
        style={Platform.OS === "web" ? {
          flex: 1,
          height: "100%",
          maxWidth: 800,
          minWidth: 800,
          marginHorizontal: "auto",
          marginTop: 80,
        }:
        {
          flex: 1,
          height: "100%",
          marginHorizontal: 20,
          marginVertical: 60,
        }}>
        {/* Periodo Fisso */}
        <VStack mb="auto" w="90%" mx="auto" space={4}>

          <TipoRegola />
          <Divider />

          {tipoRegola === "Assenza" && <VStack space={2}>
            <MotiviAssenza />
            <Divider />
          </VStack>}

          {ripetizione.id === 0 && <VStack space={2}>
            <PeriodoFisso />
            <Divider />
            </VStack>}

          <Cron />

        </VStack>

        {/* Periodo ricorrente */}
      </SafeAreaView>
    </View>
  )
}