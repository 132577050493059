import React, { useRef, useEffect } from 'react';
import { View, Animated, Easing } from 'react-native';
import Svg, { Path,Circle } from 'react-native-svg';


const MailSentIcon = () => {

    const opacityValue = useRef(new Animated.Value(0)).current;
    const scaleValue = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        animateCheckmark();
    }

        , []);

    const animateCheckmark = () => {
        Animated.parallel([
            Animated.timing(opacityValue, {
                toValue: 1,
                duration: 1000, // Adjust the duration as needed
                useNativeDriver: true,
            }),
            Animated.spring(scaleValue, {
                toValue: 1,
                friction: 4,
                useNativeDriver: true,
            }),
        ]).start();

    };



    return (
        <Animated.View
            style={{
                opacity: opacityValue,
                transform: [{ scale: scaleValue }],
            }}
        >
            <Svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 32 32">
                <Path fill="#4cbe87" d="M19 24H4L3.997 8.906l11.434 7.916a1 1 0 0 0 1.138 0L28 8.91V18h2V8a2.003 2.003 0 0 0-2-2H4a2.002 2.002 0 0 0-2 2v16a2.003 2.003 0 0 0 2 2h15Zm6.799-16L16 14.784L6.201 8Z" />
            <Circle cx="26" cy="24" r="4" fill="#4cbe87" /></Svg>
        </Animated.View>
    );
};

export default MailSentIcon;
