import React, { useEffect, useRef } from 'react';
import { View, Animated } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const AnimatedCheckmark = () => {
  const opacityValue = useRef(new Animated.Value(0)).current;
  const scaleValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    animateCheckmark();
  }, []);

  const animateCheckmark = () => {
    Animated.parallel([
      Animated.timing(opacityValue, {
        toValue: 1,
        duration: 1000, // Adjust the duration as needed
        useNativeDriver: true,
      }),
      Animated.spring(scaleValue, {
        toValue: 1,
        friction: 4,
        useNativeDriver: true,
      }),
    ]).start();
  };

  return (
    <View>
      <Animated.View
        style={{
          opacity: opacityValue,
          transform: [{ scale: scaleValue }],
        }}
      >
        <Svg xmlns="http://www.w3.org/2000/svg"
          width="250"
          height="250"
          viewBox="0 0 32 32"
          
        >
          <Path fill="#4cbe87" d="m14 21.414l-5-5.001L10.413 15L14 18.586L21.585 11L23 12.415l-9 8.999z" />
          <Path fill="#4cbe87" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm0 26a12 12 0 1 1 12-12a12 12 0 0 1-12 12Z" />
        </Svg>
      </Animated.View>
    </View>
  );
};

export default AnimatedCheckmark;
