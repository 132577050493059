import { create } from "zustand";
import { createSelectors, storage } from "../utils";
import { ServizioResult } from "../../api/servizi";

export interface ServizioStore {
    servizio: ServizioResult | null; // Update the type of the servizio property
    listaServizi: any;
    setListaServizi: (servizi: any) => void;
    isSiriaCentralino: boolean;
    setServizio: (servizi: any) => void;
    getServizioId: () => number;
}

const useServizioStore = create<ServizioStore>(
    (set) => ({
        servizio: null,
        isSiriaCentralino: false,
        listaServizi: [],
        setListaServizi: (servizi: any) => set({ listaServizi: servizi }),
        setServizio: (servizi: any) => {
            set({
                servizio: servizi,
                isSiriaCentralino: servizi.tipo_pacchetto?.match(/.*caren_light.*/) ? true : false
            }),
            storage.set("servizio", JSON.stringify(servizi));
        },
        getServizioId: () => {
            return useServizio.getState()?.servizio?.id;
        }
    })
)


export const useServizio: ReturnType<typeof createSelectors> = createSelectors(useServizioStore);
