import * as React from "react"
import Svg, { SvgProps, Path, Circle } from "react-native-svg"
const ContactUs = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 1000 500"
    {...props}
  >
    <Path
      fill="#f0f0f0"
      d="M294.494 448.282q-40.475 0-80.829-1.449c-38.44-1.38-78.19-2.808-115.135-23.299-25.845-14.333-48.735-37.329-66.195-66.504a223.77 223.77 0 0 1-25.369-63.604 261.476 261.476 0 0 1 .953-123.78 219.834 219.834 0 0 1 14.741-40.4C49.603 73.453 98.745 36 157.486 26.492a6.807 6.807 0 0 1 1.463-.031c.264.014.528.03.796.018l.346-.11a16.882 16.882 0 0 1 2.72-.698c90.594-13.487 186.365 13.802 276.953 78.913 70.042 50.352 162.867 77.465 283.781 82.89l1.717.09c33.66 1.933 66.218 15.81 94.16 40.132 27.58 24.006 48.287 56.034 59.884 92.624a209.292 209.292 0 0 1 3.884 13.801 26.039 26.039 0 0 1-6.94 24.69v.01l-.302.309a33.168 33.168 0 0 1-2.267 2.055l-.917.756c-7.727 6.366-13.83 11.394-21.135 15.3a64.983 64.983 0 0 1-15.419 5.82 2270.012 2270.012 0 0 1-88.936 19.797c-57.384 11.57-116.281 21.116-175.054 28.369a2269.14 2269.14 0 0 1-277.726 17.055ZM158.262 28.437a3.507 3.507 0 0 0-.54.037C99.532 37.893 50.855 74.978 24.179 130.219a217.624 217.624 0 0 0-14.595 40 259.188 259.188 0 0 0-.94 122.68 221.622 221.622 0 0 0 25.122 62.996c17.289 28.89 39.945 51.655 65.519 65.836 36.614 20.308 76.176 21.729 114.436 23.102a2266.314 2266.314 0 0 0 358.315-15.595c58.735-7.249 117.593-16.787 174.94-28.35a2265.172 2265.172 0 0 0 88.875-19.784 63.358 63.358 0 0 0 15.048-5.678c7.157-3.826 13.202-8.807 20.855-15.112l.917-.754a31.692 31.692 0 0 0 1.958-1.761l.18-.19c6.29-6.339 8.735-14.702 6.71-22.944v-.001a206.855 206.855 0 0 0-3.843-13.655c-23.69-74.746-84.974-127.57-152.497-131.448l-1.707-.09c-55.232-2.478-103.564-9.343-147.751-20.99-52.75-13.902-98.802-34.83-136.876-62.2-90.242-64.863-185.613-92.05-275.811-78.627a15.473 15.473 0 0 0-2.479.643l-.623.174a9.374 9.374 0 0 1-1.062-.013c-.208-.01-.41-.021-.607-.021Z"
    />
    <Path
      fill="#2f2e41"
      d="M279.327 146.056c-2.498-2.397-5.053-15.762-8.242-17.119 2.383 5.269.653 6.664.64 12.446a39.123 39.123 0 0 1-1.141 9.745H216.56v-42.162a28.847 28.847 0 0 1 28.847-28.848 17.214 17.214 0 0 1 4.276.626 27.428 27.428 0 0 1 19.35 19.404c4.12 15.054 10.67 40.112 10.294 45.908Z"
    />
    <Path
      fill="#ffb6b6"
      d="m219.73 197.923.026-32.863-19.317 1.726 6.817 20.727-3.19 52.096a8.173 8.173 0 1 0 9.89 6.889ZM257.746 432.76l7.804-.001 3.713-30.102h-11.519l.002 30.103z"
    />
    <Path
      fill="#2f2e41"
      d="m255.103 428.91 12.337-.737v5.286l11.729 8.1a3.302 3.302 0 0 1-1.876 6.02h-14.688l-2.531-5.229-.989 5.228h-5.538Z"
    />
    <Path
      fill="#ffb6b6"
      d="m212.62 432.76 7.805-.001 3.712-30.102h-11.518l.001 30.103z"
    />
    <Path
      fill="#2f2e41"
      d="m209.977 428.91 12.337-.737v5.286l11.73 8.1a3.302 3.302 0 0 1-1.877 6.02H217.48l-2.532-5.229-.988 5.228h-5.538Z"
    />
    <Path
      fill="#e6e6e6"
      d="m250.796 129.571-16.167.317-11.412 12.047-9.15 1.342a18.266 18.266 0 0 0-15.577 16.897l1.585 8.706h9.51l5.502 32.303c-2.14 5.451-2.285 9.754 2.106 11.444l13.632 25.677 40.26-18.069c2.041-3.51 2.696-7.036.609-10.464l2.878-49.45h9.677c0-9.249-3.128-17.046-12.32-18.075a108.654 108.654 0 0 0-10.989-.628Z"
    />
    <Path
      fill="#2f2e41"
      d="M271.085 220.235s-39.31 6.974-53.892-7.608c0 0-1.48 2.513-3.71 6.682-.642 1.198-1.881 2.01-2.63 3.462-.68 1.32-2.429 2.942-3.17 4.439-.596 1.204.36 2.777-.26 4.078-7.468 15.664-16.193 37.759-14.322 52.348 1.206 9.41 3.698 31.302 6.322 54.817.205 1.837-1.456 4.197-1.25 6.049.194 1.746 2.255 2.983 2.45 4.734.129 1.162-1.31 2.347-1.182 3.508.141 1.273 1.85 2.522 1.99 3.79 3.595 32.468 6.886 62.781 6.886 62.781h18.387l3.469-69.232-2.835-25.236 6.657-51.038 9.193 48.502 1.183 27.297 9.715 70.975h16.999v-68.708l1.268-3.57-1.268-4.577 1.268-4.932-1.268-5.372Z"
    />
    <Path
      fill="#ffb6b6"
      d="M255.759 120.196a18.326 18.326 0 1 1 3.175-15.315c.052.22.097.438.14.665a18.301 18.301 0 0 1-3.315 14.65Z"
    />
    <Path
      fill="#2f2e41"
      d="M251.937 109.74a9.633 9.633 0 0 0-2.815-5.104 13.142 13.142 0 0 1-.76 5.51 25.395 25.395 0 0 1-13.955-2.765c-3.233-2.194-7.348-2.004-12.141 0a17.75 17.75 0 0 1 17.752-17.752h3.17a17.756 17.756 0 0 1 17.752 17.752 52.852 52.852 0 0 1-9.003 2.358Z"
    />
    <Circle cx={113.249} cy={285} r={51} fill="#f0f0f0" />
    <Circle cx={438.249} cy={51} r={51} fill="#f0f0f0" />
    <Circle cx={843.249} cy={293} r={51} fill="#f0f0f0" />
    <Path
      fill="#6c63ff"
      d="M530.25 56.562v115.646a9.119 9.119 0 0 1-9.024 9.12.802.802 0 0 1-.1.003h-13.46a40.606 40.606 0 0 1-40.607-40.606V56.562a9.123 9.123 0 0 1 9.124-9.124h5.454c-2.527 7.371 31.765 10.474 33.648 0h5.84a9.123 9.123 0 0 1 9.124 9.124Z"
    />
    <Path
      fill="#3f3d56"
      d="M490.306 38.79a1.197 1.197 0 0 1 1.196-1.195h8.13a1.196 1.196 0 0 1 0 2.391h-8.13a1.197 1.197 0 0 1-1.196-1.195Z"
    />
    <Path
      fill="#3f3d56"
      d="M523.919 196h-51.416a15.063 15.063 0 0 1-15.046-15.046v-80.841a2.187 2.187 0 0 1-.912-1.779v-8.13a2.186 2.186 0 0 1 .912-1.779v-2.182a2.186 2.186 0 0 1-.912-1.778v-8.131a2.186 2.186 0 0 1 .912-1.778v-3.944a2.184 2.184 0 0 1-1.15-1.93v-8.13a2.184 2.184 0 0 1 1.15-1.93V47.815a15.063 15.063 0 0 1 15.046-15.046h51.416a15.063 15.063 0 0 1 15.046 15.046v23.556a1.886 1.886 0 0 1 .886 1.598v15.503a1.887 1.887 0 0 1-.886 1.599v90.883A15.063 15.063 0 0 1 523.919 196ZM472.503 34.77a13.061 13.061 0 0 0-13.046 13.045v12.508l-.962.037a.195.195 0 0 0-.189.192v8.13a.196.196 0 0 0 .189.192l.962.037v7.049l-.761.187a.197.197 0 0 0-.15.187v8.13a.197.197 0 0 0 .15.187l.76.188v4.99l-.76.188a.197.197 0 0 0-.15.187v8.13a.197.197 0 0 0 .15.187l.76.187v82.246A13.061 13.061 0 0 0 472.504 194h51.416a13.06 13.06 0 0 0 13.046-13.046V88.358h.886V73.084h-.886v-25.27a13.06 13.06 0 0 0-13.046-13.045Z"
    />
    <Circle cx={504.176} cy={38.791} r={1.196} fill="#3f3d56" />
    <Path
      fill="#fff"
      d="M244.08 270.403H110.293a7.188 7.188 0 0 1-7.17-7.17v-65.188a7.188 7.188 0 0 1 7.169-7.17h133.787a7.188 7.188 0 0 1 7.17 7.169v65.189a7.188 7.188 0 0 1-7.169 7.17Z"
    />
    <Path
      fill="#3f3d56"
      d="M244.08 270.403H110.293a7.188 7.188 0 0 1-7.17-7.17v-65.188a7.188 7.188 0 0 1 7.169-7.17h133.787a7.188 7.188 0 0 1 7.17 7.169v65.189a7.188 7.188 0 0 1-7.169 7.17Zm-133.787-77.528a5.185 5.185 0 0 0-5.17 5.17v65.188a5.185 5.185 0 0 0 5.171 5.17h133.784a5.185 5.185 0 0 0 5.171-5.171v-65.187a5.185 5.185 0 0 0-5.17-5.17Z"
    />
    <Path
      fill="#6c63ff"
      d="M184.357 260.769c-26.386 0-53.899-.252-64.164-.416a5.176 5.176 0 0 1-5.07-5.155v-53.962a1.83 1.83 0 0 1 1.823-1.827h120.476a1.83 1.83 0 0 1 1.827 1.823v36.612a22.842 22.842 0 0 1-22.457 22.752c-9.567.124-20.896.173-32.435.173Z"
    />
    <Path
      fill="#3f3d56"
      d="M230.27 234.48H121.412a4.438 4.438 0 0 1-4.164-2.936l-12.321-34.566a4.423 4.423 0 0 1 4.158-5.907l136.072-.19a4.423 4.423 0 0 1 4.36 5.199l.104.044-.39.912-.004.009L234.33 231.8a4.41 4.41 0 0 1-4.06 2.68Zm14.892-41.598-136.075.19a2.423 2.423 0 0 0-2.277 3.235l12.32 34.565a2.433 2.433 0 0 0 2.283 1.608H230.27a2.413 2.413 0 0 0 2.221-1.467l14.896-34.756.732.314-.732-.314a2.421 2.421 0 0 0-2.226-3.375Z"
    />
    <Circle cx={176.983} cy={233.48} r={8.524} fill="#fff" />
    <Circle cx={498.983} cy={159.48} r={8.524} fill="#fff" />
    <Path
      fill="#3f3d56"
      d="m821.38 283.12-22.77-22.48a11.884 11.884 0 0 0-8.43-3.46h-.08a11.914 11.914 0 0 0-8.46 3.56 27.991 27.991 0 0 0-.49 38.84c.24.26.49.51.74.76a27.248 27.248 0 0 0 4.56 3.65 27.676 27.676 0 0 0 15.11 4.43h.17a27.826 27.826 0 0 0 19.75-8.32 12.023 12.023 0 0 0-.1-16.98Zm-1.32 15.57a26.092 26.092 0 0 1-32.76 3.48 26.786 26.786 0 0 1-4.01-3.25 26.002 26.002 0 0 1-.23-36.77 9.913 9.913 0 0 1 7.06-2.97h.06a9.911 9.911 0 0 1 7.02 2.88l22.77 22.49a10.007 10.007 0 0 1 .09 14.14ZM746.67 209.34l-22.77-22.49a11.926 11.926 0 0 0-8.43-3.46h-.07a11.947 11.947 0 0 0-8.47 3.57 28.077 28.077 0 0 0-4.72 33l.01.01a27.562 27.562 0 0 0 4.33 5.93c.19.22.41.44.63.66a27.835 27.835 0 0 0 19.67 8.08h.18a27.854 27.854 0 0 0 19.75-8.33 11.999 11.999 0 0 0-.11-16.97Zm-1.32 15.57a25.855 25.855 0 0 1-18.33 7.73h-.17a26 26 0 0 1-18.49-44.27 10.008 10.008 0 0 1 14.14-.09l22.77 22.48a10.02 10.02 0 0 1 .08 14.15Z"
    />
    <Path
      fill="#6c63ff"
      d="m822.834 290.188-25.103-24.794a8.727 8.727 0 0 0-12.327.077 22 22 0 1 0 31.305 30.919ZM748.129 216.404l-25.103-24.794a8.727 8.727 0 0 0-12.328.076 22 22 0 1 0 31.305 30.92Z"
    />
    <Path
      fill="#3f3d56"
      d="m795.25 305.63-.6 1.58q-4.23-1.605-8.2-3.22c-46.88-19.13-69.26-41.52-84.23-84.02l-.01-.01q-1.066-3-2.07-6.14l1.58-.51a25.542 25.542 0 0 0 2.3 5.78q1.23 3.495 2.53 6.81c14.07 35.87 34.82 56.37 74.6 73.68q3 1.305 6.15 2.59a26.087 26.087 0 0 0 7.95 3.46Z"
    />
    <Path
      fill="#3f3d56"
      d="m722.076 234.243 1.406-1.424 51.938 51.298-1.406 1.424z"
    />
    <Path
      fill="#ffb6b6"
      d="m283.765 190.315.026-32.863-19.317 1.726 6.817 20.727-24.747 37.514a8.173 8.173 0 1 0 9.89 6.888Z"
    />
  </Svg>
)
export default ContactUs
