import Animated, { useAnimatedStyle, interpolate, useDerivedValue, withTiming } from "react-native-reanimated"
import { colors } from "../../../../theme/generic-theme/colors"
import { useColorMode } from "native-base"
import { Platform, Pressable } from "react-native"
import { TouchableOpacity } from "react-native-gesture-handler"
import { useState } from "react"

type Props = {
    index: number,
    activeDotIndex: Animated.SharedValue<number>
}

export default function PaginationItem({
    index,
    activeDotIndex
}: Props) {

    const { Extrapolate } = Animated
    const { colorMode } = useColorMode()

    const animStyle = useAnimatedStyle(() => {

        const backgroundColor = index === activeDotIndex.value && colorMode === "light" ? colors.dark : index === activeDotIndex.value && colorMode === "dark" ? colors.white : colors.gray[400]
        return {
            backgroundColor: backgroundColor,
            transform: [
                {
                    scale: interpolate(
                        activeDotIndex.value,
                        [index - 1, index, index + 1],
                        [1, 1.5, 1],
                        Extrapolate.CLAMP
                    ),
                }
            ],
            width: interpolate(
                activeDotIndex.value,
                [index - 1, index, index + 1],
                [16, 24, 16],
                Extrapolate.CLAMP
            ),
        }
    }, [activeDotIndex, colorMode])


    return (
        <Animated.View
            key={index}
            style={[{
                width: 16,
                height: 14,
                borderRadius: 8,
                marginHorizontal: 4,
            }, animStyle]}
        />
    )
}