
import React from "react";
import { HStack, Text, VStack, Switch } from "native-base";
import { useDatiContatto } from "../../../../../../../core/store/rubrica/dati-contatto/dati-contatto-store";
import { useTrasferimenti } from "../../../../../../../core/store/rubrica/trasferimenti/trasferimenti-store";

const MancatoTrasferimentoSms = ({
    editSezioneContatto
}: {
    editSezioneContatto: boolean | undefined,
}) => {

    const mancatoTrasferimentoSms = useTrasferimenti(state => state.mancatoTrasferimentoSms);
    const telefono = useDatiContatto(state => state.telefono);
    const setMancatoTrasferimentoSms = useTrasferimenti(state => state.setMancatoTrasferimentoSms);

    return (
        <HStack space={2} alignItems={"center"} w="full" >
            <VStack maxW={"90%"} space={2} w="full" >
                <Text fontSize={"xl"} fontWeight="600" minW="24" color="dark" _dark={{ color: "white" }}>
                    SMS
                </Text>
                <Text  lineHeight={"xs"} fontSize={"lg"} fontWeight="400" color="dark" _dark={{ color: "white" }}>
                    {mancatoTrasferimentoSms ? telefono : "Non inviare"}
                </Text>
            </VStack>
            <Switch ml="auto"  mx="2"  size="md" colorScheme="success" isChecked={mancatoTrasferimentoSms} onToggle={(val) => setMancatoTrasferimentoSms(val)} isDisabled={!editSezioneContatto} />
        </HStack>
    )
}

export default React.memo(MancatoTrasferimentoSms);