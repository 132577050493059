import React from 'react'
import {
    HStack,
    Input,
    Text,
    VStack,
} from 'native-base';
import { useDatiContatto } from '../../../../../../core/store/rubrica/dati-contatto/dati-contatto-store';

export const Cognome = ({
    enableEdit
}: {
    enableEdit: boolean
}) => {
    const cognome = useDatiContatto(state => state.cognome);
    const setCognome = useDatiContatto(state => state.setCognome);
    const errors = useDatiContatto(state => state.errors);

    return (
        <VStack space={2} w="full" alignItems={"flex-start"} >
            <HStack space={2} w="full" alignItems={"center"} >

                <Text fontSize={"xl"} fontWeight="600"
                    minW="20"
                    color="dark" _dark={{ color: "white" }}>
                    Cognome
                </Text>

                <Input textContentType='familyName' value={cognome} onChangeText={(val: string) => setCognome(val)} fontSize={"lg"} fontWeight="400" lineHeight={"xs"} variant={"unstyled"} maxW={"70%"} numberOfLines={2} w="full" placeholder="Inserisci Cognome" autoCapitalize='words' isDisabled={!enableEdit} />

            </HStack>
            {
                errors?.cognome && <Text fontSize={"xs"} fontWeight={"400"} color="danger.600"  >{errors?.cognome}</Text>
            }
        </VStack>
    )
}

export default React.memo(Cognome);