import React from 'react'
import { useColorMode } from 'native-base'
import { DatiContattoCentralino } from './dati-contatto'
import { TrasferimentiCentralino } from './trasferimenti'
import { RegoleCentralino } from './regole'
import CarouselCustom from '../../../../ui/components/carousel-custom/Carousel'
import { useWindowDimensions } from 'react-native';
import { Platform } from 'react-native'


export function ContattoCentralino() {



  const DATA = [{
    title: "Dati Contatto", data: <DatiContattoCentralino />
  }, {
    title: "Trasferimenti", data: <TrasferimentiCentralino />
  }, {
    title: "Regole", data: <RegoleCentralino />
  }]

  const { colorMode } = useColorMode();
  const dimensions = useWindowDimensions();

  return (
      <CarouselCustom titles={["Dati Generali", "Messaggi Audio", "Pacchetti Acquistati"]}  height={Platform.OS === "web"? dimensions.height / 1.5 : dimensions.height / 1.8} enableHeader={true} enablePagination={false} colorMode={colorMode} titles={["Dati Contatto", "Trasferimenti", "Regole"]} width={
        dimensions.width > 1000 ? 1000 : dimensions.width -40} data={DATA} />
  )
}

