import React, { useState } from 'react'
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel } from 'victory-native'
import { colors } from '../../../../ui/theme/generic-theme/colors'
import { StatisticaResult, StatisticaRiassuntiva } from '../../../../api/statistiche-chiamate';
import { G } from 'react-native-svg';

type Props = {
  colorMode: "light" | "dark" | null | undefined;
  data: StatisticaResult[] | undefined;
  setValoreSelezionato: React.Dispatch<React.SetStateAction<string>>;
}

export default function BarChart({ colorMode, data, setValoreSelezionato }: Props) {

  return (
    
    <VictoryChart
    domainPadding={{ x: 7, y: 10 }}
    padding={{ top: 30, bottom: 30, left: 35, right: 35 }}
    >
      <VictoryBar
        events={[{
          target: "data",
          eventHandlers: {
            // improve performance by avoiding rerendering of the chart
            onPressIn: () => {
              return [
                {
                  target: "data",
                  mutation: (props) => {
                    setValoreSelezionato(props.datum.x + ", " + (props.datum.y).toFixed(0))
                    return { style: { fill: colorMode === "dark" ? colors.gray['400'] : colors.primary['600'], 
                    // width: 30 
                  } };
                  }
                }
              ];
            },
            onPressOut: () => {
              return [
                {
                  target: "data",
                  mutation: (props) => {
                    // setValoreSelezionato("")
                     return { style: { fill: colorMode === "dark" ? colors.white : colors.primary['400'], width: 20 } };
                  }
                }
              ];
            },
            onMouseOver: () => {
              return [
                {
                  target: "data",
                  mutation: (props) => {
                    setValoreSelezionato(props.datum.x + ", " + props.datum.y)
                    return { style: { fill: colorMode === "dark" ? colors.gray['400'] : colors.primary['600'] } };
                  }
                }
              ];
            },
            onMouseOut: () => {
              return [
                {
                  target: "data",
                  mutation: (props) => {
                    setValoreSelezionato("")
                    return { style: { fill: colorMode === "dark" ? colors.white : colors.primary['400'] } };
                  }
                }
              ];
            }
          }
        }]}
        data={data}
        animate={
          {
            duration: 500,
          }
        }
        style={{
          data: {
            fill: colorMode === "dark" ? colors.white : colors.primary['400'], width: 20
          }
          ,
        }}
        cornerRadius={{ top: 10, bottom: 10 }}
      />
      <VictoryAxis style={{
        axis: { stroke: colorMode === "dark" ? colors.white : colors.gray['800'] },
        tickLabels: { fill: colorMode === "dark" ? colors.white : colors.primary[600], fontSize: 12, padding: 15 },
      }} />
      <VictoryAxis dependentAxis style={{
        axis: { stroke: colorMode === "dark" ? colors.white : "transparent" },
        tickLabels: { fill: colorMode === "dark" ? colors.white : colors.primary[600], fontSize: 12, padding: 5 },
        grid: {
          stroke: colorMode === "dark" ? colors.white : colors.gray['800'],
          strokeWidth: 0.5,
          strokeDasharray: "5, 5"
        }
      }} />
    </VictoryChart>
  )
}