import { create } from 'zustand';

import { createSelectors, storage } from '../utils';
import type { TokenType } from './utils';
import { getToken, removeToken, setToken } from './utils';

interface AuthState {
  status: 'idle' | 'signOut' | 'signIn';
  signIn: (data: TokenType) => void;
  signOut: () => void;
  hydrate: () => void;
}

const _useAuth = create<AuthState>((set, get) => ({
  status: 'idle',
  tokens: null,
  signIn: (tokens) => {
    setToken(tokens);
    set({ status: 'signIn' });
  },
  signOut: () => {
    removeToken();
    storage.delete('servizio')
    set({ status: 'signOut' });
  },
  hydrate: async () => {
    try {
      const userTokens = await getToken();
      if (userTokens !== null) {
        set({ status: 'signIn'});
      } else {
        signOut();
      }
    } catch (e) {
      signOut();
      // catch error here
      // Maybe sign_out user!
    }
  },
}));

export const useAuth = createSelectors(_useAuth);

export const signOut = () => _useAuth.getState().signOut();
export const signIn = (token: TokenType) => _useAuth.getState().signIn(token);
export const hydrateAuth = () => _useAuth.getState().hydrate();
