import { Divider, FlatList, HStack, Spinner, Stack, Text, View } from 'native-base'
import React, { useEffect } from 'react'
import Statistica from './Statistica'
import { getStatisticheLight } from '../../../api/statistiche-chiamate'
import { ServizioResult } from '../../../api/servizi'
import { getCurrentMonth } from '../../../utils/utils'
import { Dimensions, Platform } from 'react-native'
import { colors } from '../../../ui/theme/generic-theme/colors'
type Props = {
    servizioSelezionato: ServizioResult
}

export default function StatisticheModal({ servizioSelezionato }: Props) {

    const { data: statistiche, isLoading, isError } = getStatisticheLight({
        variables: {
            codice_servizio: servizioSelezionato?.codice_servizio
        }
    });

    if (isLoading || isError) {
        return <Spinner />
    }

    const renderItem = ({ item }: any) => {
        const { unit_measure, green, red, data, type, percentuale } = item?.data[0];
        return (
            <Stack h="100%" mx={6} flex={1} my={2} space={2} w="full">
                <Text color="dark" fontFamily={"body"} _dark={{color:"white"}} fontWeight={"500"} fontSize={"2xl"}>{item?.title}</Text>
                <Stack flexDir={{
                    base: "column",
                    lg: "row"
                }} space={4} >
                    {item?.unit_measure !== "Minuti" && <HStack w={{
                        base: "full",
                        lg: "50%"
                    }} space={2} alignItems={"center"} justifyContent={"space-between"}>

                        <Statistica unitMeaseure={unit_measure} green={green} red={red} date='Oggi' calls={data?.oggi[type]} percentage={(data?.oggi[percentuale])?.toString()?.replace(".", ",")} textPercentage="ieri" isLoading={isLoading} isError={isError} />

                        <Statistica unitMeaseure={unit_measure} green={green} red={red} date='Settimana' calls={data?.settimana[type]} percentage={(data?.settimana[percentuale])?.toString()?.replace(".", ",")} textPercentage="scorsa settimana" isLoading={isLoading} isError={isError} />

                    </HStack>}
                    <HStack w={{
                        base: "full",
                        lg: "50%"
                    }} space={2} alignItems={"center"} justifyContent={"space-between"}>

                        <Statistica unitMeaseure={unit_measure} green={green} red={red} date={getCurrentMonth()} calls={data?.mese[type]} percentage={(data?.mese[percentuale])?.toString()?.replace(".", ",")} textPercentage="scorso mese" isLoading={isLoading} isError={isError} />


                        <Statistica unitMeaseure={unit_measure} green={green} red={red} date='Ultimi 3 Mesi' calls={data?.ultimi_3_mesi[type]} percentage={(data?.ultimi_3_mesi[percentuale])?.toString()?.replace(".", ",")} textPercentage="scorsi 3 mesi" isLoading={isLoading} isError={isError} />

                    </HStack>
                </Stack>
            </Stack>
        )
    };

    return (
        <FlatList
            maxH={Dimensions.get('window').height - 100}
            w="full"
            data={[
                { title: 'Media Secondi Gestione', data: [{ "data": statistiche, "type": "media_minuti_conversazione", "percentuale": "differenza_percentuale_minuti_conversazione", "unit_measure": "Minuti", "green": colors.neutral['600'], "red": colors.neutral['600'] }] },
                { title: 'Chiamate', data: [{ "data": statistiche, "type": "chiamate", "percentuale": "differenza_percentuale", "unit_measure": "Chiamate", "green": colors.success['600'], "red": colors.error['600'] }] },
                { title: 'Fuori Orario', data: [{ "data": statistiche, "type": "fuori_orario", "percentuale": "differenza_percentuale_fuori_orario", "unit_measure": "Chiamate", "green": colors.neutral['600'], "red": colors.neutral['600'] }] },
            ]}
            contentContainerStyle={Platform.OS === "web" ? { paddingBottom: 50, maxWidth: "1200px", margin: "auto", display: "flex", width: "100%" } : { paddingBottom: 50 }}
            margin={Platform.OS === "web" ? "5" : "0"}
            ItemSeparatorComponent={() => <Divider my="2" />}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
        />
    )
}