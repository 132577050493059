import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const Timer = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 55 55"
    fill="none"
    {...props}
  >
    <Path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.5 4.417h9M27 30.917l6.75-6.625M27 48.583c9.941 0 18-7.91 18-17.666 0-9.757-8.059-17.667-18-17.667S9 21.16 9 30.917c0 9.757 8.059 17.666 18 17.666Z"
    />
  </Svg>
)
export default Timer
