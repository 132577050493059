import React from 'react'
import {
    HStack,
    Input,
    Text,
    VStack,
} from 'native-base';
import { useDatiContatto } from '../../../../../../core/store/rubrica/dati-contatto/dati-contatto-store';

export const Telefono = ({
    enableEdit
}: {
    enableEdit: boolean
}) => {
    const telefono = useDatiContatto(state => state.telefono);
    const setTelefono = useDatiContatto(state => state.setTelefono);
    const errors = useDatiContatto(state => state.errors);

    return (
        <VStack space={2} w="full" alignItems={"flex-start"} >
            <HStack space={2} w="full" alignItems={"center"}  >

                <Text fontSize={"xl"} fontWeight="600"
                    minW="20"
                    color="dark" _dark={{ color: "white" }}>
                    Telefono
                </Text>

                <Input textContentType='telephoneNumber' value={telefono} onChangeText={(val: string) => setTelefono(val)} fontSize={"lg"} fontWeight="400" lineHeight={"xs"} variant={"unstyled"} maxW={"70%"} numberOfLines={2} w="full" placeholder="Inserisci un numero di telefono" isDisabled={!enableEdit} />

            </HStack>
            {
                errors?.telefono && <Text fontSize={"xs"} fontWeight={"400"} color="danger.600"  >{errors?.telefono}</Text>
            }
        </VStack>
    )
}

export default React.memo(Telefono);