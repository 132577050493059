import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { WebView } from 'react-native-webview'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Text } from 'native-base'
type Props = {
    navigation: any
}

export default function Chat({navigation}: Props) {
    return (
        <SafeAreaView
        style={{
            flex: 1,
            backgroundColor: "#fff",
            paddingBottom: 20
        }}>
            <View style={{
                height: 50,
                backgroundColor: "#fff",
                borderBottomWidth: 1,
                borderBottomColor: "#e2e8f0",
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                marginHorizontal: 10
            }}>
                <Text style={{
                    fontSize: 20,
                    fontWeight: "bold"
                }} _dark={{
                    color: "dark"
                }}>Chatta con l'assistenza</Text>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Text textAlign={"right"} p="2" fontFamily={"body"} fontWeight={"400"} fontSize={"md"} color="danger.600" >
                        Indietro
                    </Text>
                </TouchableOpacity>
            </View>
            <WebView
                source={{ uri: "https://chat.gapitalia.it/index.php/ita/chat/start/(fresh)/true/(department)/43/(theme)/11" }}
                style={{ marginTop: 10, flex: 1, width: "100%", height: 400 }}
            />
        </SafeAreaView>

    )
}