import { create } from "zustand";
import { createSelectors } from "../../../utils";
import { useContatto } from "../contatto-store";
import { checkIsJson } from "../../../../utils/utils";
import { listaPriorita } from "../../../../utils/get-priorita";


export interface TrasferimentiStore {
    trasferimentoEmail: boolean,
    trasferimentoSms: boolean,
    mancatoTrasferimentoEmail: boolean,
    mancatoTrasferimentoSms: boolean,
    setTrasferimentoEmail: (trasferimentoEmail: boolean) => void,
    setTrasferimentoSms: (trasferimentoSms: boolean) => void,
    setMancatoTrasferimentoEmail: (mancatoTrasferimentoEmail: boolean) => void,
    setMancatoTrasferimentoSms: (mancatoTrasferimentoSms: boolean) => void,
    setTrasferimento: (trasferimento: TrasferimentiStore) => void,
    getAllTrasferimenti: () => any,
}

const useTrasferimentiStore = create<TrasferimentiStore>((set) => ({
    trasferimentoEmail: false,
    trasferimentoSms: false,
    mancatoTrasferimentoEmail: false,
    mancatoTrasferimentoSms: false,
    setTrasferimentoEmail: (trasferimentoEmail: boolean) => set({ trasferimentoEmail }),
    setTrasferimentoSms: (trasferimentoSms: boolean) => set({ trasferimentoSms }),
    setMancatoTrasferimentoEmail: (mancatoTrasferimentoEmail: boolean) => set({ mancatoTrasferimentoEmail }),
    setMancatoTrasferimentoSms: (mancatoTrasferimentoSms: boolean) => set({ mancatoTrasferimentoSms }),
    setTrasferimento: (trasferimento: TrasferimentiStore) => {
        set({ trasferimentoEmail: trasferimento.trasferimentoEmail })
        set({ trasferimentoSms: trasferimento.trasferimentoSms })
        set({ mancatoTrasferimentoEmail: trasferimento.mancatoTrasferimentoEmail })
        set({ mancatoTrasferimentoSms: trasferimento.mancatoTrasferimentoSms })
    },
    getAllTrasferimenti: () => {
        const {
            trasferimentoEmail,
            trasferimentoSms,
            mancatoTrasferimentoEmail,
            mancatoTrasferimentoSms,
        }= useTrasferimentiStore.getState()
        return {
            codice_policy_trasferita: JSON.stringify({ invio_email: trasferimentoEmail, invio_sms: trasferimentoSms }),
            codice_policy_non_trasferita: JSON.stringify({ invio_email: mancatoTrasferimentoEmail, invio_sms: mancatoTrasferimentoSms }),
        }
    }
}));

export const useTrasferimenti = createSelectors(useTrasferimentiStore);