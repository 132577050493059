import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Constants from "expo-constants";
import { getToken, removeToken, setToken } from "../../core/auth/utils";
import { signOut } from "../../core/auth/auth-context";

const auth = axios.create({
  baseURL: Constants.expoConfig?.extra?.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});


// Add a request interceptor that will refresh the token
auth.interceptors.request.use(async (config) => {
  const token = await getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token.access}`;
  }
  return config;
});

// Add a response interceptor that will refresh the token
const refreshAuthLogic = async (failedRequest) => {
  const tokens = await getToken();
  const data = {
    refresh: tokens?.refresh,
  };

  const options = {
    method: "POST",
    data,
    url: Constants.expoConfig?.extra?.apiUrl + "/token-auth-refresh/",
  };

  // Try request again with new token, if it fails logout user
  return axios(options)
    .then(async (tokenRefreshResponse) => {
      failedRequest.response.config.headers.Authorization =
        "Bearer " + tokenRefreshResponse.data.access;

        await setToken({access: tokenRefreshResponse.data.access, refresh: tokens?.refresh});

      return Promise.resolve();
    })
    .catch(async (e) => {
      console.log(e);
      signOut();
      await removeToken();
      // signOut();
      // authContext?.deleteTokens()
    });
};



// Create interceptor that will refresh token if the request fails
createAuthRefreshInterceptor(auth, refreshAuthLogic, {
  statusCodes: [401, 400],
});


export default auth;