import React from "react";
import axios from "axios";
import Constants from "expo-constants";

export type Registrazione = {
    nome: string,
    cognome: string,
    email: string | null,
    telefono: string | null,
    preferenza: string | null
}

async function authRegistrazione({
    nome,
    cognome,
    email,
    telefono,
    preferenza
}: Registrazione) {

  //Call the API to get the access token and refresh token
  const response = await axios.post(Constants.expoConfig?.extra?.apiUrl+"api/InviaEmailRegistrazione/", {
    nome,
    cognome,
    email,
    telefono,
    preferenza
  }).catch(err => console.log(err))

  return response?.data
}

export default authRegistrazione;
