import React from 'react'
import { createStackNavigator } from '@react-navigation/stack';
import DettagliContatto from '../screens/rubrica/contatto/index-modal';
import Modal from '../screens/dashboard/modal';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Text } from 'native-base';
import { Platform } from 'react-native';
import { useModal } from '../core/store/modal-store';

type Props = {}

const Stack = createStackNavigator();

export default function Modals({ }: Props) {

  const header = useModal(state => state.header);
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}
    >
      <Stack.Screen
        name="DashboardModal"
        options={{ headerShown: false }}
        component={Modal}
      />
      <Stack.Screen
        name="ContattoSelezionato"
        component={DettagliContatto}
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#fff",
            margin: 10,

          },
          headerTransparent: true,
          header: ({ navigation }) => <TouchableOpacity style={Platform.OS === "android" ? {
            marginVertical: 30,
            marginHorizontal: 10,
          } : {
            marginVertical: 10,
            marginHorizontal: 10,
          }} onPress={() => navigation.goBack()}>
            <Text textAlign={"right"} p="2" fontFamily={"body"} fontWeight={"400"} fontSize={"md"} color="danger.600" >
              Indietro
            </Text>
          </TouchableOpacity>
        }}
      />

    </Stack.Navigator>
  )
}