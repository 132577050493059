import { useDatiContatto } from "../../../../../../core/store/rubrica/dati-contatto/dati-contatto-store";
import {  HStack, Text} from "native-base";
import React from "react";
import List from "../../../../../../ui/components/list/list";

const Email = ({
    enableEdit,
}: {
    enableEdit: boolean,
}) => {
    const email = useDatiContatto(state => state.email);
    const setEmail = useDatiContatto(state => state.setEmail);



    return (
        <HStack space={2} w="full" alignItems={"center"} >

            <Text fontSize={"xl"} fontWeight="600"
                minW="20"
                color="dark" _dark={{ color: "white" }}>
                Email
            </Text>

           <List data={email}  setter={setEmail} enableEdit={enableEdit}
           textContentType={"emailAddress"} autoCapitalize={"none"} placeholder="Indirizzo Email" />

        </HStack>
    )
}



export default React.memo(Email);