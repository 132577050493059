export interface Ripetizione {
  oraInizio: Date;
  oraFine: Date;
  giorni: number[];
  mesi: number[];
}

export const convertCronToDataset = (cron: string): Ripetizione | undefined => {


  //Check if hour is = * (every hour)
  // Hours is formatted as follows: 10:00-12:00, so we need to split it and get the first and second element
  const oraInizio = (cron.split(" ")[0]).trim() === "*" ? new Date() : convertHourToDate(cron.split(" ")[0].split("-")[0]);
  const oraFine =  (cron.split(" ")[0]).trim() === "*" ? new Date() : convertHourToDate(cron.split(" ")[0].split("-")[1]);
  const giorno = cron
    .split(" ")[3]
    .split(",")
    .map((item: string) => findDay(item));
  const mese = cron
    .split(" ")[2]
    .split(",")
    .map((item: string) => findMonth(item));

  return {
    oraInizio: oraInizio,
    oraFine: oraFine,
    giorni: giorno.length === 1 && giorno[0] === "" ? [] : giorno,
    mesi: mese.length === 1 && mese[0] === "" ? [] : mese,
    enableOrario: cron.split(" ")[0].trim() !== "*"
  };
};

const convertHourToDate = (hour: string): Date => {
  const date = new Date();
  date.setHours(parseInt(hour.split(":")[0]));
  date.setMinutes(parseInt(hour.split(":")[1]));
  return date;
};

const findDay = (day: string): string => {
  switch (day) {
    case "1":
      return "Lunedì";
    case "2":
      return "Martedì";
    case "3":
      return "Mercoledì";
    case "4":
      return "Giovedì";
    case "5":
      return "Venerdì";
    case "6":
      return "Sabato";
    case "0":
      return "Domenica";
    default:
      return "";
  }
};

const findMonth = (month: string): string => {
    switch (month) {
        case "1":
            return "Gennaio";
        case "2":
            return "Febbraio";
        case "3":
            return "Marzo";
        case "4":
            return "Aprile";
        case "5":
            return "Maggio";
        case "6":
            return "Giugno";
        case "7":
            return "Luglio";
        case "8":
            return "Agosto";
        case "9":
            return "Settembre";
        case "10":
            return "Ottobre";
        case "11":
            return "Novembre";
        case "12":
            return "Dicembre";
        default:
            return "";
    }
}
