import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../common/client";


type Variables = {
    id: number;
    nome: string;
    cognome: string;
    email: string;
    servizio: string;
  };

export const deleteProfilo = createMutation<void ,Variables, AxiosError>({
    mutationFn: async ({ variables }) => {
      auth?.post("/api/deleteProfilo",{...variables}).then((response) => response.data).catch((err) => console.log(err));
  },
});
