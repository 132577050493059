// Query per l'aggiornamento di un nominativo

import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";


type Variables = {
    data:any;
    id: number;
  };

export const updateNominativo = createMutation<void ,Variables, AxiosError>({
    mutationFn: async ({ variables }) => {
        return auth?.post(`/api/updateNominativo`,{...variables.data, id: variables.id}).then((response) => response.data).catch((err) => console.log(err));
    }
});

