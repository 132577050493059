import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'native-base';
import Animated, {
    useSharedValue,
    useAnimatedStyle,
    withTiming,
    withRepeat,
    Easing,
    interpolateColor,
    withDelay
} from 'react-native-reanimated';
import { colors } from '../theme/generic-theme/colors';

type Props = {
    value: number,
    isScaglioni?: boolean
}

const AnimatedLinearChart = ({ value, isScaglioni }: Props) => {
    const progress = useSharedValue(0);

    const loopProgress1 = useSharedValue(0);
    const animatedStyleRange100 = useAnimatedStyle(() => {
        const color = interpolateColor(
            progress.value,
            [0, 1],
            [colors.success['400'], colors.success['600']]
        );
        return {
            width: progress.value > 100 ? "100%" : progress.value + "%",
            backgroundColor: color,
        };
    }, [progress.value]);

    const animatedStyleRange300 = useAnimatedStyle(() => {
        const color = interpolateColor(
            progress.value,
            [0, 1],
            [colors.warning['400'], colors.warning['600']]
        );
        const value = progress.value > 300 ? "100%" : progress.value > 100 ? (progress.value / 300) * 100 + "%" : 0;

        return {
            width: value,
            backgroundColor: color,
        };
    }, [progress.value]);


    const animatedStyleRangeOver300 = useAnimatedStyle(() => {
        const color = interpolateColor(
            progress.value,
            [0, 1],
            [colors.primary['100'], colors.primary['300']]
        );
        const value = progress.value > 300 ? "93%" : 0;
        return {
            width: value,
            backgroundColor: color,
        };
    }, [progress.value]);


    const animatedStyleRangeDefault = useAnimatedStyle(() => {
        const color = interpolateColor(
            progress.value,
            [0, 1],
            [colors.success['400'], colors.success['600']]
        );
        const value = (progress.value / 300) * 100 + "%";

        return {
            width: withTiming(value, { duration: 1200, easing: Easing.linear }),
            backgroundColor: color,
        };
    }, [progress.value]);

    const loopBlock1Style = useAnimatedStyle(() => ({
        transform: [{
            translateX: loopProgress1.value
        }]
    }));

    const loopBlock2Style = useAnimatedStyle(() => {
        const opacity = loopProgress1.value > 10 ? 0 : 1;
        return ({
            opacity: opacity,
            transform: [{
                translateX: loopProgress1.value
            }]
        })
    });

    useEffect(() => {
        if (value > 300) {
            // Create a loopProgress animation to move the loopBlock
            loopProgress1.value = withRepeat(withTiming(20, {
                duration: 1200, easing: Easing.inOut(Easing.bezierFn(0.25, 0.1, 0.79, -0.04))
            }), -30, true);
        }

        progress.value = withTiming(value, { duration: 800, easing: Easing.linear });

    }, [value])

    const ProgressComponent = () => (
        <View style={styles.containerLoopBlock}>
            <Animated.View style={[styles.loopBlock, loopBlock1Style]} />
            <Animated.View style={[styles.loopBlock, loopBlock2Style]} />
        </View>
    );

    if (isScaglioni) {
        return (
            <View style={[styles.upperContainer, { flexDirection: "row"}]}>
                <View style={styles.containerProgressLine} >
                    <View style={styles.containerScaglione}>
                        <Animated.View style={[styles.chart, animatedStyleRange100]} />
                        <Text style={styles.minValueText}>0</Text>
                        <Text style={styles.maxValueText}>100</Text>
                    </View>
                    <View style={styles.containerScaglione}>
                        <Animated.View style={[styles.chart, animatedStyleRange300]} />
                        <Text style={styles.minValueText}>100</Text>
                        <Text style={styles.maxValueText}>300</Text>
                    </View>
                    <View style={styles.containerScaglione}>
                        <Animated.View style={[styles.chart, animatedStyleRangeOver300]} />
                        <Text style={styles.maxValueText}>+300</Text>
                    </View>
                </View>
                {value > 300 && <ProgressComponent />}
            </View>
        )
    }


    return (
        <View style={[styles.upperContainer,{ flexDirection: "column"}]}>
            <View style={styles.containerProgressLine} >
                <View style={styles.containerConsumo}>
                    <Animated.View style={[styles.chart, animatedStyleRangeDefault, { maxWidth: "98.5%" }]} />
                </View>
                {value > 300 && <ProgressComponent />}
            </View>
            <View style={styles.containerRange} >
                <Text _dark={{color: "white"}} >0</Text>
                <Text _dark={{color: "white"}} >100</Text>
                <Text _dark={{color: "white"}} >200</Text>
                <Text _dark={{color: "white"}}>300</Text>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    upperContainer: {
        height: 30,
        width: '85%',
        display: "flex",
        alignItems: "center",
    },
    containerProgressLine: {
        display: "flex",
        flexFlow: "row",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "100%",
    },
    containerScaglione: {
        height: 20,
        width: "31%",
        backgroundColor: colors.gray['200'],
        borderRadius: 10,
        overflow: 'hidden',
        display: "flex",
        flexFlow: "row",
        justifyContent: "flex-start",
    },
    containerConsumo: {
        height: 20,
        width: "100%",
        maxWidth: "96.5%",
        backgroundColor: colors.gray['200'],
        borderRadius: 10,
        overflow: 'hidden',
        display: "flex",
        flexFlow: "row",
        justifyContent: "flex-start",
    },
    containerRange: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    chart: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-end',
        borderRadius: 10,
    },
    loopChart: {
        height: '100%',
        backgroundColor: colors.gray['200'],
        justifyContent: "flex-start",
    },
    progressText: {
        color: 'white',
    },
    range: {
        position: 'absolute',
        top: 0,
        height: '100%',
        width: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 5,
    },
    maxValueText: {
        position: 'absolute',
        top: 0,
        right: 10,
        color: colors.white,
        fontWeight: "bold",
    },
    minValueText: {
        position: 'absolute',
        top: 0,
        left: 5,
        color: colors.white,
        fontWeight: "bold"
    },
    loopBlock: {
        width: 18,
        height: 18,
        backgroundColor: colors.gray['200'],
        borderRadius: 20,
        marginHorizontal: 2,
    },
    containerLoopBlock: {
        height: "100%",
        width: "auto",
        display: "flex",
        flexFlow: "row",
        flexDirection: "row",
        top: 0,
        justifyContent: "flex-start",
        padding: 5,
        alignItems: "center",
        position: "absolute",
        right: "-3%",
        zIndex: -1

    }
});

export default AnimatedLinearChart;