import type { AxiosError } from "axios";
import { createQuery } from "react-query-kit";
import type { StatisticaLight, StatisticaLightResult } from "./types";
import auth from "../common/client";

type Response = StatisticaLightResult | undefined;

type Variables = {
  codice_servizio: string;
};

export const getStatisticheLight = createQuery<Response, Variables, AxiosError>({
  primaryKey: "statisticheLight",
  enabled: (data,variables) => Boolean(variables.codice_servizio),
  queryFn: async ({ queryKey: [primaryKey, variables] }) => {
    return auth
      ?.get(
        `/api/statisticheChiamateLight/?codice_servizio=${variables.codice_servizio}`
      )
      .then(function(response){
        return response.data;
      })
      .catch((err) => console.log(err));
  },
});
