import React, { useEffect } from "react";
import { HStack, View, Heading, Center, VStack, Text, Pressable, Link, useColorMode } from "native-base";
import { Platform, useWindowDimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import LoginHeader from "../../../ui/components/login/header";
import LoginForm from "./login-form";
import { useAuth } from "../../../core/auth/auth-context";
import authLogin from "../../../api/auth/login/login";
import { MotiView } from "moti";
import { queryClient } from "../../../api/common";
import { useForm, Controller } from "react-hook-form";
import Welcome from "../../../ui/svg/welcome";
import { Linking } from "react-native";
import { colors } from "../../../ui/theme/generic-theme/colors";

type Props = {
  navigation: any;
  route: any;
};

export default function Login({
  navigation,
  route
}: Props) {
  const signIn = useAuth.use.signIn();
  const screenWidth = useWindowDimensions().width;
  const [error, setError] = React.useState<string>("");
  const { colorMode } = useColorMode();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });


  const onSubmit = async (data: any) => {

    // call the api to get the access token and refresh token
    let response = await authLogin(data.email, data.password, null, false);


    if (!response) {
      setError("Credenziali errate");
      return;
    }

    // save the tokens in the keychain
    signIn({ access: response.access, refresh: response.refresh });

    //Refetch the user profile and servizio
    await queryClient.refetchQueries(["profilo"]);
    await queryClient.refetchQueries(["servizio"]);

  };

  useEffect(() => {
    // Handle deep links
    const handleDeepLink = async () => {
      const url = await Linking.getInitialURL();
      //Check if the url is a password recovery link, an example is:
      if (url && url.includes("recuperoPassword")) {
        // Navigate to the password recovery screen        

        // navigate to the password recovery screen and clear 
        navigation.navigate("CambioPassword", { url });
      }
    };
    handleDeepLink();
  }, [route.params?.url]);

  return (
    <SafeAreaView style={{ width: "100%", height: "100%", backgroundColor: colorMode === "dark" ? colors.dark : "white" }}>
      <View
        flex={1}
        height={"90%"}
        overflowY="hidden"
        width="100%"
      >
        <View
          w={{
            base: "95%",
            md: "95%"
          }}
          h="98%"
          mx={{
            base: "auto",
            md: "4"
          }}
          // maxW="800"
          maxH={{ base: "100%", md: "100%" }}
          bg="transparent"
          position={"relative"}
        >
          <LoginHeader />
          <MotiView
            from={{
              opacity: 0,
              translateY: 50,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
            }}
            transition={{
              type: "spring",
              delay: 100
            }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              justifyContent: "center"
            }}
          >

            <HStack mt="2" justifyContent={"center"}>
              {screenWidth > 768 && Platform.OS === "web" && <View flex={1}>
                <Welcome width={800}
                  height={650} />
              </View>}
              <VStack minW={"40%"} maxW={"95%"} h="full" >
                <Heading
                  _dark={{
                    color: "white"
                  }}
                  mx="4"
                  color="dark"
                  fontWeight={"800"}
                  fontSize={"5xl"}
                  fontFamily={"body"}
                  textAlign={"left"}
                >
                  Benvenuto!
                </Heading>
                <Heading
                  maxW={"85%"}
                  mx="4"
                  _dark={{
                    color: "white"
                  }}
                  color="dark"
                  fontWeight={"500"}
                  fontSize={"md"}
                  lineHeight={"xs"}
                  fontFamily={"body"}
                  textAlign={"left"}
                  marginBottom={"5%"}
                >
                  Benvenuto nell'app di monitoraggio della tua segreteria remota
                </Heading>
                <LoginForm control={control} errors={errors} navigation={navigation} setError={(val) => setError(val)} error={error} />
                {/* </LinearGradient> */}
                <VStack mx="4" mt="auto" minW="350">
                  <Pressable bg="primary.400" minH={"55"} p="2" rounded={"md"} onPress={handleSubmit(onSubmit)} >
                    {({
                      isHovered,
                      isFocused,
                      isPressed
                    }) =>

                      <Center style={{
                        opacity: isHovered || isFocused || isPressed ? 0.8 : 1,
                        transform: isHovered || isFocused || isPressed ? [{ scale: 0.98 }] : [{ scale: 1 }]
                      }} flex={1}>
                        <Text color="white" fontWeight={"bold"} textAlign={"center"} fontSize={"lg"} >Accedi</Text>
                      </Center>
                    }
                  </Pressable>
                  <Pressable mt="4" onPress={() => navigation.navigate("Registrazione")} justifyContent={"center"}>
                    <Text textAlign={"center"} _dark={{ color: "gray.300" }} color="neutral.800" fontWeight={"400"} fontSize={"md"} fontFamily={"body"} >
                      Non hai un account? Registrati
                    </Text>
                  </Pressable>

                </VStack>
              </VStack>
            </HStack>
          </MotiView>
        </View>
      </View>
    </SafeAreaView>
  );
}
