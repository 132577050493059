
import PhoneForwarded from "../ui/svg/icons/phone-forwarded";
import PhoneIncoming from "../ui/svg/icons/phone-incoming";
import Info from "../ui/svg/icons/info";
import { colors } from "../ui/theme/generic-theme/colors";
import Calendar from "../ui/svg/icons/calendar";
import Mute from "../ui/svg/icons/mute";
import Timer from "../ui/svg/icons/timer";
import Phone from "../ui/svg/icons/phone";

type Props= {
    scopo: string;
    colorMode: "dark" | "light" | null | undefined;
    size: {
        width: number
        height: number
    }
}
export function getScopi({scopo,colorMode,size}: Props){

    switch(scopo){
        case scopo.match(/Richiesta.*/g)?.[0]:
            return <PhoneIncoming color={colorMode === "dark" ? "#fff" : colors.dark} {...size}  />;
        case scopo.match(/Appuntamento.*/g)?.[0]: 
            return <Calendar color={colorMode === "dark" ? "#fff" : colors.dark}  {...size} />;
        case scopo.match(/Informazioni.*/g)?.[0]:
            return <Info color={colorMode === "dark" ? "#fff" : colors.dark}  {...size} />;
        case "Recall":
            return <PhoneForwarded color={colorMode === "dark" ? "#fff" : colors.dark}  {...size} />;
        case "Mute" || "Muta":
            return <Mute color={colorMode === "dark" ? "#fff" : colors.dark}  {...size} />;
        case "Fuori Orario":
            return <Timer color={colorMode === "dark" ? "#fff" : colors.dark}  {...size} />;
        case "Altro": 
            return <Phone color={colorMode === "dark" ? "#fff" : colors.dark}  {...size} />;
        default:
            return <Phone color={colorMode === "dark" ? "#fff" : colors.dark}  {...size} />;
    }
}