import React from "react";
import axios from "axios";
import Constants from "expo-constants";


async function authLogin(username: string, password: string, token_captcha: string | null, enable_captcha: boolean | null) {

  //Call the API to get the access token and refresh token
  const response = await axios.post(Constants.expoConfig?.extra?.apiUrl+"/auth/token-auth/", {
    username,
    password,
    token_captcha,
    enable_captcha
  }).catch(err => console.log(err))

  return response?.data
}

export default authLogin;
