import { formatDate, formatDateLeadingZero, formatDateTimeLeadingZero } from "../../../utils/utils"

export const DatePicker = (props: any) => {

    const handleTimeChange = (onChange: any) => (event: any) => {
        const currentDate = event.target.value;
        const date = new Date(props.value);
        const hours = currentDate.split(":")[0];
        const minutes = currentDate.split(":")[1];
        date.setHours(hours);
        date.setMinutes(minutes);
        onChange(null,formatDateTimeLeadingZero(date));
    }



    if(props.mode === "time"){
        return <input type={props.mode} value={new Date(props.value).getHours()+ ":" + new Date(props.value).getMinutes()} onChange={handleTimeChange(props.onChange)} />
    }

    return <input type={props.mode}  value={formatDateLeadingZero(new Date(props.value))} onChange={props.onChange} />
}