import { HStack, Input, Text } from 'native-base';
import React from 'react'
import { useDatiContatto } from '../../../../../../core/store/rubrica/dati-contatto/dati-contatto-store';
import List from '../../../../../../ui/components/list/list';

const Alias = ({
    enableEdit
}: {
    enableEdit: boolean
}) => {
    const alias = useDatiContatto(state => state.alias);
    const setAlias = useDatiContatto(state => state.setAlias);

    return (
        <HStack space={2} w="full" alignItems={"center"}  >

            <Text fontSize={"xl"} fontWeight="600"
                minW="20"
                color="dark" _dark={{ color: "white" }}>
                Alias
            </Text>

           <List data={alias} setter={setAlias} enableEdit={enableEdit}
           autoCapitalize={"words"} textContentType={"none"} placeholder='Alias' />

        </HStack>
    )
}

export default React.memo(Alias);