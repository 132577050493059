import { useEffect, useState } from 'react'
import { Svg } from 'react-native-svg';
import { VictoryAnimation, VictoryLabel, VictoryPie } from 'victory-native';
import { useWindowDimensions } from 'react-native';
import { colors } from '../../../../ui/theme/generic-theme/colors';
import { ColorMode } from 'native-base';

type Props = {
  data: any;
  title: string;
  isConsumate: boolean;
  colorMode: ColorMode
}

export default function ProgressBar({ data, title, isConsumate,colorMode }: Props) {
  const dimensions = useWindowDimensions();

  return (
    <Svg viewBox="0 0 280 280" height={150} width={dimensions.width > 768 ? "100%" : "50%"}>
      <VictoryPie
        standalone={false}
        width={300} height={300}
        data={data.data}
        innerRadius={110}
        cornerRadius={20}
        labels={() => null}
        style={{
          data: {
            fill: ({ datum }) => {

              var color = isConsumate ? "rgb(245, 176, 203)" : colors.success['200'];



              return datum.x === 1 ? color : "lightgrey";
            }
          },
        }}
      />
          <VictoryLabel
            textAnchor="middle" verticalAnchor="middle"
            x={155} y={135}
            text={`${parseInt(data.percent.toString())}%`}
            style={{ fontSize: 45, fill: colorMode === "light" ? colors.dark : "#fff" }}
          />
      <VictoryLabel
        textAnchor="middle" verticalAnchor="middle"
        x={150} y={180}
        text={title}
        style={{ fontSize: 25, fill: colorMode === "light" ? colors.dark : "#fff" }}
      />
    </Svg>
  )
}
