import { Box, Divider, HStack, Text, VStack, Icon, Spinner } from 'native-base';
import useFormatIntervallo from '../../../../../utils/rubrica/get-format-intervallo';
import { ScrollView } from 'react-native';
import { useContatto } from '../../../../../core/store/rubrica/contatto-store';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from "@expo/vector-icons"
import { getRegole } from '../../../../../api/nominativi/get/get-regole';
import { firstLetterUppercase, replaceUnderscoreWhitespace, replaceUnderscoreWhitespaceToUppercase } from '../../../../../utils/utils';
import { AnimatePresence } from 'moti';
import { deleteRegola } from '../../../../../api/nominativi/remove/delete-regola';
import { queryClient } from '../../../../../api/common';
import { Regole } from '../../../../../api/nominativi';
import { useRegole } from '../../../../../core/store/rubrica/regole/regole-store';

export function RegoleCentralino() {

  const formatIntervallo = useFormatIntervallo();
  const editSezioneContatto = useContatto(state => state.editSezioneContatto);
  const contatto = useContatto(state => state.contatto);
  const navigation = useNavigation()
  const setInitialValues = useRegole(state => state.setInitialValues);
  const resetData = useRegole(state => state.resetData);


  const { data, isLoading, isError } = getRegole({
    variables: {
      id_nominativo: contatto?.id
    }
  });

  const { mutate: removeRegola, isLoading: loadingDeleting, isError: errorDeleting } = deleteRegola({
    onSuccess: (data) => {
      queryClient.invalidateQueries(getRegole.getKey());
    }
  });

  const handleDeleteRegola = (item: Regole) => {
    const data = removeRegola({
      variables: {
        regola_id: item.id
      }
    })
  }

  const handleModificaRegola = async (item: Regole) => {
    setInitialValues(item)
    navigation.navigate("FormRegola", {
      screen: "RegolaNavigator",
      params: {
        isEdit: true,
        regola: item
      }
    });
  }

  const handleAddRegola = async (item:Regole) => {
    resetData();
    navigation.navigate("FormRegola", {
      screen: "RegolaNavigator",
      params: {
        isEdit: false,
        regola: item
      }
    });
  }

  if (isLoading) return <Spinner />;
  if (isError) return <Text>Errore</Text>;


  return (
    <ScrollView contentContainerStyle={{
      paddingBottom: 100
    }}>
      <VStack space={2} >
        {editSezioneContatto &&
          <AnimatePresence>
            <VStack>
              <TouchableOpacity onPress={() => handleAddRegola()}>
                <HStack minH="16" alignItems={"center"} justifyContent={"space-between"} borderRadius="md">
                  <Text fontSize={"md"} textTransform={"uppercase"} fontWeight="400" lineHeight={"md"} color={"success.600"} my="1" >
                    Aggiungi regola
                  </Text>
                  <Icon as={<Ionicons name="add-circle-outline" />} color="success.600" size="6" />
                </HStack>
              </TouchableOpacity>
              <Divider />
            </VStack>
          </AnimatePresence>}
        {(data?.presenza?.length > 0 || data?.assenza.length > 0) ? data?.assenza.concat(data.presenza).map((regola: any, index: number) => (
          <HStack w="full" justifyContent={"center"} alignItems={"center"} key={index}>
            <VStack w="full" >
              <HStack alignItems={"center"}>
                <Icon mr="1" as={<Ionicons name={"person-outline"} />} color={regola.period_type === "assenza" ? "danger.600" : "success.600"} size="6" />
                <Text fontSize={"lg"} textTransform={"uppercase"} fontWeight="bold" lineHeight={"md"} _dark={{ color: "white" }} color={"dark"} my="1" >
                  {regola.period_type === "assenza" ? "Assenza" : "Presenza"}
                </Text>
                {editSezioneContatto &&
                <AnimatePresence>
                  <HStack ml="auto" justifyContent={"flex-end"} space={2}>
                    <TouchableOpacity onPress={() => handleModificaRegola(regola)}>
                      <Text fontSize={"md"} fontWeight="400" lineHeight={"md"} color="gray.600"  my="1" >
                        Modifica
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => handleDeleteRegola(regola)}>
                      <Text fontSize={"md"} fontWeight="400" lineHeight={"md"} color="danger.600" my="1" >
                        Elimina
                      </Text>
                    </TouchableOpacity>
                  </HStack>
                </AnimatePresence>}
              </HStack>
              {regola.period_type === "assenza" && <Text mt="1" fontSize={"xl"}fontWeight="400" color="dark" _dark={{ color: "white" }} >
                  {replaceUnderscoreWhitespaceToUppercase(regola?.motivo_assenza?.motivo)}
                </Text>}
              <Text fontSize={"lg"} fontWeight="400"  _dark={{ color: "white" }} lineHeight={"xs"} color="dark" my="1">
                {formatIntervallo(regola?.intervallo_assenza)}
              </Text>
              <Divider my="1" />
            </VStack>

          </HStack>
        )) : <Text fontSize={"xl"} fontWeight="400" lineHeight={"md"} color="dark" _dark={{ color: "white" }} my="1">
          Nessuna regola impostata
        </Text>}
      </VStack>
    </ScrollView>
  )
}