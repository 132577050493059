import React, { useEffect } from 'react'
import { Servizio, ServizioResult } from '../../../api/servizi';
import { Divider, HStack, Skeleton, Stack, Text, VStack, View } from 'native-base';
import BoxContainer from '../../../ui/components/layout/box-container';
import { getServizioIcon, getServizioIconSource } from '../../../utils/get-icons';
import { storage } from '../../../core/utils';
type Props = {
  servizioSelezionato: ServizioResult,
  listaServizi: ServizioResult[] | undefined
}

export default function Servizi({ servizioSelezionato, listaServizi }: Props) {


  if (
    !listaServizi ||
    listaServizi.length === 0
  ) return <BoxContainer>
    <Stack flexDir={{
      base: "column",
      md: "row"
    }} space={2} alignItems={"center"} justifyContent={"flex-start"} w="full">
      <HStack w="full" flex={1}>
        <Skeleton w="4" h="4" rounded="full" />
        <Stack alignItems={"center"} flexDir={"row"} space={2} >
          <Skeleton mr={2} w="20" h="20" rounded={"2xl"} />
          <VStack w="full" space={2} >
            <Skeleton w="48" h="8" rounded={"2xl"} />
            <Skeleton w="48" h="6" rounded={"2xl"} />
            <Skeleton w="48" h="4" rounded={"2xl"} />
          </VStack>
        </Stack>
      </HStack>
      <Divider orientation={"vertical"} display={{
        base: "none",
        md: "flex"
      }} />
      <VStack ml={"10"} display={{
        base: "none",
        md: "flex"
      }} alignItems="flex-start" space={2} >
        <Skeleton w="20" h="8" rounded={"2xl"} />
        <Skeleton w="48" h="6" rounded={"2xl"} />
      </VStack>
    </Stack>
  </BoxContainer>;


  return (
    <BoxContainer >
      <Stack flexDir={{
        base: "column",
        md: "row"
      }} space={2} alignItems={"center"} justifyContent={"flex-start"} w="full">

        <HStack w="full" flex={1}>
          <View position={"absolute"} bg="success.600" w="4" h="4" rounded="full"></View>
          <Stack alignItems={"center"} flexDir={"row"} space={2} maxW={{
            base: "full",
            sm: "90%",
            md: "90%"
          }} >
            {/* Render logo base on the tipo_pacchetto */}
             {getServizioIcon(servizioSelezionato?.tipo_pacchetto, 120, 120)}
            <VStack w={{
              base: "full",
              sm: "90%",
              md: "90%"
            }} >
              <Text maxW={{
                base: "45%",
                sm: "100%",
                md: "100%"
              }} lineHeight={"xs"} color="dark" _dark={{ color: "white" }} fontFamily={"body"} fontSize={{
                base: "lg",
                md: "xl"
              }} fontWeight={"500"} >{(servizioSelezionato?.descrizione)}</Text>
              <Text color="dark" _dark={{ color: "white" }} fontSize={"md"}>Codice Servizio: {servizioSelezionato?.codice_servizio}</Text>
              <Text maxW={{
                base: "45%",
                md: "100%"
              }} color="success.600" fontSize={{
                base: "sm",
                md: "md"
              }} fontFamily={"body"} numberOfLines={1} isTruncated fontWeight={600} >
                {/* {servizioSelezionato && servizioSelezionato?.numeri?.map((numero, index, arr) => index < arr.length - 1 ? numero.numero_telefonico + ", " : numero.numero_telefonico)} */}
                {servizioSelezionato && servizioSelezionato?.numeri?.map((numero: any, index: number, arr: any) => index < arr.length - 1 ? numero.numero_telefonico + " (" + numero.tipo_numero + ") ," : numero.numero_telefonico+ " (" + numero.tipo_numero + ") ")}
              </Text>
            </VStack>
          </Stack>
        </HStack>
      </Stack>
    </BoxContainer>
  )
}