import React from 'react'
import { HStack, Text, VStack, Switch } from 'native-base'
import { useDatiContatto } from "../../../../../../../core/store/rubrica/dati-contatto/dati-contatto-store";
import { useTrasferimenti } from "../../../../../../../core/store/rubrica/trasferimenti/trasferimenti-store";

type Props = {}

const TrasferimentoEmail = ({ editSezioneContatto }: {
    editSezioneContatto: boolean | undefined,
}) => {
    const trasferimentoEmail = useTrasferimenti(state => state.trasferimentoEmail);
    const email = useDatiContatto(state => state.email);
    const setTrasferimentoEmail = useTrasferimenti(state => state.setTrasferimentoEmail);
    const [ error,setError] = React.useState<string | undefined>("");

    const toggleTrasferimentoEmail = (val: any) => {
        if(email.length === 0){
            return setError("Inserisci almeno un'email");
        }else{
            setError("");
        }
        setTrasferimentoEmail(val);
    }

    return (
        <VStack space={2} w="full" alignItems={"flex-start"} >
        <HStack space={2} alignItems={"center"} w="full" >
            <VStack maxW={"90%"} space={2} >
                <Text fontSize={"xl"} fontWeight="600" minW="24" color="dark" _dark={{ color: "white" }}>
                    Email
                </Text>
                <Text lineHeight={"xs"} fontSize={"lg"} fontWeight="400" color="dark" _dark={{ color: "white" }}>
                    {trasferimentoEmail ? email.join(", ") : "Non inviare"}
                </Text>
            </VStack>
            <Switch ml="auto" mx="2" size="md" colorScheme="success" isChecked={trasferimentoEmail} onToggle={toggleTrasferimentoEmail} isDisabled={!editSezioneContatto} />
        </HStack>
        {
           error && <Text fontSize={"xs"} fontWeight={"400"} color="danger.600"  >{error}</Text>
        }
        </VStack>
    )
}

export default React.memo(TrasferimentoEmail);