import Constants from "expo-constants";
import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";

export type CambioPasswordVariables = {
    variables: {
        id_user: number,
        vecchia_password: string,
        nuova_password: string
    }
}

export const authCambioPassword = createMutation<void, CambioPasswordVariables, AxiosError>({
    mutationFn: async ({ variables }) => {

        return await auth.put(Constants.expoConfig?.extra?.apiUrl + "/auth/change_password/" + variables.id_user + "/", { old_password: variables.vecchia_password, password: variables.nuova_password, password2: variables.nuova_password }).then((response) => response.data);

    }
});

export default authCambioPassword;
