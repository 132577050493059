import React from 'react'
import { HStack, Input, Text, VStack, View, Icon } from 'native-base';
import { TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { AnimatePresence } from 'moti';
import { useWindowDimensions } from "react-native"

type Props = {
    data: string[] | undefined,
    setter: (val: string) => void,
    enableEdit?: boolean,
    textContentType?: any,
    autoCapitalize?: any,
    placeholder?: string,
}

const List = ({ data, setter, enableEdit, textContentType, autoCapitalize, placeholder }: Props) => {

    return (
        <VStack mx="2" space={2} w="full" >
            {data?.map((item: any, index: number, arr: string[]) => {

                const handleChangeText = (newItem: string, index: number, arr: []) => {

                    //Find the index of item in array
                    const newArray = [...arr];


                    const itemIndex = newArray.findIndex((item: any, indexItem) => index === indexItem);
                    //Update the item in array
                    newArray[itemIndex] = newItem;
                    //Update the state

                    setter(newArray);

                }
                return <AnimatePresence key={index} >
                    <HStack maxW={"90%"} key={index} justifyContent={"flex-start"}>
                        {enableEdit && <View w={6} >
                            <TouchableOpacity onPress={() => setter(arr.filter((item: any, indexItem: number) => indexItem !== index))} >
                                <Icon as={<Ionicons name="close-circle-outline" />} size={6} color="danger.500" />
                            </TouchableOpacity>
                        </View>}
                        <Input
                            autoFocus textContentType={textContentType} autoCapitalize={
                                autoCapitalize
                            } value={item} onChangeText={(val) => handleChangeText(val, index, arr)} fontSize={"lg"} fontWeight="400" lineHeight={"xs"} variant={"unstyled"} w="80%" placeholder={placeholder} isDisabled={!enableEdit} />
                    </HStack>
                </AnimatePresence>


            })}

            {enableEdit && <AnimatePresence >
                <TouchableOpacity style={{ width: "100%", paddingVertical: 8, justifyContent: "center" }} disabled={!enableEdit} onPress={() => setter(
                    [...data, ""]
                )} >
                    <Text color={"success.600"} fontSize={"lg"} fontWeight="400" lineHeight={"xs"} variant={"unstyled"} maxW={"70%"} numberOfLines={2} w="full" >
                        <Icon as={<Ionicons name="add-circle-outline" />} size={6} color="success.600" />
                        {"  "} Aggiungi {placeholder}
                    </Text>
                </TouchableOpacity>
            </AnimatePresence>}
        </VStack>
    )
}

export default List