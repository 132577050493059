import { Ripetizione } from "../../core/store/rubrica/regole/regole-store";

export const getCronFixedList = (): Ripetizione[] => {
    return [
        {
            id: 0,
            message: "Nessuna",
            cron: ""
        },
        {
            id: 1,
            message: "Ogni giorno (tutti i giorni)",
            cron: "* * * *"
        },
        {
            id: 2,
            message: "Ogni settimana (il lunedì)",
            cron: "* * * 0"
        },
        {
            id: 3,
            message: "Ogni mese (il primo giorno del mese)",
            cron: "* 1 * *"
        },
        {
            id: 5,
            message: "Ogni giorno lavorativo (lun-ven)",
            cron: "* * * 1-5"
        },
        {
            id: 6,
            message: "Ogni fine settimana (sabato e domenica)",
            cron: "* * * 6,0"
        },
        {
            id: 7,
            message: "Personalizzata",
            cron: "",
            oraInizio: new Date(),
            oraFine: new Date(),
            giorni: [],
            mesi: []
        },
    ];
}


export const checkCronFixed = (cron: string): Ripetizione | undefined => {
    const list = getCronFixedList();
    return list.find(item => item.cron === cron);
}