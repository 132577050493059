import type { AxiosError } from "axios";
import { createQuery } from "react-query-kit";
import type { ScopiResult } from "./types";
import auth from "../common/client";

type Response = ScopiResult | undefined;

type Variables = {
    codice_servizio: string;
    data_inizio: string;
    data_fine: string;
};

export const getScopi = createQuery<Response, Variables, AxiosError>({
    primaryKey: "scopi",
    enabled: (data,variables) => Boolean(variables.codice_servizio),
    queryFn: async ({ queryKey: [primaryKey, variables] }) => {
        return auth
            ?.get(
                `api/statisticheScopi/?codice_servizio=${variables.codice_servizio}&p_data_inizio=${variables.data_inizio}&p_data_fine=${variables.data_fine}`
            )
            .then((response) => {
                //The scopo "Altro" has to be the last one and the scopo "Richiesta di Contatto" has to be the first one
                return response.data.sort((a: any, b: any) => {
                    if (a.scopo == "Richiesta di Contatto" || a.scopo == "Appuntamento" || a.scopo == "Informazioni") return -1;
                    if (b.scopo == "Altro" || b.scopo == "Test") return -1;
                    return 0;
                });
            })
            .catch((err) => console.log(err));
    },
});

