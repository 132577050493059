import { HStack, VStack, Text, Divider, Switch } from "native-base"
import { checkIsJson } from "../../../../../utils/utils"
import { useTrasferimenti } from "../../../../../core/store/rubrica/trasferimenti/trasferimenti-store"
import { ScrollView } from "react-native"
import { useDatiContatto } from "../../../../../core/store/rubrica/dati-contatto/dati-contatto-store"
import { useContatto, useContattoStore } from "../../../../../core/store/rubrica/contatto-store"
import TrasferimentoEmail from "./trasferimento/email"
import TrasferimentoSms from "./trasferimento/sms"
import MancatoTrasferimentoEmail from "./mancato-trasferimento/email"
import MancatoTrasferimentoSms from "./mancato-trasferimento/sms"

export function TrasferimentiCentralino() {


  const editSezioneContatto = useContatto(state => state.editSezioneContatto);

  return (
    <ScrollView contentContainerStyle={{
      paddingBottom: 100
    }}>
      <VStack space={4} >
        <VStack space={3} my="1" >
          <Text fontSize={"xl"} fontWeight="600" lineHeight={"md"} color="dark" _dark={{ color: "white" }} >
            Avviso in caso di trasferimento
          </Text>
          <Divider />
          <TrasferimentoEmail editSezioneContatto={editSezioneContatto} />
          <Divider />
          <TrasferimentoSms  editSezioneContatto={editSezioneContatto} />
        </VStack>
        <VStack space={3} my="1" >
          <Text fontSize={"xl"} fontWeight="600" lineHeight={"md"} color="dark" _dark={{ color: "white" }} >
            Avviso in caso di mancato trasferimento
          </Text>
          <Divider />
          <MancatoTrasferimentoEmail  editSezioneContatto={editSezioneContatto} />
          <Divider />
          <MancatoTrasferimentoSms  editSezioneContatto={editSezioneContatto} />
        </VStack>
      </VStack>
    </ScrollView>
  )
}