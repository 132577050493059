import type { AxiosError } from "axios";
import { createQuery } from "react-query-kit";
import auth from "../common/client";
import type { Profilo } from "./types";
import { useProfilo } from "../../core/store/profilo-store";

type Response = Profilo;

export const getProfilo = createQuery<Response, void, AxiosError>({
  primaryKey: "profilo",
  queryFn: () => {
    return auth
      ?.get("/api/profilo/")
      .then(async (response) => {
        const profilo = useProfilo.getState();
        profilo.setDatiProfilo(response.data);
        return response.data;
      })
      .catch((err) => console.log(err));
  },
});
