import React from 'react'
import { View, Text, HStack, VStack, Divider, Skeleton } from 'native-base'
import { convertDatetoItalian, getHours } from '../../../../utils/utils'
import { getScopi } from '../../../../utils/get-scopi'

type Props = {
    item: any,
    colorMode: any,
    isLoading: boolean,
    isError: boolean
}

export default function Chiamata({ item, colorMode, isLoading, isError }: Props) {

    if (isLoading || isError) return (
        <HStack position={"relative"} minW={"64"} maxW={"64"} mx="4" justifyContent={"center"} minH={"40"} alignItems={"center"}>
            <Skeleton w="20" h="20" rounded={"2xl"} />
            <VStack flex={1} ml="1" space={2} >
                <Skeleton w="32" h="8" rounded={"2xl"} />
                <Skeleton w="32" h="6" rounded={"2xl"} />
            </VStack>
            <Skeleton position={"absolute"} top="5" right={"0"} h="3" w="20" rounded={"2xl"} />
        </HStack>
    )

    return (
        <HStack minH={"32"}  position={"relative"} mx="4" minW={"64"} maxW={"64"} justifyContent={"center"} alignItems={"center"}>
            <View maxW={"60"} maxH={"60"} mr="2">
                {getScopi({ scopo: item?.scopo, colorMode: colorMode, size: { width: 60, height: 60 } })}
            </View>
            <VStack flex={1} ml="1"  >
                <Text maxW={"64"} _dark={{ color: "white" }} isTruncated color={"dark"} fontFamily={"body"} fontSize={"xl"} lineHeight={"xs"} fontWeight={"700"} >{item?.cognome ? item?.cognome : item?.numero_telefonico_cdr}</Text>
                <Text lineHeight={"sm"} _dark={{ color: "white" }} fontFamily={"body"} fontSize={"lg"} fontWeight={"400"} color={"dark"}>{item?.scopo}</Text>
                <Text fontFamily={"body"} italic fontSize={"sm"} fontWeight={"400"} _dark={{ color: "white" }} color={"dark"}>{convertDatetoItalian(item?.data_registrazione) + ", alle " + getHours(item?.data_registrazione)}</Text>
            </VStack>
        </HStack>
    )
}