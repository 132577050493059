import { VStack, HStack, Divider, View, Spinner, Stack, Text, Modal, Pressable, Icon } from "native-base";
import { getChiamateInfinite } from "../../api/chiamate";
import { RectButton } from "react-native-gesture-handler";
import React, { useState } from "react";
import { useServizio } from "../../core/store/servizio-store";
import SearchBar from "../../ui/components/search-bar/search-bar";
import FiltroScopi from "./filtro-scopi";
import { convertDatetoItalian, debounce, getHours, replaceUnderscoreWhitespace } from "../../utils/utils";
import AppContentContainer from "../../ui/components/layout/app-content";
import { FlashList } from "@shopify/flash-list";
import { EmptyList } from "../../ui/components/empty-list/empty-list";
import { getScopi } from "../../utils/get-scopi";
import { useColorMode } from "native-base";
import {Ionicons} from "@expo/vector-icons";
import { Linking } from "react-native";


export default function Chiamate() {

  const [search, setSearch] = useState<string>("")
  const [selected, setSelected] = useState(null)
  const [scopoSelezionato, setScopoSelezionato] = useState("")
  const { colorMode } = useColorMode()
  const servizioSelezionato = useServizio(state => state.servizio)
  const { data: chiamate, fetchNextPage, isLoading, hasNextPage, isFetchingNextPage, isError, error } = getChiamateInfinite({
    variables: {
      codice_servizio: servizioSelezionato?.codice_servizio,
      limit: 12,
      search: search,
      scopo: scopoSelezionato
    },
  })

  const handleSearch = (val: string) => {
    setSearch(val)
  }

  const handleScroll = (e: any) => {
    const { contentOffset, contentSize, layoutMeasurement } = e.nativeEvent

    // Se siamo alla fine della lista e ci sono altre pagine da caricare e non stiamo già caricando una pagina allora carica la pagina successiva
    if (contentOffset.y >= contentSize.height - layoutMeasurement.height - 50 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }

  }


  return (
    <AppContentContainer>
      <View position={"relative"} top={"0"} left={"0"} right={"0"} zIndex={1}  >
        <FiltroScopi scopoSelezionato={scopoSelezionato} setScopo={(val: string) => setScopoSelezionato(val)} servizioSelezionato={servizioSelezionato} />
      </View>
      <View width="100%" zIndex={0}  >
        <SearchBar width="98%" search={search} onClear={() => handleSearch("")} onChange={debounce(
          (val: string) => handleSearch(val),
          300
        )} />
      </View>
      {
        chiamate?.pages.length === 0 && !isLoading && !isError ? (
          <Stack alignItems={"center"} justifyContent={"center"} minH="16" w="full">
            <Text color="dark" _dark={{ color: "white" }} fontSize={"2xl"} fontWeight={"bold"}>Nessuna chiamata trovata</Text>
          </Stack>
        ) : <FlashList
          onScroll={handleScroll}
          ListEmptyComponent={<EmptyList isLoading={isLoading} />}
          contentContainerStyle={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(25rem, 1fr)", gap: "1rem", margin: "2rem" }}
          data={isLoading || isError ? [...Array(12)] : chiamate?.pages?.map((page) => page).flat()}
          ItemSeparatorComponent={() => <Divider />}
          renderItem={({ item, index }) =>
            isLoading || isError ? (
              <Stack alignItems={"center"} justifyContent={"center"} minH="16" w="full">
                <Spinner />
              </Stack>
            ) : (
              <View >
                <RowChiamata colorMode={colorMode} selected={selected} setSelected={(item: any) => setSelected(item)} item={item} />
                {
                  index === chiamate?.pages?.map((page) => page).flat().length - 1 && isFetchingNextPage && (
                    <View>
                      <Spinner />
                    </View>
                  )
                }
              </View>
            )
          }
          h="100%"
          keyExtractor={(_item, index) => `${index}`}
        />
      }

      <ChiamateModalWeb selected={selected} setSelected={setSelected} />
    </AppContentContainer>
  )
}

// Improve performance of RowChiamata
export const RowChiamata = React.memo(({ item, index, selected, setSelected, colorMode }: any) => (
  <RectButton onPress={() => setSelected(item)} >
    <HStack p="2" px="4" justifyContent={"space-between"} alignItems={"center"}>
      <VStack flex={1}>
        <Text noOfLines={2} lineHeight={"md"} fontWeight={"bold"} fontSize={"2xl"} maxW={"80%"} >{item?.cognome ? item?.cognome : item?.numero_telefonico_cdr}</Text>
        <HStack w="full" space={2} alignItems="center" justifyContent="flex-start">
          <View minW="60" minH="60">
            {getScopi({ scopo: item?.scopo, colorMode: colorMode, size: { width: 60, height: 60 } })}
          </View>
          <VStack maxW={"80%"} >
            <Text color="dark" _dark={{
              color: "gray.200"
            }} lineHeight={"md"} fontSize={"lg"} fontWeight={"400"}>{item.scopo}</Text>
            <Text minH={"10"} noOfLines={2} lineHeight={"md"} fontSize={"sm"} >{item.note}</Text>
          </VStack>
        </HStack>
      </VStack>
      <Text position={"absolute"} fontWeight={"300"} fontStyle={"italic"} top="1.5" right={"4"}>{convertDatetoItalian(item.data_registrazione) + ", " + getHours(item.data_registrazione)}</Text>
    </HStack>
  </RectButton>
));


export const ChiamateModalWeb = ({ selected, setSelected }: any) => {

  return (
    <Modal isOpen={Boolean(selected)} onClose={() => setSelected(null)}>
      <Modal.Content _dark={{ borderColor: "gray.800", borderWidth: 1, bg: "dark" }} p="4" maxWidth="600" maxH="800">
        <Modal.CloseButton
          top="-3"
          right="-3"
        />
        <VStack mx="2" justifyContent={"space-between"} minH={"full"}>
          <VStack mx="2" space={2} flex={1}>
            <HStack justifyContent={"space-between"} w="full"   >
              <Text lineHeight={"xs"} fontWeight={"bold"} fontSize={"3xl"} maxW={"60%"} >{selected?.cognome ? selected?.cognome : selected?.numero_telefonico_cdr}</Text>
              <Text mb="auto" color="dark" fontSize={"md"} fontWeight={"300"}>{convertDatetoItalian(selected?.data_registrazione) + ", " + getHours(selected?.data_registrazione)}</Text>
            </HStack>
            <Text _dark={{ color: "white" }} color="info.800" lineHeight={"md"} fontSize={"xl"} maxW={"80%"} fontWeight={"400"}>{selected?.scopo}</Text>
            <Text _dark={{ color: "white" }} minH={"10"} lineHeight={"md"} fontSize={"md"} >{selected?.note}</Text>
            {selected?.dati && Object.keys(selected?.dati)?.map((item: any, index: number) => {

              if (selected?.dati[item] && item !== "testo_sms") {
                return (
                  <VStack key={index} w="full">
                    <Divider w="full" my="2" />
                    {selected?.dati[item] && <Text key={index} color="dark" _dark={{
                      color: "gray.200"
                    }} lineHeight={"md"} fontSize={"sm"} fontWeight={"400"}>
                      <Text fontWeight={"bold"}>{replaceUnderscoreWhitespace(item) + " "}</Text>
                      {selected?.dati[item]}
                    </Text>}
                  </VStack>
                )
              }

            })}
            <Pressable onPress={() => Linking.openURL(`tel:${selected?.numero_telefonico_cdr}`)} flex={1} justifyContent={"flex-end"} alignItems={"flex-start"} >
              <HStack space={2} alignItems="center" p="2" justifyContent="center">
                <Icon as={
                  <Ionicons name="call-outline" />
                } color={!selected?.numero_telefonico_cdr ? "gray.600" : "success.600"} size={"lg"} />
                <Text disabled={!selected?.numero_telefonico_cdr} color={!selected?.numero_telefonico_cdr ? "gray.600" : "success.600"} fontWeight={"600"} fontSize={"lg"}>Chiama {selected?.numero_telefonico_cdr}</Text>
              </HStack>
            </Pressable>
          </VStack>
        </VStack>
      </Modal.Content>
    </Modal>

  )
}