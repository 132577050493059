import React from 'react'
import { HStack, View } from 'native-base'
import Animated from 'react-native-reanimated'
import PaginationItem from './pagination-item/pagination-item'

type Props = {
    dotsLength: number,
    activeDotIndex: Animated.SharedValue<number>,
}

export default function Pagination({ dotsLength, activeDotIndex }: Props) {

    return (
        <HStack maxW={dotsLength == 2 ? "20" : "24"} position={"absolute"} right={"0px"}  alignItems={"center"} py="2" mx="auto" w="full" justifyContent={"space-evenly"} >
            {Array.from(Array(dotsLength).keys()).map((_, index) => (
            <PaginationItem key={index} index={index} activeDotIndex={activeDotIndex} />
            ))}
        </HStack>
    )
}