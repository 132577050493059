
import React from 'react'
import { Nominativi, ServizioResult } from '../../../api/servizi';
import { Divider, FlatList } from 'native-base';
import { Dimensions } from 'react-native';
import { RectButton } from 'react-native-gesture-handler';
import { useServizio } from '../../../core/store/servizio-store';
import { useContatto } from '../../../core/store/rubrica/contatto-store';
import { ContattoRow } from '../../rubrica';
import { Platform} from 'react-native';

type Props = {
    segreteriaSelezionata: ServizioResult,
    navigation: any
}

export const RubricaModal = ({ segreteriaSelezionata, navigation }: Props) => {

    const setContatto = useContatto(state => state.setContatto);
    
    const handlePress = (item: any) => {
        setContatto(item ,false);
        navigation.push("ContattoSelezionato");
    }


    return (
        <FlatList
            maxH={Dimensions.get('window').height - 100}
            contentContainerStyle={Dimensions.get('window').width > 768 && Platform.OS === "web"  ? { display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(25rem, 1fr)", gap: "1rem", margin: "2rem" } : { paddingBottom: 100 }}
            data={segreteriaSelezionata.nominativo}
            ItemSeparatorComponent={() => <Divider />}
            renderItem={({ item, index }) => (
                <ContattoRow handlePress={handlePress} item={item}  />
            )}
            h="100%"
            keyExtractor={(_item, index) => `message ${index}`}
        />
    );
}
