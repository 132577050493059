import type { AxiosError } from "axios";
import { createQuery } from "react-query-kit";
import type { StatisticaResult } from "./types";
import auth from "../common/client";

type Response = StatisticaResult | undefined;

type Variables = {
    codice_servizio: string;
};

export const getPanoramica = createQuery<Response, Variables, AxiosError>({
    primaryKey: "panoramica",
    enabled: (data,variables) => Boolean(variables.codice_servizio),
    queryFn: async ({ queryKey: [primaryKey, variables] }) => {
        return auth
            ?.get(
                `/api/Panoramica/?codice_servizio=${variables.codice_servizio}`
            )
            .then((response) => response.data)
            .catch((err) => console.log(err));
    },
});

