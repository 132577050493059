// Query per la rimozione di un nominativo

import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";


type Variables = {
    data:any;
  };

export const createNominativo = createMutation<void ,Variables, AxiosError>({
    mutationFn: async ({ variables }) => {
        return auth?.post(`/api/createNominativo`,variables.data).then((response) => response.data).catch((err) => console.log(err));
    }
});