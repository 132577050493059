import { HStack, Stack, Text, useColorMode } from 'native-base'
import React from 'react'
import Statistica from './Statistica'
import BoxContainer from '../../../ui/components/layout/box-container'
import { getStatisticheLight } from '../../../api/statistiche-chiamate'
import { ServizioResult } from '../../../api/servizi'
import { getCurrentMonth } from '../../../utils/utils'
import { colors } from '../../../ui/theme/generic-theme/colors'
type Props = {
    servizioSelezionato: ServizioResult
}

export default function Statistiche({ servizioSelezionato }: Props) {

    const { data: statistiche, isLoading, isError } = getStatisticheLight({
        variables: {
            codice_servizio: servizioSelezionato?.codice_servizio
        }
    });


    return (
        <BoxContainer>
            <Stack space={{
                base: 4,
                md: 6
            }} w="full">
                <HStack w="full" space={{
                    base: 2,
                    md:4
                }} alignItems={"center"} justifyContent={"space-between"}>
                    <Statistica
                        isLoading={isLoading}
                        isError={isError}
                        green={colors.success['600']}
                        red={colors.error['600']}
                        unitMeaseure='Chiamate'
                        date='Chiamate Oggi'
                        calls={statistiche?.oggi?.chiamate}
                        percentage={(statistiche?.oggi?.differenza_percentuale)?.toString()?.replace(".", ",")}
                        textPercentage="ieri"
                    />
                    <Statistica
                        isLoading={isLoading}
                        isError={isError}
                        green={colors.success['600']}
                        red={colors.error['600']}
                        unitMeaseure='Chiamate'
                        date='Chiamate Settimana'
                        calls={statistiche?.settimana?.chiamate}
                        percentage={(statistiche?.settimana?.differenza_percentuale)?.toString()?.replace(".", ",")}
                        textPercentage="scorsa settimana"
                    />
                </HStack>
                <HStack w="full" space={2} alignItems={"center"} justifyContent={"space-between"}>
                    <Statistica
                        isLoading={isLoading}
                        isError={isError}
                        green={colors.success['600']}
                        red={colors.error['600']}
                        unitMeaseure='Chiamate'
                        date={"Chiamate " + getCurrentMonth()}
                        calls={statistiche?.mese?.chiamate}
                        percentage={(statistiche?.mese?.differenza_percentuale)?.toString()?.replace(".", ",")}
                        textPercentage="scorso mese"
                    />
                    <Statistica
                        isLoading={isLoading}
                        isError={isError}
                        green={colors.success['600']}
                        red={colors.error['600']}
                        unitMeaseure='Chiamate'
                        date='Chiamate Ultimi 3 Mesi'
                        calls={statistiche?.ultimi_3_mesi?.chiamate}
                        percentage={(statistiche?.ultimi_3_mesi?.differenza_percentuale)?.toString()?.replace(".", ",")}
                        textPercentage="scorsi 3 mesi"
                    />
                </HStack>
            </Stack>
        </BoxContainer>
    )
}