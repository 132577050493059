import React, { useEffect, useState } from 'react'
import BoxContainer from '../../../ui/components/layout/box-container'
import { HStack, Skeleton, Text, VStack, useColorMode } from 'native-base';
import LinearChart from './chart';
import RangeDate from './range';
import { ServizioResult } from '../../../api/servizi';
import { getItalianDay, getMonth } from '../../../utils/utils';
import { usePanoramica } from '../../../core/store/panoramica-store';
import { getPanoramica } from '../../../api/statistiche-chiamate/get-panoramica';
import { useWindowDimensions, Platform } from 'react-native';

type Props = {
  servizioSelezionato: ServizioResult,
  hideRangeDate: boolean,
  enableScroll?: () => void,
  disableScroll?: () => void,
}


export default function Panoramica({ servizioSelezionato, hideRangeDate, enableScroll, disableScroll }: Props) {


  const { data: panoramica, isLoading: isLoadingPanoramica, isError: isErrorPanoramica } = getPanoramica({
    variables: {
      codice_servizio: servizioSelezionato?.codice_servizio,
    }
  })

  const { colorMode } = useColorMode();
  const dataSet = usePanoramica(state => state.dataSet);
  const setDataSet = usePanoramica(state => state.setDataSet);
  const tipo_statistica = usePanoramica(state => state.tipo_statistica);
  const [totalValue, setTotalValue] = useState(0);
  const WIDTH = useWindowDimensions().width;


      // Define a function to calculate the dataset for a given time period
      const calculateDataset = (timePeriod: string, data: any[]) => {
        let chiamate_counter = 0;
        const dataset = data?.map((item: any) => {
          chiamate_counter += item.numero_chiamate;
          return {
            ...item,
            periodo: timePeriod === "1S" ? (WIDTH > 768 && Platform.OS === "web" ? getItalianDay(item.intervallo) : getItalianDay(item.intervallo).slice(0, 3)) : getMonth(item.intervallo)
          }
        })
        return chiamate_counter === 0 ? [] : dataset?.reverse();
      }

  useEffect(() => {

    // Calculate the dataset based on the selected time period
    let dataset = null;
    switch (tipo_statistica) {
      case "6M":
        dataset = calculateDataset("6M", panoramica?.ultimi_sei_mesi ?? []);
        break;
      case "3M":
        dataset = calculateDataset("3M", panoramica?.ultimi_sei_mesi?.slice(0, 3) ?? []);
        break;
      case "1S":
        dataset = calculateDataset("1S", panoramica?.ultimi_sette_giorni ?? []);
        break;
      default:
        dataset = [];
        break;
    }

    // Set the dataset in the store
    setDataSet(dataset);

    // Calculate the total and highest value
    const { total } = dataset?.reduce(
      ({ total }: { total: number, highest: number }, { numero_chiamate }: { numero_chiamate: number }) => ({
        total: total + numero_chiamate,
      }),
      { total: 0 }
    );

    setTotalValue(total);


  }, [tipo_statistica, panoramica])


  if (isLoadingPanoramica || isErrorPanoramica) return <BoxContainer>
    <Skeleton w="80%" h="72" marginTop={WIDTH > 768 ? "20" : "0"} rounded={"2xl"} />
    <HStack position={ "relative"} top="0" right="0" justifyContent={"center"} space={4} alignItems={"center"}>
      <Skeleton w="16" h="16" rounded={"2xl"} />
      <Skeleton w="16" h="16" rounded={"2xl"} />
      <Skeleton w="16" h="16" rounded={"2xl"} />
    </HStack>
  </BoxContainer>


  return (
    <BoxContainer>
      {dataSet && dataSet.length > 0 ? <LinearChart enableScroll={enableScroll} disableScroll={disableScroll} totale_chiamate={totalValue} width_screen={WIDTH} dataSet={dataSet} colorMode={colorMode} /> : <Text color={"dark"} _dark={{ color: "white" }} fontSize={"lg"}>Nessun dato disponibile</Text>}
      {!hideRangeDate && <RangeDate width_screen={WIDTH} />}
    </BoxContainer>
  )
}