import Constants from "expo-constants";
import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";
import axios from "axios";

export type RecuperoPasswordVariables = {
    variables: {
        uid: string,
        token: string,
        nuova_password: string
    }
}

export const authConfermaRecuperoPassword = createMutation<void, RecuperoPasswordVariables, AxiosError>({
    mutationFn: async ({ variables }) => {

        return await axios.post(Constants.expoConfig?.extra?.apiUrl + "/api/v1/users/reset_password_confirm/", { uid: variables.uid, token: variables.token, new_password: variables.nuova_password }).then((response) => response.data);

    }
});

export default authConfermaRecuperoPassword;



