import React, { useState } from 'react'
import { VictoryPie, VictoryAnimation, VictoryLabel, VictoryVoronoiContainer, VictoryTooltip } from 'victory-native'
import { Circle, Svg, G } from 'react-native-svg'
import { ScopiResult } from '../../../../api/statistiche-scopi'
import { colors } from '../../../../ui/theme/generic-theme/colors'

type Props = {
    colorMode: "light" | "dark" | null | undefined,
    data: ScopiResult[] | undefined,
    total: number,
    colorsCharts: string[],
    colorsLabel: string[]
}

export default function PieChart({ colorMode, data, total, colorsCharts, colorsLabel }: Props) {

    const [activeSelection, setActiveSelection] = useState({
        data: 100,
        scopo: "Totale",
        colore: colors.dark
    });


    const getData = React.useMemo(() => (data: ScopiResult[] | undefined) => {
        if (data) {
            return data.map((item, index) => {
                return {
                    x: index,
                    y: parseFloat((item.numero / total * 100).toFixed(1)),
                    label: item.scopo,
                    color: colorsCharts[index],
                    colorsLabel: colorsLabel[index]
                }
            })
        }
    }, [data]);

    const FlyOut = React.memo((props: any) => {
        const { x, y, datum } = props;
        return (
            <G>
                <Circle cx={200} cy={200} r={110}fill={"#fff"} />
            </G>
        )
    })

    const GlobalData = React.memo((props: any) => {
        return (
            <G>
                <FlyOut />
                <G>
                    <VictoryLabel textAnchor="middle" verticalAnchor="middle"
                        x={200} y={180}
                        text={`${activeSelection.data ? activeSelection.data : total}%`}
                        style={{ fontSize: 60, fill: colors.dark }} />
                    <VictoryLabel textAnchor="middle" verticalAnchor="middle"
                        x={200} y={230}
                        text={`${activeSelection.scopo}`}
                        style={{ fontSize: 20, fontWeight: 300, fill: colors.dark }} />
                </G>
            </G>
        )
    })

    return (
        <Svg viewBox="0 0 400 400" height={400} width={"100%"}>
            <GlobalData />
            <VictoryPie

                events={[{
                    target: "data",
                    eventHandlers: {
                        onPressIn: () => {
                            return [
                                {
                                    target: "data",
                                    mutation: (props) => {
                                        setActiveSelection({
                                            data: props.datum.y,
                                            scopo: props.datum.label,
                                            colore: props.datum.colorsLabel
                                        })
                                        return { style: { fill: props.datum.colorsLabel } };
                                    },
                                }
                            ];
                        },
                        onPressOut: () => {
                            return [
                                {
                                    target: "data",
                                    mutation: (props) => {
                                        setActiveSelection({
                                            data: 100,
                                            scopo: "Totale",
                                            colore: colors.dark
                                        })
                                        return { style: { fill: props.datum.color } };
                                    }
                                }
                            ];
                        },
                        onMouseOver: () => {
                            return [
                                {
                                    target: "data",
                                    mutation: (props) => {
                                        setActiveSelection({
                                            data: props.datum.y,
                                            scopo: props.datum.label,
                                            colore: props.datum.colorsLabel
                                        })
                                        return { style: { fill: props.datum.colorsLabel } };
                                    }
                                }
                            ];
                        },
                        onMouseOut: () => {
                            return [
                                {
                                    target: "data",
                                    mutation: (props) => {
                                        setActiveSelection({
                                            data: 100,
                                            scopo: "Totale",
                                            colore: colors.dark
                                        })
                                        return { style: { fill: props.datum.color } };
                                    }
                                }
                            ];
                        }
                    }
                }]}
                standalone={false}
                data={
                    getData(data)
                }
                // containerComponent={<VictoryVoronoiContainer />}
                innerRadius={120}
                labelRadius={150}
                labels={({ datum }) => null}
                cornerRadius={10}
                padAngle={1}
                style={{
                    data: {
                        fill: ({ datum }) => {
                            return datum.color;
                        }
                    },
                    labels: {
                        display: "none"
                    }
                }}
                height={400}
                width={400}
            />
        </Svg>
    )
}