import React from 'react'
import { HStack, Text, VStack } from 'native-base'
import { Menu, View, Divider, Pressable, Icon } from 'native-base'
import { useRegole } from '../../../../../../core/store/rubrica/regole/regole-store';
import {Ionicons} from '@expo/vector-icons'
import CronPersonalizzata from './personalizzata/cron-personalizzata';
import { getCronFixedList } from '../../../../../../utils/rubrica/get-cron-fixed-list';

export default function Cron() {

    const { ripetizione, setRipetizione } = useRegole(state => state);

    const data = React.useMemo(() => getCronFixedList(), []);




    return (
        <VStack >
            <HStack mb="4" w="full"  justifyContent={"space-between"}>
                <Text fontSize="md" maxW="24" minW={"24"} fontWeight="600">Ripetizione</Text>
                <Menu
                    rounded={"xl"}
                    w="90%"
                    placement='bottom left'
                    trigger={(triggerProps) => {
                        return (
                            <Pressable flex={1} w="full" {...triggerProps}>
                                <HStack w="full" ml="auto">
                                    <Text
                                        justifyContent={"center"}
                                        mx="2" w="70%"
                                        fontSize="md" fontWeight="400">{ripetizione?.message}</Text>
                                         <Icon ml="auto" as={<Ionicons name={"chevron-down"} />} color="dark"  size="6" _dark={{
                                    color: "white"
                                }} />
                                </HStack>
                            </Pressable>
                        )
                    }
                    }>
                    {data?.map((item: any, index: number) => (
                        <View key={index}>
                            <Menu.Item key={index} onPress={() => setRipetizione(item)}>
                                {item.message}
                            </Menu.Item>
                            <Divider />
                        </View>
                    ))}
                </Menu>

            </HStack>

            {ripetizione?.id === 7 && <CronPersonalizzata />}
        </VStack>
    )
}