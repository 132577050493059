import { HStack, Text, VStack, Divider } from "native-base"
import React from "react"
import Email from "./email"
import { EmailCC } from "./email-cc"
import { Telefono } from "./telefono"
import Priorita from "./priorita"
import Alias from "./alias"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import Nome from "./nome"
import Cognome from "./cognome"
import { useContatto } from '../../../../../core/store/rubrica/contatto-store'

export const DatiContattoCentralino = () => {

    const editSezioneContatto = useContatto(state => state.editSezioneContatto)

    return (
        <KeyboardAwareScrollView enableOnAndroid={true} enableAutomaticScroll={true} enableResetScrollToCoords={true} keyboardOpeningTime={0}  style={{
            width: "100%",
            height: "100%",
        }}>
            <VStack space={3} h="full" >
                    <Nome enableEdit={editSezioneContatto} />
                    <Divider />
                    <Cognome enableEdit={editSezioneContatto} />
                    <Divider />
                    <Email enableEdit={editSezioneContatto} />
                    <Divider />
                    <EmailCC enableEdit={editSezioneContatto} />
                    <Divider />
                    <Telefono enableEdit={editSezioneContatto} />
                    <Divider />
                    <Priorita enableEdit={editSezioneContatto} />
                    <Divider />
                    <Alias enableEdit={editSezioneContatto} />
            </VStack>
        </KeyboardAwareScrollView>
    )
}

export const DatiContatto = () => {
    const contatto = useContatto(state => state.contatto)
    return (
        <VStack mx="2" space={4} flex={1} mt="4">
            <Text fontSize={"2xl"} fontWeight={"bold"} color="dark" _dark={{ color: "white" }} my="1">
                Dati Contatto
            </Text>
            <HStack space={2} w="full" >
                <Text fontSize={"xl"} fontWeight="600"
                    minW="24"
                    color="dark" _dark={{ color: "white" }}>
                    Email
                </Text>
                <Text maxW={"70%"} lineHeight={"xs"} fontSize={"lg"} fontWeight="400" color="dark" _dark={{ color: "white" }}>
                    {contatto?.email}
                </Text>
            </HStack>
            <Divider />
            <HStack space={2} w="full" >
                <Text fontSize={"xl"} fontWeight="600"
                    minW="24"
                    color="dark" _dark={{ color: "white" }}>
                    Telefono
                </Text>
                <Text maxW={"70%"} fontSize={"lg"} fontWeight="400" lineHeight={"xs"} color="dark" _dark={{ color: "white" }}>
                    {contatto?.telefono}
                </Text>
            </HStack>
            <Divider />
            <HStack space={2} w="full" >
                <Text fontSize={"xl"} fontWeight="600"
                    minW="24"
                    color="dark" _dark={{ color: "white" }}>
                    Ruolo
                </Text>
                <Text maxW={"70%"} lineHeight={"xs"} fontSize={"lg"} fontWeight="400" color="dark" _dark={{ color: "white" }}>
                    {contatto?.ruolo}
                </Text>
            </HStack>
        </VStack>
    )
}