import React from 'react'
import { HStack, Switch, Text, VStack, useColorMode } from 'native-base'
import { useRegole } from '../../../../../../core/store/rubrica/regole/regole-store';
import { DatePicker } from '../../../../../../ui/components/date-picker/date-picker';
import { formatDateLeadingZero } from '../../../../../../utils/utils';

type Props = {}

export default function PeriodoFisso({ }: Props) {

    const { inizio, setInizio, fine, setFine, allDay, setAllDay, isErrorDate, setErrorDate } = useRegole(state => state);
    const { colorMode } = useColorMode();

    const handleChangeInizio = (event: any, selectedDate: any) => {

        const currentDate = !selectedDate ? event.target.value : selectedDate;

        if (currentDate === "") {
            return;
        }


        // Check if date is less than data fine
        if (currentDate > fine) {
            setErrorDate(true);
        } else {
            setErrorDate(false);
        }

        setInizio(currentDate)
    };

    const handleChangeFine = (event: any, selectedDate: any) => {
        const currentDate = !selectedDate ? event.target.value : selectedDate;

        if (currentDate === "") {
            return;
        }

        // Check if date is less than data inizio
        if (currentDate < inizio) {
            setErrorDate(true);
        } else {
            setErrorDate(false);
        }

        setFine(currentDate)
    };



    return (
        <VStack space={2}>
            <HStack w="full" minW="48" mx="auto" justifyContent={"space-between"} alignItems={"center"} >
                <Text
                    fontSize={"md"}
                    fontWeight="600"

                >
                    Tutto il giorno
                </Text>
                <Switch size="md" value={allDay} onToggle={(value) => setAllDay(value)} />
            </HStack>
            <HStack w="full" minW="48" mx="auto" justifyContent={"space-between"} alignItems={"center"} >
                <Text
                    fontSize={"md"}
                    fontWeight="600"

                >
                    Inizio
                </Text>

                <HStack space={2}>
                    {
                        !allDay && <DatePicker
                            colorMode={colorMode}
                            mode="time"
                            value={inizio}
                            onChange={handleChangeInizio}
                        />
                    }
                    <DatePicker
                        colorMode={colorMode}
                        mode="date"
                        value={inizio}
                        onChange={handleChangeInizio}
                    />
                </HStack>
            </HStack>
            <HStack w="full" mx="auto" justifyContent={"space-between"} alignItems={"center"} >
                <Text
                    fontSize={"md"}
                    fontWeight="600"

                >
                    Fine
                </Text>
                <HStack space={2}>
                    {
                        !allDay && <DatePicker
                            colorMode={colorMode}
                            mode="time"
                            value={fine}
                            onChange={handleChangeFine}
                        />
                    }
                    <DatePicker
                        colorMode={colorMode}
                        mode="date"
                        value={fine}
                        onChange={handleChangeFine}
                    />
                </HStack>
            </HStack>
            {isErrorDate && <Text
                fontSize={"md"}
                fontWeight={"400"}
                color={"danger.600"}
            >
                La data di inizio deve essere minore della data di fine
            </Text>}

        </VStack>
    )
}