import { create } from "zustand";
import { createSelectors } from "../../../utils";
import { useContatto } from "../contatto-store";
import { checkIsJson } from "../../../../utils/utils";
import { listaPriorita } from "../../../../utils/get-priorita";


export interface DatiContattoStore {
    email: string[],
    emailCC: string[],
    telefono: string,
    priorita: string,
    alias: string[],
    nome: string,
    cognome: string,
    descrizione: string,
    errors: {},
    setErrors: (errors: {}) => void,
    setEmail: (email: []) => void,
    setEmailCC: (emailCC: string[]) => void,
    setTelefono: (telefono: string) => void,
    setPriorita: (priorita: string) => void,
    setAlias: (alias: string[]) => void,
    setCognome: (cognome: string) => void,
    setNome: (nome: string) => void,
    setDatiContatto: (datiContatto: DatiContattoStore) => void,
    restoreDatiContattoData: () => void,
    getAllDatiContatto: () => any
}

const useDatiContattoStore = create<DatiContattoStore>((set) => ({
    email: [],
    emailCC: [],
    telefono: "",
    priorita: "Media",
    alias: [],
    nome: "",
    cognome: "",
    descrizione: "",
    errors: {},
    setErrors: (errors: {}) => set({ errors }),
    setCognome: (cognome: string) => set({ cognome }),
    setNome: (nome: string) => set({ nome }),
    setEmail: (email: string[]) => set({ email }),
    setEmailCC: (emailCC: string[]) => set({ emailCC }),
    setTelefono: (telefono: string) => set({ telefono }),
    setPriorita: (priorita: string) => set({ priorita }),
    setAlias: (alias: string[]) => set({ alias }),
    setDatiContatto: (datiContatto: DatiContattoStore) => set({ email: datiContatto.email, emailCC: datiContatto.emailCC, telefono: datiContatto.telefono, priorita: datiContatto.priorita, alias: datiContatto.alias, nome: datiContatto.nome, cognome: datiContatto.cognome, descrizione: datiContatto.descrizione }),
    restoreDatiContattoData: () => {
        set({
            email: [],
            emailCC: [],
            telefono: "",
            priorita: "Media",
            alias: [],
            nome: "",
            cognome: "",
            descrizione: "",
            errors: {},
        });
    },
    getAllDatiContatto: () => {
        const state: DatiContattoStore = useDatiContattoStore.getState();
        const { email, emailCC, telefono, priorita, alias, nome, cognome } = state;

        const email_ica = JSON.stringify({ destinatari: email, cc: emailCC });
        const telefono_ica = telefono;
        const numero_sms = telefono;
        const prioritaValue = listaPriorita.find(val => val.descrizione === priorita)?.valore;
        const aliasJson = JSON.stringify({ alias: alias });

        return {
            email_ica,
            telefono_ica,
            numero_sms,
            priorita: prioritaValue,
            alias: aliasJson,
            nome,
            cognome,
        };
    }
}));

export const useDatiContatto = createSelectors(useDatiContattoStore);