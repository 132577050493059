import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";


type Response = any[];


export const getMotiviAssenza = createQuery<Response, AxiosError>({
    primaryKey: "motiviAssenza",
    queryFn: async ({ queryKey }) => {
        return auth?.get(`/api/getMotiviAssenza`).then((response) => response.data).catch((err) => console.log(err));
    },
    
});
