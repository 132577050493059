import React, { useEffect } from 'react'
import { VictoryLabel, VictoryPie, VictoryAnimation } from 'victory-native'
import { Svg } from 'react-native-svg'
import { colors } from '../../../../ui/theme/generic-theme/colors'
import { ColorMode } from 'native-base'

type Props = {
    data: any;
    colorMode: ColorMode,
    chiamate_totali: number
}

export default function PieChart({ data,  colorMode,chiamate_totali }: Props) {
    const [pieSelected, setPieSelected] = React.useState<any>({
        title: "Totali",
        data: chiamate_totali
    });

    useEffect(() => {
        setPieSelected({
            title: "Totali",
            data: chiamate_totali
        })
    }, [chiamate_totali])

    return (
        <Svg viewBox="0 0 400 340" height={280} width={"100%"}>
            <VictoryPie
                standalone={false}
                data={data}
                events={[{
                    target: "data",
                    eventHandlers: {
                        onPressIn: () => {
                            return [
                                {
                                    target: "data",
                                    mutation: (props) => {
                                        setPieSelected(props.datum)
                                        return { style: { fill: props.datum.colorPress, stroke: props.datum.colorPress, strokeWidth: 8
                                         } };
                                    }
                                }
                            ];
                        },
                        onPressOut: () => {
                            return [
                                {
                                    target: "data",
                                    mutation: () => {
                                        setPieSelected({ title: "Totali", data: pieSelected.chiamate_totali })
                                        return null;
                                    }
                                }
                            ];
                        },
                        onMouseOver: () => {
                            return [
                                {
                                    target: "data",
                                    mutation: (props) => {
                                        setPieSelected(props.datum)
                                        return { style: { fill: props.datum.colorPress, stroke: props.datum.colorPress, strokeWidth: 2 } };
                                    }
                                }
                            ];
                        },
                        onMouseOut: () => {
                            return [
                                {
                                    target: "data",
                                    mutation: () => {
                                        setPieSelected({ title: "Totali", data: pieSelected.chiamate_totali })
                                        return null;
                                    }
                                }
                            ];
                        },
                    }
                }]}
                innerRadius={120}
                cornerRadius={25}
                style={{ labels: { fill: "white", fontSize: 30, fontWeight: "700" }, data: { fill: ({ datum }) => datum.color } }}
                padAngle={4}
                labels={() => null}
                startAngle={140}
                endAngle={-140}
                height={400}
                width={400}
            />
            <VictoryAnimation duration={500} >
                {() => {
                    return (
                        <VictoryLabel
                            textAnchor="middle" verticalAnchor="middle"
                            x={200} y={185}
                            text={`${pieSelected?.data ? pieSelected.data : 0}`}
                            style={{ fontSize: 90, fill: colorMode === "light" ? "#000" : "white", fontWeight: "400" }}
                        />
                    );
                }}
            </VictoryAnimation>
            <VictoryLabel 
            textAnchor={"middle"} verticalAnchor={"middle"} x={200}
            y={245} text={pieSelected.title} style={{ fontSize: 22, fill: colorMode === "light" ? "#000" : "white" }} />
        </Svg>
    )
}