import React from 'react'
import { HStack, Text, Heading, Center, View, Pressable, Divider } from 'native-base'
import { Ionicons } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';
import AnimatedCheckmark from '../../../ui/components/animated-checkmark/animated-checkmark';
import { colors } from '../../../ui/theme/generic-theme/colors';
import { useIsFirstTime } from '../../../core/use-is-first-time';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Linking } from 'react-native';

type Props = {
    navigation: any,
    route: any
}

export default function RegistrazioneSuccess({ navigation, route }: Props) {

    const [_, setIsFirstTime] = useIsFirstTime();
    const handleRegistrazioneSuccess = () => {
        setIsFirstTime(false)
        navigation.navigate("Login")
    }

    const handleNavigateToSito = () => {
        setIsFirstTime(false)
        Linking.openURL("https://www.segreteriaremota.it/il-servizio-segretariato-telefonico-segretaria-online/")
    }

    return (
        <SafeAreaView style={{ width: "100%", height: "100%", backgroundColor: route?.params?.colorMode === "dark" ? colors.dark : "white" }}>
            <View flex={1} bg="white" _dark={{
                bg: "dark"
            }} maxH={"98%"}>
                <View minW={"90%"} flex={1} p="2" mt="2" maxW={"800"} mx="auto" paddingLeft={"5%"} maxH={{
                    base: "95%",
                    md: "90%"
                }} paddingRight={"5%"} >
                    <Center >
                        <AnimatedCheckmark />
                        <Heading
                            _dark={{
                                color: "white"
                            }}
                            lineHeight={"xs"}
                            color="dark"
                            fontWeight={"800"}
                            fontSize={"4xl"}
                            mb="2"
                            fontFamily={"body"}
                            textAlign={"center"}
                        >
                            Congratulazioni {route.params?.nome}
                        </Heading>
                        <HStack alignItems={"center"} w="90%">
                            <Ionicons name="checkmark-circle" size={24} color={colors.success[400]} />
                            <Heading
                                _dark={{
                                    color: "white"
                                }}
                                textAlign={"left"}
                                color="dark"
                                mt="4"
                                fontWeight={"500"}
                                fontSize={"md"}
                                lineHeight={"xs"}
                                fontFamily={"body"}
                            >
                                Abbiamo ricevuto la tua richiesta di attivazione del servizio.
                            </Heading>
                        </HStack>
                        <HStack alignItems={"center"} w="90%">
                            <Ionicons name="checkmark-circle" size={24} color={colors.success[400]} />
                            <Heading
                                _dark={{
                                    color: "white"
                                }}
                                textAlign={"left"}
                                color="dark"
                                mt="4"
                                fontWeight={"500"}
                                fontSize={"md"}
                                lineHeight={"xs"}
                                fontFamily={"body"}
                            >
                                Ti abbiamo inviato una mail di conferma all'indirizzo <Text fontWeight={"bold"}>{route.params?.email}</Text>
                            </Heading>
                        </HStack>
                        <HStack alignItems={"center"} w="90%">
                            <Ionicons name="checkmark-circle" size={24} color={colors.success[400]} />
                            <Heading
                                _dark={{
                                    color: "white"
                                }}
                                textAlign={"left"}
                                color="dark"
                                mt="4"
                                fontWeight={"500"}
                                fontSize={"md"}
                                lineHeight={"xs"}
                                fontFamily={"body"}
                            >
                                A breve verrai contattato da un nostro consulente per completare l'attivazione.
                            </Heading>
                        </HStack>
                    </Center>
                </View>
                <View>
                    <Text color="dark" _dark={
                        {
                            color: "white"
                        }
                    } fontWeight={"500"} fontSize={"md"} lineHeight={"xs"} fontFamily={"body"} textAlign={"center"}>Vuoi saperne di più? </Text>
                    <TouchableOpacity style={{ marginBottom: 24 }} onPress={() => handleNavigateToSito()}>
                        <Text color="primary.400" _dark={{
                            color: "white"
                        }} fontWeight={"500"} fontSize={"md"} lineHeight={"xs"} fontFamily={"body"} textAlign={"center"}>Controlla il nostro sito!</Text>
                    </TouchableOpacity>
                    <Pressable onPress={() => handleRegistrazioneSuccess()} bg="primary.400" h="55" rounded={"md"} mx="4">
                        <Center flex={1}>
                            <Text color="white" fontWeight={"bold"} textAlign={"center"} fontSize={"lg"} >Torna al Login</Text>
                        </Center>
                    </Pressable>
                </View>
            </View>
        </SafeAreaView>
    )
}