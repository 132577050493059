import React from 'react'
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { TouchableOpacity } from 'react-native';
import { Text, HStack } from 'native-base';
import { SafeAreaView } from 'react-native-safe-area-context';
import DettagliContatto from '../../../screens/rubrica/contatto/index-modal';
import { useContatto } from '../../../core/store/rubrica/contatto-store';
import { useServizio } from '../../../core/store/servizio-store';
import { useDatiContatto } from '../../../core/store/rubrica/dati-contatto/dati-contatto-store';
import { Platform } from 'react-native';
import RegolaNavigator from './regola/regola-navigator';
import { createNominativo } from '../../../api/nominativi/create/create-nominativo';
import { queryClient } from '../../../api/common/api-provider';
import { getNominativi } from '../../../api/nominativi/get/get-nominativi';
import { updateNominativo } from '../../../api/nominativi/update/update-nominativo';


type Props = {}


const Stack = createStackNavigator();

export default function DettagliContattoNavigator({ }: Props) {
    const editSezioneContatto = useContatto(state => state.editSezioneContatto);
    const contatto = useContatto(state => state.contatto);
    const isSiriaCentralino = useServizio(state => state.isSiriaCentralino);
    const setEditSezioneContatto = useContatto(state => state.setEditSezioneContatto);
    const getAllContattoData = useContatto(state => state.getAllContattoData);
    const setContatto = useContatto(state => state.setContatto);
    const setErrors = useDatiContatto(state => state.setErrors);


    const { mutate: createContatto } = createNominativo({
        onSuccess: (data) => {
            queryClient.invalidateQueries(getNominativi.getKey());
        }
    });

    const { mutate: updateContatto } = updateNominativo({
        onSuccess: (data) => {
            queryClient.invalidateQueries(getNominativi.getKey());
        }
    })

    const handleSaveContatto = (isEdit: boolean) => {
        const payload = getAllContattoData();

        if (payload?.nome === "") {
            return setErrors({
                "nome": "Il campo nome è obbligatorio"
            })
        }

        if (payload?.cognome === "") {
            return setErrors({
                "cognome": "Il campo cognome è obbligatorio"
            })
        }

        if (payload?.telefono_ica === "") {
            return setErrors({
                "telefono": "Il campo telefono è obbligatorio"
            })
        }

        setEditSezioneContatto(false);

        if (isEdit) {
            updateContatto({
                variables: {
                    data: payload,
                    id: contatto?.id
                }
            })
        } else {
            createContatto({
                variables: {
                    data: payload
                }
            });
        }
    }

    const handleResetContatto = () => {
        setEditSezioneContatto(false);
        setErrors({});
        // restoreContattoData();
        setContatto(contatto, false);
    }

    return (
        <Stack.Navigator
            initialRouteName="DettagliContatto"
            screenOptions={{
                headerShown: true,
                gestureEnabled: true,
                cardOverlayEnabled: true,
                headerStyle: {
                    backgroundColor: "#fff",
                    margin: 10,
                },
                headerTransparent: true,
                header: ({ navigation, route }) =>
                    <HeaderDettaglioContatto
                        navigation={navigation}
                        route={route}
                        setEditSezioneContatto={setEditSezioneContatto}
                        handleSaveContatto={handleSaveContatto}
                        isSiriaCentralino={isSiriaCentralino}
                        editSezioneContatto={editSezioneContatto}
                        setErrors={setErrors}
                        handleResetContatto={handleResetContatto}
                    />
                ,
                ...TransitionPresets.ModalPresentationIOS,
            }}
        >
            <Stack.Screen name="DettagliContatto" component={DettagliContatto} />
        </Stack.Navigator>
    )
}


const HeaderDettaglioContatto = ({ navigation, route, setEditSezioneContatto, handleSaveContatto, isSiriaCentralino, editSezioneContatto, setErrors, handleResetContatto }: any) => {
    return (
        <SafeAreaView
            style={{
                flex: 1,
                height: "100%",
                position: "relative",
                marginHorizontal: 5,
                paddingVertical: Platform.OS === "android"  ? 5 : 0
            }}>
            <HStack justifyContent={"space-between"}>
                <TouchableOpacity onPress={() => {
                    navigation.goBack();
                    setErrors({});
                }}>
                    <Text textAlign={"right"} p="2" fontFamily={"body"} fontWeight={"400"} fontSize={"md"} color="danger.600" >
                        Indietro
                    </Text>
                </TouchableOpacity>
                {isSiriaCentralino && <Text textAlign={"right"} p="2" fontWeight={"600"} fontSize={"md"} color="dark" _dark={{
                    color: "white"
                }} >
                    {route?.params?.isEdit ? "Modifica Contatto" : "Crea Contatto"}
                </Text>}
                {
                    !editSezioneContatto && isSiriaCentralino ? <HStack>
                        <TouchableOpacity onPress={() => setEditSezioneContatto(true)}>
                            <Text textAlign={"right"} p="2" fontFamily={"body"} fontWeight={"400"} fontSize={"md"} color="gray.600" >
                                Modifica
                            </Text>
                        </TouchableOpacity>
                    </HStack>
                        : editSezioneContatto && isSiriaCentralino &&
                        <HStack>
                            {route?.params?.isEdit && <TouchableOpacity onPress={handleResetContatto}>
                                <Text textAlign={"right"} p="2" fontFamily={"body"} fontWeight={"400"} fontSize={"md"} color="danger.600" >
                                    Annulla
                                </Text>
                            </TouchableOpacity>}
                            <TouchableOpacity onPress={() => handleSaveContatto(route?.params?.isEdit)} >
                                <Text textAlign={"right"} p="2" fontFamily={"body"} fontWeight={"400"} fontSize={"md"} color="success.600" >
                                    Salva
                                </Text>
                            </TouchableOpacity>
                        </HStack>
                }
            </HStack>
        </SafeAreaView>
    )
}