import React from 'react';
import { ActivityIndicator } from 'react-native';
import { Stack, Text,View } from 'native-base';
import NoDataFound from '../../svg/no-data-found';
type Props = {
  isLoading: boolean;
};
export const EmptyList = React.memo(({ isLoading }: Props) => {
  return (
    <View minH="400"  flex={1} w="full" justifyContent={"center"}>
      {!isLoading ? (
        <Stack justifyContent={"center"} alignItems={"center"}>
          <NoDataFound width="400" height="400" />
          <Text color="dark" _dark={{color:"white"}} fontSize={"2xl"} fontWeight={"400"} textAlign={"center"} pt="4"
          >
            Nessun risultato
          </Text>
        </Stack>
      ) : (
        <ActivityIndicator />
      )}
    </View>
  );
});
