import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createSelectors } from "../utils";


type header = {
    title: string,
    icon: string,
    color: string
}

export interface ModalStore {
    header: {
        title: string,
        icon: string
        color: string
    }
    content: React.ReactNode
    setModalTitle: (header:header) => void;
    setContent: (content: React.ReactNode) => void;
}

const useModalStore = create<ModalStore>((set) => ({
    header: {
        title: "",
        icon: "",
        color: ""
    },
    content: null,
    setModalTitle: (header:header) => set({ header: header}),
    setContent: (content: React.ReactNode) => set({ content: content })
}));

export const useModal = createSelectors(useModalStore);