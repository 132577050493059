import React from 'react'
import { ServizioResult } from '../../../api/servizi'
import { Divider, FlatList, HStack, ScrollView, Text } from 'native-base'
import { Contatto } from '../../rubrica/contatto'
import BoxContainer from '../../../ui/components/layout/box-container'

type Props = {
    segreteriaSelezionata: ServizioResult,
    isLoading: boolean,
    isError: boolean
}

export default function Rubrica({ segreteriaSelezionata,isLoading, isError }: Props) {
    if (segreteriaSelezionata?.nominativo && segreteriaSelezionata?.nominativo.length > 0) {
        return (
            <BoxContainer >
                <FlatList
                    overflowX={"auto"}
                    maxW={"100%"}
                    w="100%"
                    horizontal
                    data={isLoading || isError ? [{id:1},{id:2},{id:3}] : segreteriaSelezionata.nominativo.slice(0, 10)}
                    renderItem={({ item }) => <Contatto isLoading={isLoading} isError={isError} data={item} />}
                    keyExtractor={(item) => item.id.toString()}
                    ItemSeparatorComponent={() => <Divider h="70%" orientation={'vertical'} alignSelf={"center"} />}
                    decelerationRate={"fast"}
                    snapToInterval={250}
                />
            </BoxContainer>
        )
    }

    return (
        <BoxContainer>
            <HStack w="100%">
                <Text color={"dark"} _dark={{ color: "white" }} fontSize={"lg"} fontFamily={"body"} >Non ci sono contatti</Text>
            </HStack>
        </BoxContainer>
    )
}