import { create } from "zustand";
import { createSelectors } from "../../../utils";
import { MotivoAssenza } from "../../../../api/nominativi";
import { firstLetterUppercase } from "../../../../utils/utils";
import { checkCronFixed } from "../../../../utils/rubrica/get-cron-fixed-list";
import { convertCronToDataset } from "../../../../utils/rubrica/convert-cron-to-dataset";
import { convertDatasetToCron } from "../../../../utils/rubrica/convert-dataset-to-cron";

export type Ripetizione = {
    id: number,
    message: string,
    cron?: string,
    giorni: number[] | undefined,
    mesi: number[] | undefined,
    oraInizio: Date | undefined,
    oraFine: Date | undefined,
    enableOrario?: boolean,
}

export interface RegoleStore {
    inizio: Date,
    fine: Date,
    tipoRegola: string,
    motivoAssenza: MotivoAssenza | string | null,
    allDay: boolean,
    isErrorDate: boolean,
    ripetizione: Ripetizione,
    setOraInizio: (oraInizio: Date) => void,
    setOraFine: (oraFine: Date) => void,
    setGiorni: (giorni: number[]) => void,
    setMesi: (mesi: number[]) => void,
    setRipetizione: (ripetizione: Ripetizione) => void,
    setErrorDate: (error: boolean) => void,
    setAllDay: (allDay: boolean) => void,
    setInizio: (inizio: Date) => void,
    setFine: (fine: Date) => void,
    setTipoRegola: (tipoRegola: string) => void,
    setMotivoAssenza: (motivo: string | MotivoAssenza | null) => void,
    getAllData: (state: RegoleStore) => void,
    resetData: () => void,
    setInitialValues: (regola: any) => void,
}


const useRegoleStore = create<RegoleStore>((set) => ({
    inizio: new Date(),
    fine: new Date(),
    tipoRegola: "Presenza",
    allDay: false,
    motivoAssenza: "",
    isErrorDate: false,
    ripetizione: {
        id: 0,
        message: "Nessuna",
        cron: "",
        giorni: [],
        mesi: [],
        oraInizio: new Date(),
        oraFine: new Date(),
        enableOrario: false,
    },
    setOraInizio: (oraInizio: Date) => set({ ripetizione: { ...useRegoleStore.getState().ripetizione, oraInizio } }),
    setOraFine: (oraFine: Date) => set({ ripetizione: { ...useRegoleStore.getState().ripetizione, oraFine } }),
    setGiorni: (giorni: number[]) => set({ ripetizione: { ...useRegoleStore.getState().ripetizione, giorni } }),
    setMesi: (mesi: number[]) => set({ ripetizione: { ...useRegoleStore.getState().ripetizione, mesi } }),
    setEnableOrario: (enableOrario: boolean) => set({ ripetizione: { ...useRegoleStore.getState().ripetizione, enableOrario } }),
    setRipetizione: (ripetizione: Ripetizione) => set({ ripetizione: ripetizione, isErrorDate: false }),
    setErrorDate: (error: boolean) => set({ isErrorDate: error }),
    setAllDay: (allDay: boolean) => set({ allDay }),
    setInizio: (inizio: Date) => set({ inizio }),
    setFine: (fine: Date) => set({ fine }),
    setTipoRegola: (val: any) => {
        if (val) {
            set({ tipoRegola: "Assenza"});
        } else {
            set({ tipoRegola: "Presenza", motivoAssenza: null });
        }
    },
    setMotivoAssenza: (motivo: MotivoAssenza | null | string) => set({ motivoAssenza: motivo }),
    getAllData: () => {
        const {
            inizio,
            fine,
            tipoRegola,
            motivoAssenza,
            allDay,
            ripetizione
        } = useRegoleStore.getState()

        const getIntervalloAssenza = () => {
            if (ripetizione.id !== 0 && ripetizione.id !== 7) {
                return JSON.stringify({
                    cron: ripetizione.cron
                });
            } else if (ripetizione.id === 7) {
                return JSON.stringify({
                    cron: convertDatasetToCron(ripetizione)
                });
            } else {
                if (allDay) {
                    inizio.setHours(0);
                    inizio.setMinutes(0);
                    fine.setHours(23);
                    fine.setMinutes(59);
                }
                return JSON.stringify({
                    dateTimeRange: {
                        startDateTime: inizio,
                        endDateTime: fine,
                    }
                });
            }
        };

        return {
            intervallo_assenza: getIntervalloAssenza(),
            attivo: 1,
            deleted: 0,
            period_type: tipoRegola.toLowerCase(),
            motivo_assenza_id: motivoAssenza?.id ? motivoAssenza?.id : "",
        };
    },

    setInitialValues: (regola: any) => {
        const isCron = regola?.intervallo_assenza && JSON.parse(regola?.intervallo_assenza)?.cron ? true : false;

        if (isCron) {
            const cron = JSON.parse(regola?.intervallo_assenza)?.cron;
            const fixedListItem = checkCronFixed(cron);

            if (fixedListItem) {
                set({
                    ripetizione: {
                        id: fixedListItem.id,
                        message: fixedListItem.message,
                        cron: cron,
                        giorni: [],
                        mesi: [],
                        oraInizio: new Date(),
                        oraFine: new Date(),
                    },
                    tipoRegola: firstLetterUppercase(regola?.period_type),
                    motivoAssenza: regola?.motivo_assenza,
                });
            } else {
                const datasetRipetizionePers = convertCronToDataset(cron);
                set({
                    ripetizione: {
                        id: 7,
                        message: "Personalizzata",
                        ...datasetRipetizionePers
                    },
                    tipoRegola: firstLetterUppercase(regola?.period_type),
                    motivoAssenza: regola?.motivo_assenza,
                });
                return;
            }
        } else {
            const start = new Date(JSON.parse(regola?.intervallo_assenza)?.dateTimeRange.startDateTime);
            const end = new Date(JSON.parse(regola?.intervallo_assenza)?.dateTimeRange.endDateTime);

            set({
                ripetizione: {
                    id: 0,
                    message: "Nessuna",
                    cron: "",
                    giorni: [],
                    mesi: [],
                    oraInizio: new Date(),
                    oraFine: new Date(),
                },
                inizio: start,
                fine: end,
                tipoRegola: firstLetterUppercase(regola?.period_type),
                motivoAssenza: regola?.motivo_assenza,
                allDay: start.getHours() == 0 && start.getMinutes() == 0 && end.getHours() == 23 && end.getMinutes() == 59
            });
        }
    },
    resetData: () => {
        set({
            inizio: new Date(),
            fine: new Date(),
            tipoRegola: "Presenza",
            motivoAssenza: null,
            ripetizione: {
                id: 0,
                message: "Nessuna",
                cron: "",
                giorni: [],
                mesi: [],
                oraInizio: new Date(),
                oraFine: new Date(),
            },
            allDay: false,
            isErrorDate: false,
        })
    },
}));

export const useRegole = createSelectors(useRegoleStore);