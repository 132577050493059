import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const PhoneIncoming = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    fill="none"
    {...props}
  >
    <Path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M40 5v15h15M55 5 40 20M55 42.3v7.5a5.001 5.001 0 0 1-5.45 5 49.475 49.475 0 0 1-21.575-7.675 48.749 48.749 0 0 1-15-15A49.475 49.475 0 0 1 5.3 10.45 5 5 0 0 1 10.275 5h7.5a5 5 0 0 1 5 4.3c.317 2.4.904 4.757 1.75 7.025A5 5 0 0 1 23.4 21.6l-3.175 3.175a40 40 0 0 0 15 15L38.4 36.6a5 5 0 0 1 5.275-1.125 32.1 32.1 0 0 0 7.025 1.75A5 5 0 0 1 55 42.3Z"
    />
  </Svg>
)
export default PhoneIncoming
