import { VStack, View, Menu, Image, Pressable, useColorMode, HStack, Avatar, Icon } from 'native-base'
import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useWindowDimensions } from 'react-native';
import { colors } from '../../ui/theme/generic-theme/colors';
import { routes, tabsIcons, webRoutes } from '../utils';
import { TabParamList } from '../utils';
import { DrawerContentScrollView, DrawerItem, DrawerItemList } from '@react-navigation/drawer';
import { Ionicons } from '@expo/vector-icons';
import { useSharedValue, useAnimatedStyle, withTiming, withSpring } from 'react-native-reanimated';
import { getProfilo } from '../../api/profilo';
import { getFirstLetterToUppercase } from '../../utils/utils';
import { signOut } from '../../core/auth/auth-context';
import { Text, Box, Divider } from 'native-base';
import { useProfilo } from '../../core/store/profilo-store';
type Props = {}

const Drawer = createDrawerNavigator();


export default function DrawerNavigator({ }: Props) {
  const dimensions = useWindowDimensions();
  const drawerWidth = useSharedValue(dimensions.width >= 768 ? 300 : 85);
  const [isResized, setIsResized] = React.useState(false)
  const { colorMode, toggleColorMode } = useColorMode();

  const widthCustom = useAnimatedStyle(() => {
    return {
      width: drawerWidth.value
    }
  })

  const onResize = () => {
    setIsResized((prev) => !prev);
    if (drawerWidth.value === 300) {
      drawerWidth.value = withTiming(85, { duration: 300 })
    } else {
      drawerWidth.value = withTiming(300, { duration: 300 })
    }
  }


  type BarIconType = {
    name: keyof TabParamList;
    color: string;
    size: number;
  };

  const BarIcon = ({ color, name, size, ...reset }: BarIconType) => {
    const Icon = tabsIcons[name];
    return <Icon color={color} size={size} {...reset} />;
  };

  const { data: profilo, isLoading, isError } = getProfilo();
  const {setContattaci} = useProfilo(state => state);



  return (
    <Drawer.Navigator

      screenOptions={({ route }) => ({
        drawerType: dimensions.width >= 768 ? 'permanent' : 'front',
        headerShown: dimensions.width >= 768 ? false : true,
        drawerIcon: ({ color, size, focused }) => <BarIcon name={route.name} size={focused ? 28 : size} color={color} />,
        drawerActiveTintColor: colorMode === "dark" ? colors.white : colors.primary[400],
        drawerInactiveTintColor: colors.neutral[600],
        headerStyle: {
          backgroundColor: colors.dark,
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
          fontSize: 22,
        },
        drawerStyle: [
          widthCustom,
          { backgroundColor: colorMode === "dark" ? colors.dark : colors.white },
          { paddingTop: 5 },
          { borderRight: "none" },
          dimensions.width >= 768 ? {} : {
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 12,
            },
            shadowOpacity: 0.58,
            shadowRadius: 16.00,
            elevation: 24,

          }
        ],
      }
      )}
      initialRouteName="Dashboard"

      drawerContent={(props) => (
        <DrawerContentScrollView contentContainerStyle={{ height: "100%" }} {...props} >
          <VStack minH={"full"} space={4} mx={2} position="relative" >
            <Image source={require('../../assets/logo-segreteria.png')} alt="Logo Segreteria" size="xl" mx="auto" resizeMode="contain" />
            {dimensions.width >= 768 && <Pressable position={"absolute"} right={0} top={0} onPress={onResize}>
              <View w="100%" >
                <Icon as={<Ionicons name={"resize"} />} color="dark" size="6" _dark={{
                  color: "white"
                }} />
              </View>
            </Pressable>}
            <DrawerItemList  {...props} />
            <Menu
              rounded={"xl"}
              marginTop={"auto"}
              placement='right top'
              left={3}
              _dark={{ borderColor: "gray.800", borderWidth: 1, bg: "dark" }}
              bottom={2}
              trigger={(triggerProps) => {
                return (
                  <Pressable mt="auto" mb="4" {...triggerProps}>

                    <HStack p="2" >

                      <Avatar bg="neutral.600" size="md" >
                        {getFirstLetterToUppercase(profilo?.results[0]?.first_name) + "" + getFirstLetterToUppercase(profilo?.results[0]?.last_name)}
                      </Avatar>

                      {!isResized &&
                        <Text fontSize="lg" ml="2" textAlign={"right"} p="2" fontWeight="bold" color="neutral.600" >{(profilo?.results[0]?.first_name) + " " + (profilo?.results[0]?.last_name)}</Text>}

                    </HStack>


                  </Pressable>
                )
              }}
            >

              <VStack alignItems={"flex-start"} p="2"  >
                <Text color="dark" lineHeight={"xs"} _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">{profilo?.results[0]?.first_name + " " + profilo?.results[0]?.last_name}</Text>
                <Text color="gray.800" _dark={{ color: "white" }} fontSize={"md"} fontWeight="400">{profilo?.results[0]?.email}</Text>
              </VStack>
              <Divider />
              <Menu.Item

                onPress={toggleColorMode}
              >
                {colorMode === "dark" ?
                  <HStack space={4}>
                    <Ionicons name="sunny-outline" size={24} color="white" />
                    <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Modalità Light</Text>
                  </HStack>
                  :
                  <HStack space={4}>
                    <Icon as={<Ionicons name="moon-outline" />} color="dark" size="6" />
                    <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Modalità Dark</Text>
                  </HStack>
                }
              </Menu.Item>
              <Divider />
              <Menu.Item

                onPress={() => {
                  props.navigation.navigate("Cambio Password");
                }}
              >
                <Icon as={<Ionicons name="key-outline" />} color="dark" size="6" _dark={{
                  color: "white"
                }} />
                <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Cambio Password</Text>
              </Menu.Item>
              <Divider />
              <Menu.Item
                onPress={() => {
                  setContattaci(true)
                }}
              >
                <Icon as={<Ionicons name="send-outline" />} color="dark" size="6" _dark={{
                  color: "white"
                }} />
                <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Contattaci</Text>
              </Menu.Item>
              <Divider />
              <Menu.Item

                onPress={() => {
                  signOut();
                }}
              >
                <Icon as={<Ionicons name="log-out-outline" />} color="dark" size="6" _dark={{
                  color: "white"
                }} />
                <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Esci</Text>
              </Menu.Item>
            </Menu>
          </VStack>
        </DrawerContentScrollView>
      )}>
      {webRoutes?.map(({ name, component }) => (
        <Drawer.Screen key={name} name={name} component={component} />
      ))}
    </Drawer.Navigator>
  )
}