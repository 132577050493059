import { create } from "zustand";
import React from "react";
import { createSelectors } from "../../utils";
import { Nominativi } from "../../../api/servizi";
import { DatiContattoStore, useDatiContatto } from "./dati-contatto/dati-contatto-store";
import { checkIsJson } from "../../../utils/utils";
import { listaPriorita } from "../../../utils/get-priorita";
import { useTrasferimenti } from "./trasferimenti/trasferimenti-store";
import { useServizio } from "../servizio-store";

export interface ContattoStore {
    contatto: Nominativi | null,
    editSezioneContatto: boolean | undefined,
    setEditSezioneContatto: (value: boolean) => void,
    setCreazioneContatto: () => void,
    setContatto: (contatto: Nominativi | null, editContatto: boolean) => void,
    getAllContattoData: () => void,
    restoreContattoData: () => void,
}

const setDatiContatto = useDatiContatto.getState().setDatiContatto
const restoreDatiContattoData = useDatiContatto.getState().restoreDatiContattoData
const setTrasferimenti = useTrasferimenti.getState().setTrasferimento
const getAllDatiContatto = useDatiContatto.getState().getAllDatiContatto
const getAllTrasferimenti = useTrasferimenti.getState().getAllTrasferimenti
const getServizioId = (useServizio.getState() as { getServizioId: () => string })?.getServizioId;

const useContattoStore = create<ContattoStore>((set) => ({
    contatto: null,
    editSezioneContatto: false,
    setEditSezioneContatto: (val) => set({ editSezioneContatto: val }),
    setCreazioneContatto: () => {
        restoreDatiContattoData();
        setTrasferimenti({
            trasferimentoEmail: false,
            trasferimentoSms: false,
            mancatoTrasferimentoEmail: false,
            mancatoTrasferimentoSms: false,
        });
        set({ contatto: null, editSezioneContatto: true });
    },
    setContatto: (contatto, editContatto) => {
        if (!contatto) return;
        const datiContatto = {
            email: checkIsJson(contatto.email_ica)?.destinatari,
            emailCC: checkIsJson(contatto.email_ica)?.cc,
            telefono: contatto.telefono_ica,
            priorita: listaPriorita.find((val) => val.valore === contatto.priorita)?.descrizione,
            alias: checkIsJson(contatto.alias).alias,
            nome: contatto.nome,
            cognome: contatto.cognome,
            descrizione: contatto.descrizione,
            servizio_id: contatto.servizio,
        };
        setDatiContatto(datiContatto);
        const trasferimenti = {
            trasferimentoEmail: checkIsJson(contatto.codice_policy_trasferita)?.invio_email,
            trasferimentoSms: checkIsJson(contatto.codice_policy_trasferita)?.invio_sms,
            mancatoTrasferimentoEmail: checkIsJson(contatto.codice_policy_non_trasferita)?.invio_email,
            mancatoTrasferimentoSms: checkIsJson(contatto.codice_policy_non_trasferita)?.invio_sms,
        };
        setTrasferimenti(trasferimenti);
        set({ contatto, editSezioneContatto: editContatto });
    },
    getAllContattoData: () => {
        const datiContatto = getAllDatiContatto();
        const trasferimenti = getAllTrasferimenti();
        const servizio_id = getServizioId();
        const payload = {
            ...datiContatto,
            ...trasferimenti,
            servizio_id,
            modificato: 1,
            descrizione: `${datiContatto.nome} ${datiContatto.cognome}`,
        };
        return payload;
    },
    restoreContattoData: () => {
        restoreDatiContattoData();
        setTrasferimenti({
            trasferimentoEmail: false,
            trasferimentoSms: false,
            mancatoTrasferimentoEmail: false,
            mancatoTrasferimentoSms: false,
        });
    },
}));

export const useContatto = createSelectors(useContattoStore);