//Create a Bottom Sheet component with 2 numbers and a mail 
import React from 'react'
import { HStack, Text, VStack, Stack, Divider, Icon } from 'native-base';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { BottomActionSheet } from '../../ui/components/bottom-action-sheet/bottom-action-sheet';
import { Ionicons } from '@expo/vector-icons';
import { Linking } from 'react-native';
import { useProfilo } from '../../core/store/profilo-store';
import { BottomSheetFooter } from '@gorhom/bottom-sheet';
import { useNavigation } from '@react-navigation/native';
import { useColorMode } from 'native-base';

type Props = {
    titolo: string;
    descrizione: string;
}

export default function Contattaci({
    titolo,
    descrizione,
}: Props) {

    const { open_contattaci, onCloseContattaci } = useProfilo(state => state);
    const { navigate } = useNavigation();
    const {colorMode} = useColorMode();

    
    const handleChiamaCommerciale = () => {
        Linking.openURL('tel:0400648888');
    }

    const handleChiamaCommercialeWhatsApp = () => {
        Linking.openURL('whatsapp://send?phone=390400648888');
    }

    const handleEmailCommerciale = () => {
        Linking.openURL('mailto:info@segreteriaremota.it')
    }

    const handleOpenChatHcl = () => {
        navigate("Chat");
    }

    const handleSitoWeb = () => {
        Linking.openURL('https://www.segreteriaremota.it/')
    }

    const renderFooter = React.useCallback((props: any) =>
         <BottomSheetFooter
            {...props}
            bottomInset={0}
        >
            <Stack mx="4" mb="8" space={4} >
                <TouchableOpacity onPress={handleChiamaCommercialeWhatsApp}>
                    <HStack alignItems={"center"} space={2}>
                        <Icon as={<Ionicons name="logo-whatsapp" />} size="3xl" color="success.600" />
                        <Text _dark={{color:"white"}} fontSize={"xl"} fontWeight={"400"} color={"dark"}>
                            040 064 88 88
                        </Text>
                    </HStack>
                </TouchableOpacity>
                <Divider />
                <TouchableOpacity onPress={handleOpenChatHcl}>
                    <HStack alignItems={"center"} space={2}>
                        <Icon as={<Ionicons name="ios-person-circle-outline" />} size="3xl" color="success.600" />
                        <Text _dark={{color:"white"}} maxWidth={"80%"} fontSize={"xl"} fontWeight={"400"} color={"dark"}>
                            Chatta con noi
                        </Text>
                    </HStack>
                </TouchableOpacity>
                <Divider />
                <TouchableOpacity onPress={handleChiamaCommerciale}>
                    <HStack alignItems={"center"} space={2}>
                        <Icon as={<Ionicons name="ios-call-outline" />} size="3xl" color="success.600" />
                        <Text _dark={{color:"white"}} maxWidth={"80%"} fontSize={"xl"} fontWeight={"400"} color={"dark"}>
                            040 064 88 88
                        </Text>
                    </HStack>
                </TouchableOpacity>
                <Divider />
                <TouchableOpacity onPress={handleEmailCommerciale}>
                    <HStack alignItems={"center"} space={2}>
                        <Icon as={<Ionicons name="ios-mail-open-outline" />} size="3xl" color="success.600" />
                        <Text _dark={{color:"white"}} fontSize={"xl"} fontWeight={"400"} color={"dark"}>
                            info@segreteriaremota.it
                        </Text>
                    </HStack>
                </TouchableOpacity>
            </Stack>
        </BottomSheetFooter>
        , [])

    return (
        <BottomActionSheet
            snap={["57%"]}
            isOpen={open_contattaci}
            onClose={onCloseContattaci}
            footerComponent={renderFooter}
            colorMode={colorMode}
        >
            <VStack mx="4">
                <Text _dark={{color:"white"}} fontSize={"3xl"} lineHeight={"sm"} fontWeight={"400"} color={"dark"}>
                    {titolo}
                </Text>
                <Text _dark={{color:"white"}} fontSize={"lg"} lineHeight={"sm"} fontWeight={"400"} color={"dark"}>
                     {descrizione}
                </Text>
            </VStack>

        </BottomActionSheet>
    )
}