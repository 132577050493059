import * as React from "react";
import { NativeBaseProvider } from "native-base";
import theme from "../../theme/generic-theme/generic-theme";
import { APIProvider } from "../../../api/common";
import colorModeManager from "../../../core/color-manager/color-mode-manager";

type Props = {
  children: React.ReactNode;
};

export default function AppContainer(props: Props) {



  return (
      <APIProvider>
        <NativeBaseProvider colorModeManager={colorModeManager} theme={theme}>{props.children}</NativeBaseProvider>
      </APIProvider>
    // </AuthProvider>
  );
}
