import { useNavigation } from '@react-navigation/native'
import { useModal } from '../../core/store/modal-store';
import { Ionicons } from '@expo/vector-icons';
import { Box, HStack, View, Text} from 'native-base';
import { TouchableOpacity } from 'react-native-gesture-handler';

type Props = {}

export default function Modal({ }: Props) {
  const navigation = useNavigation();
  const header = useModal(state => state.header);
  const content = useModal(state => state.content);

  return (
    <View h="full" bg="white" _dark={{
      bg: "dark"
    }}>
      <HStack mx={{
        base: "4",
        md: "8"
      }} alignItems={"center"} _android={{
        pt: "8"
      }} _ios={{
        pt: "4"
      }} _web={{
        pt: "4"
      }} >
        <HStack alignItems={"center"} space={2} flex={1}>
          <Box justifyContent={"center"} alignItems={"center"} w={12} h={12} bg={header.color} rounded={"full"}>
            <Ionicons name={header.icon} size={26} md="auto" color="white" />
          </Box>
          <Text _dark={{color:"white"}} fontFamily={"body"} color="dark" fontWeight={"600"} fontSize={"3xl"} >{
            header.title
          }</Text>
        </HStack>
        <TouchableOpacity onPress={() => navigation.goBack()} >
          <Text fontFamily={"body"} fontWeight={"400"} fontSize={"sm"} color="danger.600" >
            Chiudi
          </Text>
        </TouchableOpacity>
      </HStack>
      <View _dark={{ bg: "dark" }} mt="4" mb="4" >
        {content}
      </View>
    </View>
  )
}