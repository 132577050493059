import React from 'react'
import { Text } from 'native-base'
import Animated, {useAnimatedStyle, interpolate} from 'react-native-reanimated'


type Props = {
    colorMode: "light" | "dark" | null | undefined,
    activeDotIndex: Animated.SharedValue<number>,
    index: number,
    title: string,
    PAGE_WIDTH: number | undefined
}

export default function HeaderItem({title,index,colorMode,activeDotIndex, PAGE_WIDTH}: Props) {

    // Create Parallax Effect
    const { Extrapolate } = Animated


    

    const animStyle = useAnimatedStyle(() => {
        return {
            opacity: activeDotIndex.value === index ? 1 : 0.5,

            //Create Skew Effect, the active item will be in the beginning of the list
            transform: [
                {
                    translateX: interpolate(
                        activeDotIndex.value,
                        [index - 1, index, index + 1],
                        [PAGE_WIDTH ?? 1 / 2, index, - PAGE_WIDTH ?? 1 / 2],
                        Extrapolate.CLAMP
                    )
                }
            ],
        }
    })

  return (
    <Animated.View
    key={index}
    style={[{
        // height: 40,
        alignItems: "flex-start",
    },animStyle]}
    >
        <Text fontSize={"2xl"} fontWeight={"400"} color="dark" _dark={{ color: "white" }} mt="1">{title}</Text>
    </Animated.View>
  )
}