// Query per la creazione di una regola

import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";
import {Regole} from "../types";


type Variables = {
    data:any;
    id_nominativo: string;
  };

export const createRegola = createMutation<Regole ,Variables, AxiosError>({
    mutationFn: async ({ variables }) => {
        return auth?.post(`/api/saveRegola`,{...variables.data, nominativo_id: variables.id_nominativo}).then((response) => response.data).catch((err) => console.log(err));
    }
});