import { VStack, Divider, Text, CheckCircleIcon, HStack, useColorMode } from 'native-base'
import React from 'react'
import { Platform, TouchableOpacity } from 'react-native'
import { Menu, Pressable, View } from 'native-base';
import { DatePicker } from '../../../../../../../ui/components/date-picker/date-picker';
import { useRegole } from '../../../../../../../core/store/rubrica/regole/regole-store';

type Props = {}

type Giorno = {
    cron: string,
    message: string
}

type Mese = {
    cron: string,
    message: string
}
export const giorniSettimana = [
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
    "Domenica"
];

export const mesi = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre"
]

export default function CronPersonalizzata({ }: Props) {

    const ripetizione = useRegole(state => state.ripetizione);
    const setOraInizio = useRegole(state => state.setOraInizio);
    const setOraFine = useRegole(state => state.setOraFine);
    const setGiorni = useRegole(state => state.setGiorni);
    const setMesi = useRegole(state => state.setMesi);
    const setEnableOrario = useRegole(state => state.setEnableOrario);
    const setErrorDate = useRegole(state => state.setErrorDate);
    const isErrorDate = useRegole(state => state.isErrorDate);
    const { colorMode } = useColorMode();



    const handleChangeInizio = (event: any, selectedDate: any) => {

        // Check if date is less than data fine
        if (selectedDate > ripetizione.oraFine) {
            setErrorDate(true);
        } else {
            setErrorDate(false);
        }

        setOraInizio(new Date(selectedDate))
    }

    const handleChangeFine = (event: any, selectedDate: any) => {

        if (selectedDate < ripetizione.oraInizio) {
            setErrorDate(true);
        } else {
            setErrorDate(false);
        }

        setOraFine(new Date(selectedDate))
    }

    const handleChangeGiorniSettimana = (item: Giorno) => {
        setGiorni(
            ripetizione.giorni.includes(item) ? ripetizione.giorni.filter((giorno) => giorno !== item) : [...ripetizione.giorni, item]
        )
    }

    const handleChangeMesi = (item: Mese) => {
        setMesi(
            ripetizione.mesi.includes(item) ? ripetizione.mesi.filter((mese) => mese !== item) : [...ripetizione.mesi, item]
        )

    }



    return (
        <VStack space={4} >

            {/* Orario */}
            <HStack w="full" space={1} alignItems={"center"} >
                <VStack flex={1} alignItems={"flex-start"} justifyContent={"flex-start"}  >
                    <Text fontSize="md" fontWeight="400">Orario</Text>

                    {/* </VStack> */}
                    {ripetizione.enableOrario ? <HStack space={2} alignItems={"center"} >
                        <DatePicker
                            mode="time"
                            value={ripetizione?.oraInizio}
                            onChange={handleChangeInizio}
                            colorMode={colorMode}
                        />
                        {/* <Text fontSize="md" fontWeight="600">-</Text> */}
                        <DatePicker
                            colorMode={colorMode}
                            mode="time"
                            value={ripetizione?.oraFine}
                            onChange={handleChangeFine}
                        />
                    </HStack> :
                        <Text fontSize="md" fontWeight="400" color={"gray.800"}>Tutto il Giorno</Text>
                    }
                    {isErrorDate && <Text
                        fontSize={"md"}
                        fontWeight={"400"}
                        color={"danger.600"}
                    >
                        L'ora di inizio deve essere minore dell'ora di fine
                    </Text>}
                </VStack>

                <TouchableOpacity onPress={() => setEnableOrario(
                    !ripetizione.enableOrario
                )}>
                    <Text ml="auto" fontSize="md" fontWeight="400" color={"gray.800"}>{
                        ripetizione.enableOrario ? "Nascondi Orario" : "Scegli Orario"
                    }</Text>
                </TouchableOpacity>

            </HStack>

            <VStack>
                <HStack>
                    <Text flex={1} fontSize="md" fontWeight="400">Giorni Della Settimana</Text>
                    {/* Lista dei giorni della settimana */}
                    <Menu
                        closeOnSelect={false}
                        rounded={"xl"}
                        w="90%"
                        minW={Platform.OS === "web" ? "250" : "auto"}
                        placement='bottom left'
                        trigger={(triggerProps) => {
                            return (
                                <Pressable  {...triggerProps}>
                                    <Text
                                        ml="auto" fontSize="md" fontWeight="400" color={"gray.800"}>{"Scegli Giorni"}</Text>
                                </Pressable>
                            )
                        }
                        }>
                        {giorniSettimana?.map((item: any, index: number) => (
                            <View key={index + item}>
                                <Menu.Item onPress={() => handleChangeGiorniSettimana(item)}>

                                    <HStack w="full" alignItems={"center"}>
                                        <Text w="90%" fontSize="md" fontWeight="400">{item}</Text>
                                        {ripetizione.giorni?.includes(item)
                                            && <CheckCircleIcon size="4" color="success.600" />}
                                    </HStack>
                                </Menu.Item>
                                <Divider />
                            </View>
                        ))}
                    </Menu>
                </HStack>
                <HStack px="2" w="80%" space={1} alignItems={"flex-start"} flexWrap={"wrap"} >
                    {ripetizione.giorni?.map((giorno, indx) => (
                        <Text key={indx} fontSize="md" fontWeight="600">{giorno + ","}</Text>
                    ))}
                    {ripetizione.giorni.length === 0 && <Text fontSize="md" fontWeight="400" color={"gray.800"}>Tutti i giorni della settimana</Text>}
                </HStack>
            </VStack>


            {/* Lista di Mesi */}
            <VStack>
                <HStack>
                    <Text flex={1} fontSize="md" fontWeight="400">Mesi</Text>
                    <Menu
                        closeOnSelect={false}
                        rounded={"xl"}
                        w="90%"
                        minW={Platform.OS === "web" ? "250" : "auto"}
                        placement='bottom left'
                        trigger={(triggerProps) => {
                            return (
                                <Pressable  {...triggerProps}>
                                    <Text
                                        ml="auto" fontSize="md" fontWeight="400" color={"gray.800"}>{"Scegli Mese"}</Text>
                                </Pressable>
                            )
                        }
                        }>
                        {mesi?.map((item: any, index: number) => (
                            <View key={index + item}>
                                <Menu.Item key={index} onPress={() => handleChangeMesi(item)}>

                                    <HStack w="full" alignItems={"center"}>
                                        <Text w="90%" fontSize="md" fontWeight="400">{item}</Text>
                                        {ripetizione.mesi?.includes(item) && <CheckCircleIcon size="4" color="success.600" />}
                                    </HStack>
                                </Menu.Item>
                                <Divider />
                            </View>
                        ))}
                    </Menu>
                </HStack>
                <HStack px="2" w="80%" space={1} alignItems={"flex-start"} flexWrap={"wrap"} >
                    {ripetizione.mesi.map((mese, indx) => (
                        <Text key={indx} fontSize="md" fontWeight="600">{mese + ","}</Text>
                    ))}
                    {ripetizione.mesi.length === 0 && <Text fontSize="md" fontWeight="400" color={"gray.800"}>Tutti i mesi dell'anno</Text>}
                </HStack>
            </VStack>

            {/* <VStack mt="auto">
                <Text fontSize="md" fontWeight="400">Periodo Regola</Text>
                <Text fontSize="md" fontWeight="600">{handlerPeriodoCron}</Text>
            </VStack> */}
        </VStack>

    )
}