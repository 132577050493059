import { Image } from "react-native";

const SiriaBlu = require('../assets/logo-siria-blu.png');
const CarenLight = require('../assets/logo-caren-light.png');
const Segreteria = require('../assets/adaptive-icon.png');

export const getServizioIconSource = (tipo_pacchetto: string | null) => {
    
        if(tipo_pacchetto === "siria_basic") return SiriaBlu
    
        if(tipo_pacchetto === "caren_light") return CarenLight
        
        return Segreteria
}

export const getServizioIcon = (tipo_pacchetto: string | null, width: number, height: number) => {
        
        if(tipo_pacchetto === "siria_basic") return <Image source={SiriaBlu} alt="Alternate Text" style={{
                width: width-25,
                height: height-25,
                margin: 12
        }} resizeMode='contain'  />
        
        if(tipo_pacchetto === "caren_light" || tipo_pacchetto === "caren_light_centralino") return <Image source={CarenLight} alt="Alternate Text" style={{
                width: width,
                height: height,
        }} resizeMode='contain' />
        
        return <Image source={Segreteria} alt="Alternate Text" style={{
                width: width,
                height: height,
        }} resizeMode='contain' />
}