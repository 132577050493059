import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";
import { Regole } from "../types";


type Response = Regole[];

type Variables = {
    id_nominativo: string;
  };


export const getRegole = createQuery<Response, Variables, AxiosError>({
    primaryKey: "regole",
    enabled: (data,variables) => Boolean(variables.id_nominativo),
    queryFn: async ({ queryKey: [primaryKey, variables] }) => {
        return auth?.get(`/api/getRegole/${variables.id_nominativo}/`).then((response) => response.data).catch((err) => console.log(err));
    },
    
});