import React from 'react'
import { Text, VStack, ScrollView, Divider, HStack, Box, FlatList, View, Stack } from 'native-base';
import { ServizioResult } from '../../../models/Servizio';
import { getDay, getMonth, getYear, getYearMonth } from '../../../utils/utils';
import { Dimensions } from 'react-native';
type Props = {
  servizioSelezionato: ServizioResult | undefined;
  dataSet: any[]
}

export default function PacchettiAcquistati({ servizioSelezionato, dataSet }: Props) {



  const handleRenderItem = React.useCallback(({ item, index }: any) => {
    
    //Check if the year is different from the previous one

    return (
      <HStack borderBottomWidth={"1"} borderBottomColor={"gray.200"} p="2" py="4" position={"relative"} w="full" key={index}>
        <Box _dark={{
          bg: "dark",
          borderColor: "white",
          borderWidth: 2
        }} rounded={"2xl"} justifyContent={"center"} borderColor={"secondary.100"} borderWidth={"2"} bg="white" mx="2" minW={"20"} minH={"16"}>
          <Text my="0.5" textAlign={"center"} color={"secondary.100"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"700"} fontSize={"3xl"}>{getDay(item.data_acquisto)}</Text>
          <Text textAlign={"center"} color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"400"} fontSize={"sm"}>{getMonth(item.data_acquisto)}</Text>
          <Text my="1" textAlign={"center"} color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"400"} fontSize={"xs"}>{getYear(item.data_acquisto)}</Text>
        </Box>
        <VStack px="2" >

          <HStack space={1} alignItems={"flex-end"}>
            <Text fontFamily={"body"} fontWeight={"700"} fontSize={{
              base: "3xl",
              md: "4xl",
            }} lineHeight={"xs"} color="dark" _dark={{ color: "white" }}>
              {parseInt(item.importo).toFixed(0)}
            </Text>
            <Text lineHeight={"lg"} fontFamily={"body"} fontWeight={"600"} fontSize={{
              base: "lg",
              md: "xl",
              lg: "xl",
            }} color="dark" _dark={{ color: "white" }}>
              Chiamate
            </Text>
          </HStack>
          <Text color={"dark"} maxW={"80%"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"400"} fontSize={"md"}>
            {item.descrizione}
          </Text>
        </VStack>
        <Box right={"2"} top="2" bg="info.200" w="5" h="5" rounded={"2xl"} justifyContent={"center"} position={"absolute"}>
          <Text textAlign={"center"} color={"white"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"600"} fontSize={"xs"}>{"x" + parseInt(item.quantita)}</Text>
        </Box>
      </HStack>
    )

  }, []);


  if (!servizioSelezionato?.pacchetti_acquistati || servizioSelezionato.pacchetti_acquistati.length === 0) {
    return <Text color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"400"} fontSize={"md"}>Nessun Pacchetto Acquistato</Text>
  }


  return (
    <FlatList
      maxH={Dimensions.get('window').height - 100}
      contentContainerStyle={{ paddingBottom: 250 }}
      data={dataSet}
      renderItem={handleRenderItem}
      h="100%"
      // stickyHeaderIndices={dataSet.filter((item: any) => item.isYearSeparator).map((item: any, index: number) => index)}
      keyExtractor={(_item, index) => `${index}`}
    />
  )
}