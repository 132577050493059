import React from 'react'
import { HStack, Switch, Text } from 'native-base'
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useRegole } from '../../../../../../core/store/rubrica/regole/regole-store';

export default function TipoRegola() {

  const tipoRegola = useRegole(state => state.tipoRegola);
  const setTipoRegola = useRegole(state => state.setTipoRegola);

  return (
    <HStack justifyContent={"space-between"}>
      <Text fontSize="xl" fontWeight="600">Tipo Regola</Text>
      <HStack alignItems={"center"} ml="auto">
      {/* Create 2 toggler buttons for "assenza" and "presenza" */}
      <TouchableOpacity onPress={() => setTipoRegola(0)}><Text fontSize="xl" mr="2" _dark={{
        color: tipoRegola === "Presenza" ? "white" : "gray.500"
      }} color={tipoRegola === "Presenza" ? "primary.500" : "gray.500"}>Presenza</Text></TouchableOpacity>
      <TouchableOpacity onPress={() => setTipoRegola(1)}><Text _dark={{
        color: tipoRegola === "Assenza" ? "white" : "gray.500"
      }} fontSize="xl" mr="2" color={tipoRegola === "Assenza" ? "primary.500" : "gray.500"}>Assenza</Text></TouchableOpacity>
      </HStack>
    </HStack>
  )
}