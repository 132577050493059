import * as React from "react"
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg"
const Welcome = (props: SvgProps) => {

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 550 1000"
      fill="none"
      style={{
        margin: "auto"
      }}
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fill="#F2F2F2"
          d="m294.343 657.212-.781-17.576-.244-.129c-8.376-4.395-17.976 3.561-15.301 12.634 2.518 8.537 4.209 17.74 8.816 25.212a34.152 34.152 0 0 0 25.039 15.778l10.642 6.516a57.218 57.218 0 0 0-12.06-46.364 55.233 55.233 0 0 0-10.157-9.437c-2.549 6.72-5.954 13.366-5.954 13.366Z"
        />
        <Path
          fill="#7A93AC"
          d="M199.067 478.844c-20.488-21.129-50.177-33.247-81.456-33.247l-1.207.004a116.325 116.325 0 0 1-58.635-15.835l-1.852-1.079 1.491-1.537a113.364 113.364 0 0 0 19.993-28.307 34.28 34.28 0 0 1-27.667 8.938 35.095 35.095 0 0 1-25.77-16.506C13.032 373.537 0 348.369 0 329.195a116.671 116.671 0 0 1 38.063-86.091 72.655 72.655 0 0 0 24-54.172l-.006-1.452a164.782 164.782 0 0 1 4.89-39.873l.33-1.317 1.356.047c8.738.298 17.48-.435 26.047-2.182a87.881 87.881 0 0 1-23.36-6.213l-1.458-.59.495-1.493c34.101-21.114 111.077-10.18 155.757-112.404C262.342-59.434 390.17 97.036 390.17 187.48c0 9.228-9.133 21.424-2.296 27.524 73.91 65.938 22.913 108.279 6.834 150.885-3.814 10.109 4.457 21.206 4.457 32.142 0 1.856-.047 3.772-.141 5.694l-.142 2.955-2.464-1.64a81.62 81.62 0 0 1-13.48-11.057 71.138 71.138 0 0 1-2.256 61.412c-7.746 14.619-15.239 25.085-22.272 31.105a116.29 116.29 0 0 1-159.343-7.656Z"
        />
        <Path
          fill="#E1E1E1"
          d="M322.808 698.78a2.095 2.095 0 0 0 2.07-1.799c.089-.625 8.8-63.454.976-146.956-7.225-77.116-30.429-188.191-99.939-279.415a2.092 2.092 0 1 0-3.33 2.538c68.909 90.436 91.926 200.696 99.101 277.268 7.778 83.016-.865 145.355-.953 145.975a2.094 2.094 0 0 0 2.075 2.389Z"
        />
        <Path
          fill="#E1E1E1"
          d="M266.084 399.286a2.094 2.094 0 0 0 1.232-3.787c-.252-.183-25.681-18.506-63.696-32.425-35.143-12.866-88.582-24.53-143.597-7.02a2.08 2.08 0 0 0-1.227 1.032 2.091 2.091 0 0 0 .898 2.823 2.099 2.099 0 0 0 1.598.134c53.888-17.15 106.359-5.679 140.888 6.963 37.473 13.72 62.427 31.7 62.675 31.88.357.26.787.4 1.229.4ZM361.896 160.653a2.095 2.095 0 0 0-2.589-2.185 2.095 2.095 0 0 0-1.099.683c-.201.239-20.307 24.282-36.928 61.197-15.363 34.124-30.845 86.585-17.343 142.718a2.09 2.09 0 0 0 3.82.604c.29-.474.38-1.043.25-1.583-13.224-54.983 1.995-106.491 17.091-140.02 16.383-36.388 36.113-59.982 36.311-60.217.285-.337.455-.756.487-1.197Z"
        />
        <Path
          fill="#04446C"
          d="M359.063 268.597c1.766 14.251 11.64 26 26 26a26.001 26.001 0 0 0 26-26c0-14.359-11.702-24.672-26-26-15.171-1.408-28.268 7.705-26 26Z"
        />
        <Path
          fill="#FFB6B6"
          d="m397.105 687.328 9.901-.001 4.71-38.19-14.613.001.002 38.19Z"
        />
        <Path
          fill="#04446C"
          d="m396.757 697.656 30.447-.001v-.385a11.85 11.85 0 0 0-11.851-11.85l-5.562-4.22-10.377 4.22h-2.657v12.236Z"
        />
        <Path
          fill="#FFB6B6"
          d="m358.105 687.328 9.901-.001 4.71-38.19-14.613.001.002 38.19Z"
        />
        <Path
          fill="#04446C"
          d="m357.757 697.656 30.447-.001v-.385a11.85 11.85 0 0 0-11.851-11.85l-5.562-4.22-10.377 4.22h-2.657v12.236ZM431.177 448.137l7 24-8.246 101.645s4.246 6.355 1.246 6.355c-3 0-6.847 1.409-4.423 5.204 2.423 3.796-5.86 17.421-5.86 17.421l-11.717 72.375-14.929-.221s-7.59-4.228-3.331-8.504c4.26-4.275 7.26-4.275 3.26-8.275s-4-2.796-4-5.898-4.007-41.016 5.496-55.559l-2.863-59.295-6.745 58.161-13.888 77.591-14.382-.119s-6.642-6.402-6.63-11.142c.012-4.739 4.104-5.133 1.058-7.936-3.046-2.803-3.046-45.866 5.954-69.803 0 0 5.091-13.374-.011-17.957-5.103-4.583-.007-9.826-.007-9.826s8.082-2.922-.007-11.339c-8.089-8.418-.044-67.949-.044-67.949s-8.842-24.031.113-33.48c8.956-9.449 72.956 4.551 72.956 4.551Z"
        />
        <Path
          fill="#FFB6B6"
          d="m306.503 489.486 12.489-30.182 9.84 5.467-5.943 30.703a10.939 10.939 0 0 1-2.933 11.135 10.934 10.934 0 1 1-13.453-17.123ZM516.077 250.816l-19.004 26.567-8.345-7.554 12.745-28.558a10.94 10.94 0 0 1 5.38-10.181 10.935 10.935 0 1 1 9.224 19.726Z"
        />
        <Path
          fill="#F7A904"
          d="M433.063 450.597c12.326 19.119-49.631 43.381-73.404 33.64-4.163-1.705-18.483-7.339-8.695-15.149 9.788-7.811-1.135-8.91-1.393-13.994-.172-3.399 2.459-10.856-.287-10.536-2.746.319-5.959-4.381.021-6.404 5.98-2.023.064-3.868.116-5.788.572-20.956 16.65-54.287 16.65-54.287L337.1 452.136s.214 12.812-3.48 8.897c-3.695-3.915-3.426 8.757-3.426 8.757s-5.494 3.833-2.335 5.967c3.16 2.134-3.401 8.694-3.401 8.694l-15.306-1.094s.239-11.37 2.853-13.885c2.614-2.515 1.33-6.471 1.33-6.471s6.804-6.024 2.242-10.915c-5.867-6.29 15.525-112.53 22.907-111.477a28.121 28.121 0 0 1 20.08-21.452l16.532-4.552 2.936-11.647h23.278l4.055 7.653 17.698 7.986c3.334-4.703 5.825-9.659 19.475-11.266 0 0 36.783-33.467 34.987-36.08-1.796-2.613 3.906-8.731 3.906-8.731s4.87-1.594 2.122-2.445c-2.748-.851 2.446-2.818 2.446-2.818s4.81-1.264 2.541-2.928c-2.268-1.665 7.572-2.758 7.572-2.758l12.767 10.417-2.581 4.375s1.574 5.958-.48 3.783c-2.053-2.175-2.439 4.137-2.439 4.137s-1.049 6.912-7.267 8.995c-6.218 2.082-10.933 21.866-10.933 21.866-14.665 19.067-31.972 33.942-52.481 43.733 7.993 9.175.365 101.72.365 101.72Z"
        />
        <Path
          fill="#FFB6B6"
          d="M387.937 296.141c12.733 0 23.056-10.322 23.056-23.055 0-12.734-10.323-23.056-23.056-23.056s-23.056 10.322-23.056 23.056c0 12.733 10.323 23.055 23.056 23.055Z"
        />
        <Path
          fill="#04446C"
          d="M364.063 275.597c.73 5.01 5 12 4 12s-8.11-20.653-1-22c5.157-.976 6.31-.12 11.13-2.21l-.68.64c3.19 2.32 7.63.89 11.27-.62 3.65-1.5 8.08-2.93 11.28-.62 2.01 1.45 2.86 3.97 4.11 6.11 1.25 2.15 3.56 4.18 5.94 3.52 1.91-.53 3.01-2.67 3-4.65-.01-1.98-2.588-3.863-1.72-5.64 2.573-5.264.259-8.852-5.35-11.33a924.2 924.2 0 0 1-6.12-.95 17.381 17.381 0 0 1 3.84-3.86 8.78 8.78 0 0 0-3.1-3.97c-1.63-.97-3.65-.9-5.54-.8l-12.65.66c-3.12.16-6.35.35-9.14 1.78-3.48 1.79-5.76 5.28-7.42 8.83-3.72 7.98-3.13 14.4-1.85 23.11Z"
        />
        <Path
          fill="#CACACA"
          d="m268.152 700.254 226.75.307a1.19 1.19 0 1 0 0-2.381l-226.75-.307a1.192 1.192 0 0 0 0 2.381Z"
        />
        <Path
          fill="#7A93AC"
          d="M186.143 596.463c.418-2.873-2.591-5.689-6.721-6.29s-7.817 1.241-8.235 4.114c-.418 2.873 2.591 5.689 6.721 6.29s7.817-1.241 8.235-4.114Z"
        />
        <Path
          fill="#E1E1E1"
          d="M235.314 591.665c.418-2.873-2.591-5.689-6.721-6.29s-7.817 1.241-8.235 4.114c-.418 2.873 2.591 5.689 6.721 6.29s7.817-1.241 8.235-4.114Z"
        />
        <Path
          fill="#F7A904"
          d="M172.678 556.655c.418-2.873-2.591-5.689-6.721-6.29s-7.817 1.241-8.235 4.114c-.418 2.873 2.591 5.689 6.721 6.29s7.817-1.241 8.235-4.114Z"
        />
        <Path
          fill="#E6E6E6"
          d="M151.312 540.349c.02-2.638-1.452-4.787-3.286-4.801-1.835-.014-3.339 2.113-3.359 4.75-.02 2.638 1.452 4.787 3.286 4.801 1.835.014 3.339-2.113 3.359-4.75Z"
        />
        <Path
          fill="#3F3D56"
          d="M144.066 509.977c.02-2.637-1.451-4.787-3.286-4.801-1.835-.014-3.338 2.113-3.358 4.751-.02 2.637 1.451 4.787 3.286 4.801 1.835.014 3.338-2.113 3.358-4.751Z"
        />
        <Path
          fill="#7A93AC"
          d="M200.411 563.429c.02-2.638-1.451-4.787-3.286-4.801-1.835-.014-3.338 2.113-3.358 4.751-.02 2.637 1.451 4.787 3.286 4.801 1.835.013 3.338-2.113 3.358-4.751Z"
        />
        <Path
          fill="#F7A904"
          d="M176.649 575.293c.02-2.638-1.451-4.787-3.286-4.801-1.835-.014-3.338 2.113-3.358 4.751-.02 2.637 1.451 4.787 3.286 4.801 1.835.013 3.338-2.113 3.358-4.751Z"
        />
        <Path
          fill="#E1E1E1"
          d="M209.727 597.221c.02-2.638-1.452-4.787-3.286-4.801-1.835-.014-3.339 2.113-3.359 4.75-.02 2.638 1.452 4.787 3.286 4.801 1.835.014 3.339-2.113 3.359-4.75Z"
        />
        <Path
          fill="#7A93AC"
          d="M564.143 442.463c.418-2.873-2.591-5.689-6.721-6.29s-7.817 1.241-8.235 4.114c-.418 2.873 2.591 5.689 6.721 6.29s7.817-1.241 8.235-4.114Z"
        />
        <Path
          fill="#E1E1E1"
          d="M613.314 437.665c.418-2.873-2.591-5.689-6.721-6.29s-7.817 1.241-8.235 4.114c-.418 2.873 2.591 5.689 6.721 6.29s7.817-1.241 8.235-4.114Z"
        />
        <Path
          fill="#F7A904"
          d="M550.677 402.655c.418-2.873-2.591-5.689-6.721-6.29s-7.817 1.241-8.235 4.114c-.418 2.873 2.591 5.689 6.721 6.29s7.817-1.241 8.235-4.114Z"
        />
        <Path
          fill="#7A93AC"
          d="M529.312 386.349c.02-2.638-1.452-4.787-3.286-4.801-1.835-.014-3.339 2.113-3.359 4.75-.02 2.638 1.452 4.787 3.286 4.801 1.835.014 3.339-2.113 3.359-4.75Z"
        />
        <Path
          fill="#E1E1E1"
          d="M522.066 355.977c.02-2.637-1.451-4.787-3.286-4.801-1.835-.014-3.338 2.113-3.358 4.751-.02 2.637 1.451 4.787 3.286 4.801 1.835.014 3.338-2.113 3.358-4.751Z"
        />
        <Path
          fill="#7A93AC"
          d="M578.411 409.429c.02-2.638-1.451-4.787-3.286-4.801-1.835-.014-3.338 2.113-3.358 4.751-.02 2.637 1.451 4.787 3.286 4.801 1.835.013 3.338-2.113 3.358-4.751Z"
        />
        <Path
          fill="#F7A904"
          d="M554.649 421.293c.02-2.638-1.451-4.787-3.286-4.801-1.835-.014-3.338 2.113-3.358 4.751-.02 2.637 1.451 4.787 3.286 4.8 1.835.014 3.338-2.113 3.358-4.75Z"
        />
        <Path
          fill="#E1E1E1"
          d="M587.727 443.221c.02-2.638-1.452-4.787-3.286-4.801-1.835-.014-3.339 2.113-3.359 4.75-.02 2.638 1.452 4.787 3.286 4.801 1.835.014 3.339-2.113 3.359-4.75Z"
        />
        <Path
          fill="#fff"
          d="M381.143 157.463c.418-2.873-2.591-5.689-6.721-6.29s-7.817 1.241-8.235 4.114c-.418 2.873 2.591 5.689 6.721 6.29s7.817-1.241 8.235-4.114Z"
        />
        <Path
          fill="#E1E1E1"
          d="M430.314 152.665c.418-2.873-2.591-5.689-6.721-6.29s-7.817 1.241-8.235 4.114c-.418 2.873 2.591 5.689 6.721 6.29s7.817-1.241 8.235-4.114Z"
        />
        <Path
          fill="#F7A904"
          d="M367.678 117.655c.418-2.873-2.591-5.689-6.721-6.29s-7.817 1.241-8.235 4.114c-.418 2.873 2.591 5.689 6.721 6.29s7.817-1.241 8.235-4.114Z"
        />
        <Path
          fill="#7A93AC"
          d="M346.312 101.349c.02-2.638-1.452-4.787-3.286-4.801-1.835-.014-3.339 2.113-3.359 4.75-.02 2.638 1.452 4.787 3.286 4.801 1.835.014 3.339-2.113 3.359-4.75Z"
        />
        <Path
          fill="#E1E1E1"
          d="M339.066 70.977c.02-2.638-1.451-4.787-3.286-4.8-1.835-.015-3.338 2.112-3.358 4.75-.02 2.637 1.451 4.787 3.286 4.8 1.835.014 3.338-2.112 3.358-4.75Z"
        />
        <Path
          fill="#7A93AC"
          d="M395.411 124.429c.02-2.638-1.451-4.787-3.286-4.801-1.835-.014-3.338 2.113-3.358 4.751-.02 2.637 1.451 4.787 3.286 4.801 1.835.013 3.338-2.113 3.358-4.751Z"
        />
        <Path
          fill="#F7A904"
          d="M371.649 136.293c.02-2.638-1.451-4.787-3.286-4.801-1.835-.014-3.338 2.113-3.358 4.751-.02 2.637 1.451 4.787 3.286 4.8 1.835.014 3.338-2.113 3.358-4.75Z"
        />
        <Path
          fill="#E1E1E1"
          d="M404.727 158.221c.02-2.638-1.452-4.787-3.286-4.801-1.835-.014-3.339 2.113-3.359 4.75-.02 2.638 1.452 4.787 3.286 4.801 1.835.014 3.339-2.113 3.359-4.75Z"
        />
      </G>
      <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h613.353v700.561H0z" />
      </ClipPath>
    </Defs>
  </Svg>)
}
export default Welcome
