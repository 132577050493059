import { MotiView } from 'moti'
import { Center, Heading, Text, Pressable, View } from 'native-base'
import React from 'react'
import AnimatedCheckmark from '../../../ui/components/animated-checkmark/animated-checkmark'
import { colors } from '../../../ui/theme/generic-theme/colors'
import { signOut } from '../../../core/auth/auth-context'
import { SafeAreaView } from 'react-native-safe-area-context'
type Props = {
    navigation: any,
    route: any
}


export default function CambioPasswordSuccess({
    navigation,
    route
}: Props) {

    const handleBackLogin = () => {

        signOut();

        navigation.navigate("Login", {
            colorMode: route.params?.colorMode
        })
    }

    return (
        <SafeAreaView style={{ width: "100%", height: "100%", backgroundColor: route.params?.colorMode === "dark" ? colors.dark : "white" }}>
            <MotiView style={{
                width: "100%",
                height: "100%",
                maxHeight: "98%"
            }} from={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Center w="90%" mx="auto" h="full">
                    <View  mb="2">
                        <AnimatedCheckmark />
                    </View>
                    <Heading _dark={{
                        color: "white"
                    }} color="success.600" fontWeight={"800"} fontSize={"4xl"} mb="2" fontFamily={"body"} textAlign={"left"}>Password cambiata con successo!</Heading>
                    <Text color="dark" _dark={{
                        color: "white"
                    }} fontWeight={"400"} fontSize={"lg"} fontFamily={"body"} textAlign={"left"}>
                        La tua password è stata cambiata con successo.
                        Ora puoi accedere all'app con la tua nuova password.
                    </Text>
                    <Pressable mx="4" mt="auto" minW="350"  bg="primary.400" h="55" rounded={"md"} onPress={handleBackLogin}>
                        {({
                            isHovered,
                            isFocused,
                            isPressed
                        }) =>

                            <Center style={{
                                opacity: isHovered || isFocused || isPressed ? 0.8 : 1,
                                transform: isHovered || isFocused || isPressed ? [{ scale: 0.98 }] : [{ scale: 1 }]
                            }} flex={1}>
                                <Text color="white" fontWeight={"bold"} textAlign={"center"} fontSize={"lg"} >Torna al Login</Text>
                            </Center>
                        }
                    </Pressable>
                </Center>
            </MotiView>
        </SafeAreaView>
    )
}