import React from 'react'
import { VStack, Text, Stack, Divider } from 'native-base'
import PhoneRinging from '../../../ui/svg/icons/phone-ringing'
import PhoneForwarded from '../../../ui/svg/icons/phone-forwarded'
import HourGlass from '../../../ui/svg/icons/hourglass';
import Languages from '../../../ui/svg/icons/languages';
import Email from '../../../ui/svg/icons/email';
import Calendar from '../../../ui/svg/icons/calendar';
import { Platform, ScrollView } from 'react-native';
import { useWindowDimensions } from 'react-native';

type Props = {
    servizioSelezionato: any,
    colorMode: "dark" | "light" | null | undefined
}

export default function DatiGenerali({
    servizioSelezionato,
    colorMode,
}: Props) {
    
    const dimensions = useWindowDimensions();
    return (
        <ScrollView
            contentContainerStyle={Platform.OS !== "web" && {
                paddingBottom: 300,
                minHeight: "100%"
            }}>
            <VStack p="2" w="full" mx="auto" space={6} mt="2">
                <Stack space={2} flexDir={{
                    base: "column",
                    md: "row",
                }} flexWrap={"wrap"} >
                    <Stack flexDir={"row"} w="full" p="2">
                        {dimensions.width >= 768 && <PhoneRinging color={colorMode === "light" ? "#22272E" : "#fff"} width={"60"} height={"60"} />}
                        <VStack ml="2">
                            <Text fontSize={"xl"} fontWeight="600" color="dark" _dark={{ color: "white" }} >
                                Numeri Attivi
                            </Text>
                            <Text maxW={{
                                base: "95%",
                                md: "90%",
                            }} fontSize={"lg"} fontWeight="400" color="dark" _dark={{ color: "white" }} >
                                {servizioSelezionato && servizioSelezionato?.numeri?.map((numero: any, index: number, arr: []) => index < arr.length - 1 ? numero.numero_telefonico + " (" + numero.tipo_numero + ") ," : numero.numero_telefonico+ " (" + numero.tipo_numero + ") ")}
                            </Text>
                        </VStack>
                    </Stack>
                    <Divider />
                    <Stack
                        flexDir={"row"}
                        w={{
                            base: "100%",
                        }} p="2" alignItems={"center"}>
                         {dimensions.width >= 768 && <HourGlass color={
                            colorMode === "light" ? "#22272E" : "#fff"
                        } width={"60"} height={"60"} />}
                        <VStack ml="2">
                            <Text fontSize={"xl"} fontWeight="600" color="dark" _dark={{ color: "white" }} >
                                Orari di Servizio
                            </Text>
                            <Text maxW={{
                                base: "95%",
                                md: "90%",
                            }} fontSize={"md"} fontWeight="400" color="dark" _dark={{ color: "white" }} >
                                {servizioSelezionato?.orario ? servizioSelezionato?.orario[0]?.giorni_settimana : "Non Disponibile"}
                            </Text>
                            <Text fontSize={"md"} fontWeight={"400"} color="dark" >
                                {servizioSelezionato?.orario && servizioSelezionato?.orario[0]?.ora_inizio} - {servizioSelezionato?.orario && servizioSelezionato?.orario[0]?.ora_fine}
                            </Text>
                        </VStack>
                    </Stack>
                    <Divider />
                    <Stack
                        flexDir={"row"}
                        w={{
                            base: "100%",
                        }} p="2" alignItems={"center"}>
                         {dimensions.width >= 768 && <Languages color={
                            colorMode === "light" ? "#22272E" : "#fff"
                        } width={"60"} height={"60"} />}
                        <VStack ml="2">
                            <Text fontSize={"xl"} fontWeight="600" color="dark" _dark={{ color: "white" }} >
                                Lingue
                            </Text>
                            <Text maxW={{
                                base: "95%",
                                md: "90%",
                            }} fontSize={"md"} fontWeight="400" color="dark" _dark={{ color: "white" }} >
                                {servizioSelezionato?.lingue?.split(";").join(", ")}
                            </Text>
                        </VStack>
                    </Stack>
                    <Divider />
                    <Stack
                        flexDir={"row"}
                        w={{
                            base: "100%",
                        }}
                        p="2"
                        alignItems={"center"}>
                        {dimensions.width >= 768 &&  <Email color={
                            colorMode === "light" ? "#22272E" : "#fff"
                        } width={"60"} height={"60"} />}
                        <VStack ml="2">
                            <Text fontSize={"xl"} fontWeight="600" color="dark" _dark={{ color: "white" }} >
                                Email
                            </Text>
                            <Text maxW={{
                                base: "95%",
                                md: "90%",
                            }} fontSize={"md"} fontWeight="400" color="dark" _dark={{ color: "white" }} >
                                {servizioSelezionato?.email?.split(";").join(", ")}
                            </Text>
                        </VStack>
                    </Stack>
                    <Divider />
                    <Stack
                        flexDir={"row"}
                        w={{
                            base: "100%",
                        }}
                        p="2"
                        alignItems={"center"}>
                        {dimensions.width >= 768 && <Calendar color={
                            colorMode === "light" ? "#22272E" : "#fff"
                        } width={"60"} height={"60"} />}
                        <VStack ml="2">
                            <Text fontSize={"xl"} fontWeight="600" color="dark" _dark={{ color: "white" }} >
                                Agenda
                            </Text>
                            <Text fontSize={"md"} fontWeight="400" color="dark" _dark={{ color: "white" }} >
                                {servizioSelezionato?.agenda == "1" ? "Attiva" : "Non Attiva"}
                            </Text>
                        </VStack>
                    </Stack>
                    <Divider />
                    <Stack flexDir={{
                        base: "row",
                        md: "row",
                    }} w="full" p="2">
                       {dimensions.width >= 768 &&   <PhoneForwarded color={
                            colorMode === "light" ? "#22272E" : "#fff"
                        } width={"60"} height={"60"} />}
                        <VStack ml="2">
                            <Text fontSize={"xl"} fontWeight="600" color="dark" _dark={{ color: "white" }} >
                                Trasferimento Chiamata
                            </Text>
                            <Text fontSize={"md"} fontWeight="400" color="dark" _dark={{ color: "white" }} >
                                {servizioSelezionato?.chiamate_trasferite == "1" ? "Attivo" : "Non Attivo"}
                            </Text>
                        </VStack>
                    </Stack>
                </Stack>
            </VStack>
        </ScrollView>
    )
}