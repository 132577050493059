import { Box } from "native-base"
import { MotiView } from "moti"

type Props = {
    children: React.ReactNode
}

export default function AppContentContainer({
    children
}: Props) {
  return (
    <MotiView
    from={{
      opacity: 0,
      translateY: 50,
    }}
    animate={{
      opacity: 1,
      translateY: 0,
    }}
    transition={{
      type: "timing",
      duration: 800,
    }}
    style={{
      flex: 1,
    }}
  >
    <Box maxW={1200} bg="white" _dark={{ bg: "dark" }} w="95%" p="2" minH={"95%"} maxH={"95%"} my="auto" justifyContent={"space-evenly"} mx="auto" rounded={"3xl"}>
        {children}
    </Box>
    </MotiView>
  )
}