import React, { useEffect } from 'react'
import { useContatto } from '../../../core/store/rubrica/contatto-store'
import { Text, Avatar, View, Box } from 'native-base'
import { useServizio } from "../../../core/store/servizio-store";
import { getFirstLetterToUppercase } from '../../../utils/utils';
import { ContattoCentralino } from './handler-contatto/handler-contatto';
import { useWindowDimensions } from 'react-native';
import { DatiContatto } from './handler-contatto/dati-contatto';
import { SafeAreaView } from 'react-native-safe-area-context';
// import { isReperibile } from '../../../utils/rubrica/reperibilità/is_reperibile';
import { useDatiContatto } from '../../../core/store/rubrica/dati-contatto/dati-contatto-store';

export default function DettagliContatto() {

  const contatto = useContatto((state) => state.contatto)
  const nome = useDatiContatto((state) => state.nome)
  const cognome = useDatiContatto((state) => state.cognome)
  const descrizione = useDatiContatto((state) => state.descrizione)

  const isSiriaCentralino = useServizio(state => state.isSiriaCentralino);

  // const presenze = contatto?.regole.filter((regola) => regola?.period_type === "presenza")
  // const assenze = contatto?.regole.filter((regola) => regola?.period_type === "assenza")
  // const reperibile = isReperibile(presenze, assenze, new Date(), true);


  return (
    <View h="full" w="full" bg="white" _dark={{
      bg: "dark"
    }}>
      <SafeAreaView
        style={{
          flex: 1,
          height: "100%",
          marginHorizontal: 20,
          marginTop: 50,
          position: "relative"
        }}>
        <Avatar mx="auto" bg="info.400" size={"2xl"} >
          {isSiriaCentralino ? getFirstLetterToUppercase(nome + " " + cognome) : getFirstLetterToUppercase(descrizione)}
          {/* <Box w="6" h="6" position="absolute" top={-4} left={-6} bg={true ? "success.200" : "danger.200"} rounded={"3xl"} /> */}
        </Avatar>
        <Text _dark={{ color: "white" }} textAlign={"center"} fontFamily={"body"} color="dark" fontWeight={"400"} fontSize={"3xl"} >{isSiriaCentralino ? nome + " " + cognome : descrizione}</Text>

        {isSiriaCentralino ? <ContattoCentralino />
          : <DatiContatto />
        }
      </SafeAreaView>
    </View>
  )
}