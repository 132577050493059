import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const PhoneForwarded = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 60 60"
    {...props}
  >
    <Path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m45 5.5 10 10-10 10M35 15.5h20M55 42.8v7.5a5.001 5.001 0 0 1-5.45 5 49.475 49.475 0 0 1-21.575-7.675 48.749 48.749 0 0 1-15-15A49.475 49.475 0 0 1 5.3 10.95a5 5 0 0 1 4.975-5.45h7.5a5 5 0 0 1 5 4.3c.317 2.4.904 4.757 1.75 7.025A5 5 0 0 1 23.4 22.1l-3.175 3.175a40 40 0 0 0 15 15L38.4 37.1a5 5 0 0 1 5.275-1.125 32.1 32.1 0 0 0 7.025 1.75A5 5 0 0 1 55 42.8Z"
    />
  </Svg>
)
export default PhoneForwarded
