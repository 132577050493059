import { Divider, Box, Text, VStack, HStack, Icon, View } from 'native-base';
import { useServizio } from '../../core/store/servizio-store';
import { useContatto } from '../../core/store/rubrica/contatto-store';
import { AnimatePresence, MotiView } from 'moti';
import { RectButton } from 'react-native-gesture-handler';
import ContattoModal from './contatto/index';
import { Nominativi } from '../../api/servizi';
import SearchBar from '../../ui/components/search-bar/search-bar';
import { useState } from 'react';
import ContactUs from '../../ui/svg/contact-us';
import AppleStyleSwipeableRow from '../../ui/components/swipable-view';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import { Linking, Platform, useWindowDimensions } from 'react-native';
import { NominativoResult, getNominativi } from '../../api/nominativi';
import { debounce } from '../../utils/utils';
import { colors } from '../../ui/theme/generic-theme/colors';
import AppContentContainer from '../../ui/components/layout/app-content';
import { deleteNominativo } from '../../api/nominativi/remove/delete-nominativo';
import { queryClient } from '../../api/common';
import { FlashList } from "@shopify/flash-list";
import { EmptyList } from '../../ui/components/empty-list/empty-list';
import { useDatiContatto } from '../../core/store/rubrica/dati-contatto/dati-contatto-store';

type Props = {
    navigation: any
}


export const ContattoRow = ({ item, handlePress }: { item: Nominativi; handlePress: (item: any) => void }) => {

    return (
        <RectButton onPress={() => handlePress(item)}>
            <ContattoModal data={item} />
        </RectButton>
    );
};



export default function Rubrica({
    navigation,
}: Props) {

    const servizioSelezionato = useServizio(state => state.servizio)
    const setContatto = useContatto(state => state.setContatto);
    const setCreazioneContatto = useContatto(state => state.setCreazioneContatto);
    const setPriorita = useDatiContatto(state => state.setPriorita);
    const isSiriaCentralino = useServizio(state => state.isSiriaCentralino);

    const [search, setSearch] = useState("")
    const dimensions = useWindowDimensions();

    const { data: nominativi, isLoading } = getNominativi(
        {
            variables: {
                id_servizio: servizioSelezionato?.id,
                search: search
            }
        }
    )

    const { mutate: removeNominativo, isLoading: loadingDeleting, isError: errorDeleting } = deleteNominativo({
        onSuccess: (data) => {
            queryClient.invalidateQueries(getNominativi.getKey());
        }
    });

    const handlePress = (item: any) => {

        // mutate the list of nominativi
        setContatto(item, false);
        navigation.push("ContattoRubrica", {
            screen: "DettagliContatto",
            params: {
                isEdit: true,
            },
        });
    }

    const handleDeleteNominativo = (item: NominativoResult) => {
        const data = removeNominativo({
            variables: {
                nominativo_id: item.id
            }
        })
    }

    const handleAddNominativo = () => {
        setCreazioneContatto();
        setPriorita("Media");
        navigation.push("ContattoRubrica", {
            screen: "DettagliContatto",
            params: {
                isEdit: true,
            },
        });
    }

    const handleContattaci = () => {
        Linking.openURL('tel:0400648888');
    }



    if (servizioSelezionato?.nominativo && servizioSelezionato?.nominativo.length > 0) {
        return (
            <AppContentContainer key="ListaContatti" >
                <HStack alignItems={"center"} justifyContent={"space-evenly"}>
                    <View flex={1} >
                        <SearchBar width='95%' search={search} onChange={debounce((val: string) => setSearch(val), 300)} onClear={() => setSearch("")} />
                    </View>
                    {isSiriaCentralino && <TouchableOpacity style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 10,
                        minWidth: "10%",
                        backgroundColor: colors.gray['50'],
                        marginVertical: 10,
                        padding: 8,
                        borderRadius: 30
                    }} onPress={() => handleAddNominativo()}>
                        <HStack alignItems={"center"}>
                            <Icon as={<Ionicons name="add" />} size="3xl" color="success.400" />
                            {Platform.OS === "web" && dimensions.width > 768 && <Text fontSize={"lg"} fontWeight={"400"} color={"success.400"}>Aggiungi</Text>}
                        </HStack>
                    </TouchableOpacity>}
                </HStack>
                <FlashList
                    
                    ListEmptyComponent={<EmptyList isLoading={isLoading} />}
                    estimatedItemSize={20}
                    data={nominativi}
                    renderItem={({ item, index }) =>
                        <View h="full" key={index}>
                            <AppleStyleSwipeableRow rightActions={{
                                name: "Elimina", color: colors.danger['200'], onPress: () => handleDeleteNominativo(item)
                            }}>
                                <ContattoRow item={item} handlePress={handlePress} />
                            </AppleStyleSwipeableRow>
                        </View>

                    }
                    keyExtractor={(_item, index) => `${index}`}
                    ItemSeparatorComponent={() => <Divider />}
                />
            </AppContentContainer>
        )
    } else {
        return (
            <AppContentContainer>
                <Box maxW={1200} bg="white" _dark={{ bg: "dark" }} w="95%" p="2" minH={"95%"} maxH={"95%"} my="auto" justifyContent={"space-evenly"} mx="auto" rounded={"3xl"}>
                    <ContactUs width={"100%"} height={250} />
                    <VStack flex={1}>
                        <Text textAlign={"center"}>Sembra che al momento non hai nessuna Rubrica attiva.</Text>
                        <Text textAlign={"center"} fontSize={"3xl"} fontWeight={"bold"} lineHeight={"md"}>Per configurare la tua rubrica, contattaci!</Text>
                    </VStack>
                    <TouchableOpacity onPress={handleContattaci} >
                        <Text my="2" textAlign={"center"} color={
                            "neutral.600"
                        }

                            _dark={{
                                color: "white"
                            }} fontSize={"3xl"} fontWeight={"bold"}>Contattaci</Text>
                    </TouchableOpacity>
                </Box>
            </AppContentContainer>
        )
    }

}