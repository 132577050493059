import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const Languages = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <Path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={18}
      d="M48 112h288M192 64v48m80 336 96-224 96 224m-162.5-64h133M281.3 112S257 206 199 277 80 384 80 384"
    />
    <Path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={18}
      d="M256 336s-35-27-72-75-56-85-56-85"
    />
  </Svg>
)
export default Languages
