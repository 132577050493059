// Query per la rimozione di una regola

import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";


type Variables = {
    regola_id: number;
  };

export const deleteRegola = createMutation<void ,Variables, AxiosError>({
    mutationFn: async ({ variables }) => {
        return auth?.post(`/api/deleteRowRegola`,{id: variables.regola_id}).then((response) => response.data).catch((err) => console.log(err));
    }
});