import React from 'react'
import Panoramica from '..'
import { ServizioResult } from '../../../../api/servizi'
import { Divider, ScrollView, Stack, Text, VStack, useColorMode, Box, Spinner, View } from 'native-base'
import { ScopiResult, getScopi } from '../../../../api/statistiche-scopi'
import PieChart from './pie-chart'
import { Dimensions } from 'react-native'
import BarChart from './bar-chart'
import { colors } from '../../../../ui/theme/generic-theme/colors'
import { getStatistiche } from '../../../../api/statistiche-chiamate/get-statistiche'
import { usePanoramica } from '../../../../core/store/panoramica-store'
import RangeDate from '../range'
import { Platform } from "react-native"

type Props = {
    servizioSelezionato: ServizioResult,
}


export default function PanoramicaModal({
    servizioSelezionato
}: Props) {

    const { colorMode } = useColorMode();
    const tipo_statistica = usePanoramica(state => state.tipo_statistica);

    const [activeScroll, setEnableScroll] = React.useState(true);


    const disableScroll = React.useCallback(() => {
        setEnableScroll(false);
    }, []);

    const enableScroll = React.useCallback(() => {
        setEnableScroll(true);
    }, []);

    return (
        <View>
            <ScrollView
                maxH={Dimensions.get('window').height - 100}
                contentContainerStyle={Platform.OS === "web" ? { paddingBottom: 150, maxWidth: "1400px", margin: "auto" } : { paddingBottom: 150 }}
                scrollEnabled={activeScroll}
            >
                <Panoramica hideRangeDate={true} servizioSelezionato={servizioSelezionato} enableScroll={enableScroll} disableScroll={disableScroll} />
                <Divider w="90%" mx="auto" />
                <Stack flexDir={{
                    base: "column",
                    lg: "row"
                }} w="100%">
                    <Scopi tipoStatistica={tipo_statistica} servizioSelezionato={servizioSelezionato} colorMode={colorMode} />
                    <Divider display={{
                        base: "flex",
                        lg: "none"
                    }} w="90%" mx="auto" />
                    <Divider display={{
                        base: "none",
                        lg: "flex"
                    }} h="90%" w="0.5" />

                    <ChiamateSettimanali tipoStatistica={tipo_statistica} servizioSelezionato={servizioSelezionato} colorMode={colorMode} />
                </Stack>
            </ScrollView>
            {/* Create a glassmorphism container */}
            <View position={"absolute"} bottom={Platform.OS === "ios" ? "20" : "10"} maxW={"64"} rounded={"3xl"} bg="gray.50" opacity={"0.85"} w="100%" py="2" alignSelf={"center"} alignItems={"center"} >
                <RangeDate width_screen={Dimensions.get('screen').width} />
            </View>
        </View>
    )
}

const Scopi = ({ servizioSelezionato, colorMode, tipoStatistica }: { servizioSelezionato: ServizioResult | undefined, colorMode: "light" | "dark" | null | undefined, tipoStatistica: string }) => {


    const colorsCharts = [colors.success['200'], colors.warning['200'], colors.danger['200'], colors.info['200'], colors.primary['200'], colors.secondary['200'], colors.purple['200']];

    const colorsLabel = [colors.success['400'], colors.warning['400'], colors.danger['400'], colors.info['400'], colors.primary['400'], colors.secondary['400'], colors.purple['400']];


    const { data: listaScopi, isLoading: isLoadingScopi, isError: isErrorScopi } = getScopi({
        variables: {
            codice_servizio: servizioSelezionato?.codice_servizio ?? "",
            data_inizio: tipoStatistica === "6M" ? new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().slice(0, 10) : tipoStatistica === "3M" ? new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().slice(0, 10) : new Date(new Date().setDate(
                new Date().getDate() - 7
            )).toISOString().slice(0, 10),
            data_fine: new Date().toISOString().slice(0, 10)
        }
    })

    const getTotal = (data: ScopiResult[] | undefined) => {
        if (data) {
            let total = data.reduce((acc, item) => acc + item.numero, 0)
            // Return percentage
            return total;
        }
        return 0;
    }

    return (
        <Stack my={2} w={{
            base: "full",
            lg: "50%"
        }} >
            <Text w="90%" mx="auto" fontSize="2xl" fontWeight="600" textAlign={"left"}>Casistiche Ricorrenti</Text>
            {Array.isArray(listaScopi) && (
                <PieChart
                    colorsCharts={colorsCharts}
                    colorsLabel={colorsLabel}
                    total={getTotal(listaScopi)}
                    colorMode={colorMode}
                    data={listaScopi}
                />
            )}
            <Stack w="90%" mx="auto" flexDir={"row"} flexWrap={"wrap"}>
                {Array.isArray(listaScopi) && listaScopi.map((item, index) => {
                    return (
                        <Stack alignItems={"center"} key={index} m="2" flexDir="row" >
                            <Box w="6" h="6" borderRadius="full" bg={colorsCharts[index]} />
                            <Text ml="2">{item.scopo}</Text>
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    )
}

const ChiamateSettimanali = ({ servizioSelezionato, colorMode, tipoStatistica }: { servizioSelezionato: ServizioResult | undefined, colorMode: "light" | "dark" | null | undefined, tipoStatistica: string }) => {

    const { data: chiamateSettimanali, isLoading, isError } = getStatistiche({
        variables: {
            codice_servizio: servizioSelezionato?.codice_servizio ?? "",
            tipo_statistica: "chiamate_settimanali",
            data_inizio: tipoStatistica === "6M" ? new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().slice(0, 10) : tipoStatistica === "3M" ? new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().slice(0, 10) : new Date(new Date().setDate(
                new Date().getDate() - 7
            )).toISOString().slice(0, 10),
            data_fine: new Date().toISOString().slice(0, 10),
            tipo_aggregazione: "wday"
        }
    })
    const [valoreSelezionato, setValoreSelezionato] = React.useState("")

    if (isLoading) {
        return <View display={"flex"} flex={1} justifyContent={"center"} alignSelf={"center"} mx="auto"  my="4" >
            <Spinner />
        </View>
    }
    return (
        <Stack my={2}
            w={{
                base: "full",
                lg: "50%"
            }}
        >
            <Text w="90%" mx="auto" fontSize="2xl" fontWeight="600" textAlign={"left"}>Chiamate Settimanali</Text>
            <VStack >
                <Text w="90%" mx="auto" fontSize="xs" fontWeight="400" textAlign={"left"}>Numero medio di chiamate per giorno della settimana: </Text>
                <Text w="90%" mx="auto" fontSize="lg" minH={"7"} fontWeight="600" textAlign={"left"}>{valoreSelezionato}</Text>
            </VStack>
            <BarChart setValoreSelezionato={setValoreSelezionato} data={chiamateSettimanali} colorMode={colorMode} />
        </Stack>
    )
}