import { extendTheme } from "native-base";
import { colors } from "./colors";

const config = {
  useSystemColorMode: false,
  initialColorMode: "light",
  fontConfig: {
    Inter: {
      100: {
        fontFamily: "Inter-Light",
        fontWeight: "normal",
      },
      200: {
        fontFamily: "Inter-Light",
        fontWeight: "normal",
      },
      300: {
        fontFamily: "Inter-Regular",
        fontWeight: "normal",
      },
      400: {
        fontFamily: "Inter-Regular",
        fontWeight: "normal",
      },
      500: {
        fontFamily: "Inter-Medium",
        fontWeight: "normal",
      },
      600: {
        fontFamily: "Inter-Medium",
        fontWeight: "normal",
      },
      700: {
        fontFamily: "Inter-SemiBold",
        fontWeight: "normal",
      },
      800: {
        fontFamily: "Inter-Bold",
        fontWeight: "normal",
      },
      900: {
        fontFamily: "Inter-ExtraBold",
      }
    },
  },
  fonts:{
    heading: "Inter",
    body: "Inter",
    mono: "Inter",
  }
};

export default extendTheme({ config, colors });
