import React from 'react'
import { HStack, Menu, Text, Pressable, View, Divider, Icon } from 'native-base'
import { getMotiviAssenza } from '../../../../../../api/nominativi/get-motivi-assenza/get-motivi-assenza';
import { useRegole } from '../../../../../../core/store/rubrica/regole/regole-store';
import { Ionicons } from '@expo/vector-icons'

export default function MotiviAssenza() {

    const { data, isLoading, isError } = getMotiviAssenza();
    const motivoAssenza = useRegole(state => state.motivoAssenza);
    const setMotivoAssenza = useRegole(state => state.setMotivoAssenza);

    return (
        <HStack justifyContent="space-between" mb="2">
            <Text fontSize="md" maxW="24" minW={"24"} fontWeight="600">Messaggio di Assenza</Text>
            <Menu
                rounded={"xl"}
                w="90%"
                placement='bottom left'
                trigger={(triggerProps) => {
                    return (
                        <Pressable  {...triggerProps}>
                            <HStack w="full" ml="auto">
                                <Text mx="2" w="70%" justifyContent={"center"} fontSize="md" fontWeight="400">{!motivoAssenza?.message ? "La persona cercata non è al momento disponibile" : "La persona cercata non è al momento disponibile perchè " + motivoAssenza?.message}</Text>
                                <Icon ml="auto"  as={<Ionicons name={"chevron-down"} />} color="dark" size="6" _dark={{
                                    color: "white"
                                }} />
                            </HStack>

                        </Pressable>
                    )
                }
                }>
                {data?.map((item: any, index: number) => (
                    <View key={index}>
                        <Menu.Item key={index} onPress={() => setMotivoAssenza(item)}>
                            {!item.message ? "Generico" : item.message}
                        </Menu.Item>
                        <Divider />
                    </View>
                ))}
            </Menu>

        </HStack>
    )
}