import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { colors } from '../ui/theme/generic-theme/colors';
import RecuperoPassword from '../screens/autenticazione/recupero-password/recupero-password';
import Login from '../screens/autenticazione/login';
import RecuperoPasswordSuccess from '../screens/autenticazione/recupero-password/recupero-password-success';
import CambioPasswordSuccess from '../screens/autenticazione/cambio-password/cambio-password-success';
import CambioPassword from '../screens/autenticazione/cambio-password/cambio-password';
import Registrazione from '../screens/autenticazione/registrazione/registrazione';
import RegistrazioneScegliServizio from '../screens/autenticazione/registrazione/registrazione-scegli-servizio';
import RegistrazioneSuccess from '../screens/autenticazione/registrazione/registrazione-success';

export type AuthStackParamList = {
  Login: undefined;
};

const Stack = createNativeStackNavigator<AuthStackParamList>();

export const AuthNavigator = () => {

  return (
    <Stack.Navigator
      // initialRouteName="Login"
      screenOptions={{
        headerShown: false,
      }}

    >
      <Stack.Screen
        name="Login"
        component={Login}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen name="Recupero Password" component={RecuperoPassword} options={{
        headerShown: false,
      }} />
      <Stack.Screen
        name="RecuperoPasswordSuccess"
        component={RecuperoPasswordSuccess}
        options={{
          headerShown: false,
        }}
      />
       <Stack.Screen
        name="Registrazione"
        component={Registrazione}
        options={{
          headerShown: false,
        }}
      />
        <Stack.Screen
        name="RegistrazioneScegliServizio"
        component={RegistrazioneScegliServizio}
        options={{
          headerShown: false,
        }}
      />
       <Stack.Screen
        name="RegistrazioneSuccess"
        component={RegistrazioneSuccess}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen name="CambioPassword" component={CambioPassword} options={{
        headerShown: false,

      }} />
      <Stack.Screen name="CambioPasswordSuccess" component={CambioPasswordSuccess} options={{
        headerShown: false,

      }} />
    </Stack.Navigator>
  );
};
