import React from "react";
import AppContainer from "./src/ui/components/layout/app-container";
import { RootNavigator } from "./src/navigation/";
import { hydrateAuth } from "./src/core/auth/auth-context";
import * as SplashScreen from "expo-splash-screen";
import { NavigationContainer } from "@react-navigation/native";


global._frameTimestamp = null
require("./src/assets/css/scrollbar.css")
// Prevent native splash screen from autohiding before App component declaration
SplashScreen.preventAutoHideAsync();

hydrateAuth();


export default function App() {


  return (
    <NavigationContainer
    >
      <AppContainer>
        <RootNavigator />
      </AppContainer>
    </NavigationContainer>
  );
}
