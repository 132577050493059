//Create a Bottom Sheet component with 2 numbers and a mail 
import React from 'react'
import { HStack, Text, VStack, Stack, Divider, Icon } from 'native-base';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import { Linking } from 'react-native';
import { useProfilo } from '../../core/store/profilo-store';
import { Modal } from 'native-base';
type Props = {
}

export default function Contattaci({ }: Props) {

    const { open_contattaci, onCloseContattaci } = useProfilo(state => state);

    const handleChiamaCommerciale = () => {
        Linking.openURL('tel:0400648888');
    }

    const handleChiamaCommercialeWhatsApp = () => {
        Linking.openURL('whatsapp://send?phone=3913049418');
    }

    const handleEmailCommerciale = () => {
        Linking.openURL('mailto:info@segreteriaremota.it')
    }

    return (
        <Modal isOpen={open_contattaci} onClose={onCloseContattaci}>
            <Modal.Content _dark={{ borderColor: "gray.800", borderWidth: 1, bg: "dark" }} p="4" maxWidth="600" maxH="800">
                <Modal.CloseButton
                    top="-3"
                    right="-3"
                />
                <VStack mx="4">
                    <Text fontSize={"3xl"} lineHeight={"sm"} fontWeight={"400"} color={"dark"} _dark={{color:"white"}}>
                        Contattaci
                    </Text>
                    <Text fontSize={"lg"} lineHeight={"sm"} fontWeight={"400"} color={"dark"} _dark={{color:"white"}}>
                        Hai bisogno di aiuto oppure vuoi segnalarci un problema? Contattaci!
                    </Text>
                </VStack>
                <Stack mx="4" my="6" space={4} >
                    <TouchableOpacity onPress={handleChiamaCommerciale}>
                        <HStack alignItems={"center"} space={2}>
                            <Icon as={<Ionicons name="ios-call-outline" />} size="3xl" color="success.600" />
                            <Text maxWidth={"80%"} fontSize={"2xl"} fontWeight={"400"} color={"dark"} _dark={{color:"white"}}>
                                040 064 88 88
                            </Text>
                        </HStack>
                    </TouchableOpacity>
                    <Divider />
                    <TouchableOpacity onPress={handleEmailCommerciale}>
                        <HStack alignItems={"center"} space={2}>
                            <Icon as={<Ionicons name="ios-mail-open-outline" />} size="3xl" color="success.600" />
                            <Text fontSize={"2xl"} fontWeight={"400"} color={"dark"} _dark={{color:"white"}}>
                                info@segreteriaremota.it
                            </Text>
                        </HStack>
                    </TouchableOpacity>
                    <Divider />
                    <TouchableOpacity onPress={handleChiamaCommercialeWhatsApp}>
                        <HStack alignItems={"center"} space={2}>
                            <Icon as={<Ionicons name="logo-whatsapp" />} size="3xl" color="success.600" />
                            <Text fontSize={"2xl"} fontWeight={"400"} color={"dark"} _dark={{color:"white"}}>
                                391 304 94 18
                            </Text>
                        </HStack>
                    </TouchableOpacity>
                </Stack>
            </Modal.Content>
        </Modal>
    )
}