export const convertDatasetToCron = (dataset: any) => {

    const giorni = dataset.giorni.map(
        (item) => convertGiornoToCron(item)
    ).join(",");
    const mesi = dataset.mesi.map(
        (item) => convertMeseToCron(item)
    ).join(",");

    const orario = dataset.enableOrario ? `${dataset.oraInizio.getHours()}:${dataset.oraInizio.getMinutes()}-${dataset.oraFine.getHours()}:${dataset.oraFine.getMinutes()}` : '*';

    return `${orario} * ${mesi.length === 0 ? "*" : mesi} ${giorni.length === 0 ? "*" : giorni}`;
}


const convertMeseToCron = (mese: string): string => {
    switch (mese) {
        case "Gennaio":
            return "1";
        case "Febbraio":
            return "2";
        case "Marzo":
            return "3";
        case "Aprile":
            return "4";
        case "Maggio":
            return "5";
        case "Giugno":
            return "6";
        case "Luglio":
            return "7";
        case "Agosto":
            return "8";
        case "Settembre":
            return "9";
        case "Ottobre":
            return "10";
        case "Novembre":
            return "11";
        case "Dicembre":
            return "12";
        default:
            return "";
    }
}

const convertGiornoToCron = (giorno: string): string => {
    switch (giorno) {
        case "Lunedì":
            return "1";
        case "Martedì":
            return "2";
        case "Mercoledì":
            return "3";
        case "Giovedì":
            return "4";
        case "Venerdì":
            return "5";
        case "Sabato":
            return "6";
        case "Domenica":
            return "0";
        default:
            return "";
    }
}