import React from 'react'
import { VStack, View, Text, HStack } from "native-base"
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel';
import Pagination from './pagination/Pagination';
import Animated, { useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { AnimatePresence } from 'moti';
import Header from './header/Header';
import { Platform, ScrollView } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useWindowDimensions } from 'react-native';

type Props = {
    data: any,
    width?: number,
    colorMode?: "light" | "dark" | null | undefined,
    isEnable?: boolean,
    enablePagination: boolean,
    titles: string[] | undefined,
    height?: string | number
}

export default function CarouselCustom({ data, width, colorMode, isEnable = true, height, titles }: Props) {

    const progressValue = useSharedValue(0);
    const r = React.useRef<ICarouselInstance>(null);
    const dimensions = useWindowDimensions();

    return (
        <VStack my="3" mx="auto" flex={1} height={"100%"} justifyContent={"space-between"}>
            <Carousel
                data={data}
                ref={r}
                renderItem={({ item, index }) => {
                    return (
                        <View style={{ width: width || 0, height: "100%" }}>
                            <Header index={index} PAGE_WIDTH={width} titles={item?.title} colorMode={colorMode} activeDotIndex={progressValue} />
                            {item?.data}
                        </View>
                    )
                }}
                width={width || 0}
                enabled={isEnable}
                onProgressChange={(_, absoluteProgress) =>
                    (progressValue.value = absoluteProgress)
                }
                panGestureHandlerProps={{
                    activeOffsetX: [-10, 10],
                }}
                loop={false}
                // enablePagination={true}
                style={{ flex: 1, minHeight: height }}
            />
            <AnimatePresence>
                {Platform.OS == "web" ?
                    <HStack space={4} maxW={data.length == 2 ? "20" : "24"} top={{
                        base: 7,
                        md: 0
                    }} position={"absolute"} right={"40"} alignItems={"center"} py="2" mx="auto" w="full" justifyContent={"space-evenly"}  >
                        {titles?.map(
                            (title, index) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => r.current?.scrollTo({
                                            index: index,
                                            animated: true
                                        })}>
                                        <Text textAlign={"center"} color={colorMode == "light" ? "dark" : "white"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"300"} fontSize={"md"} >
                                            {title}
                                        </Text>
                                    </TouchableOpacity>
                                )
                            }

                        )}
                    </HStack> :
                    <Pagination dotsLength={data.length} activeDotIndex={progressValue} />
                }
            </AnimatePresence>
        </VStack>
    )
}
