import React, { useEffect } from 'react'
import PieChart from './pie-chart';
import { useColorMode, Stack} from 'native-base';
import ProgressBar from './progress-bar';
import PacchettiAcquistati from './pacchetti_acquistati';
import { colors } from '../../../../ui/theme/generic-theme/colors';
import { Platform } from 'react-native';

type Props = {
    servizioSelezionato: any,
}

export default function Prepagato({ servizioSelezionato}: Props) {

    const [pieChartData, setPieChartData] = React.useState<any>([]);
    const [percentChiamateUtilizzate, setPercentChiamateUtilizzate] = React.useState<any>({percent: 0, data: [{ x: 1, y: 0 }, { x: 2, y: 100}]});
    const [percentChiamateDisponibili, setPercentChiamateDisponibili] = React.useState<any>({percent: 0, data: [{ x: 1, y: 0 }, { x: 2, y: 100}]});
    const { colorMode } = useColorMode();

    useEffect(() => {
        getData()
    }, [servizioSelezionato])

    const getData = () => {

        

        const chiamate_totali = servizioSelezionato.minuti_acquistati_mese_corrente + servizioSelezionato.minuti_inizio_mese;
        const chiamate_consumate = servizioSelezionato?.minuti_conversati_mese_corrente;
        const chiamate_residue = chiamate_totali - chiamate_consumate;

        // Get the data for the progress bar
        const percentUtilizzate = parseFloat(Math.ceil(chiamate_consumate / chiamate_totali * 100).toFixed(1));
        setPercentChiamateUtilizzate({percent: percentUtilizzate, data: [{ x: 1, y: percentUtilizzate }, { x: 2, y: 100 - percentUtilizzate}]})

        // Get the data for the progress bar
        const percentDisponibili = parseFloat(Math.floor(chiamate_residue / chiamate_totali * 100).toFixed(1));
        setPercentChiamateDisponibili({percent: percentDisponibili, data: [{ x: 1, y: percentDisponibili }, { x: 2, y: 100 - percentDisponibili}]})

        // Get the data for the pie chart
        setPieChartData([{
            x: 1, y: Math.ceil(chiamate_residue / chiamate_totali * 10), title: "Disponibili", data: chiamate_residue, color: colors.success['200'], chiamate_totali: chiamate_totali, colorPress: colors.success['400']
        }, {
            x: 2, y: Math.floor(chiamate_consumate / chiamate_totali * 10), title: "Consumate", data: chiamate_consumate, color:   "rgb(245, 176, 203)", chiamate_totali: chiamate_totali, colorPress: 
            colors.danger['400']
        }])

    }

    return (
        <Stack w="full" justifyContent={"space-between"} space={2}>
            <Stack flexDir={{
                base: "column",
                md: "row"
            }} justifyContent={"space-evenly"}>

                <Stack flexDir={{
                    base: "column",
                    md: "row"
                }}>

                    <PieChart chiamate_totali={servizioSelezionato.minuti_acquistati_mese_corrente+servizioSelezionato?.minuti_inizio_mese}  data={pieChartData} colorMode={colorMode} />


                    <Stack flexDir={{
                        base: "row",
                        md: "column"
                    }} justifyContent={"space-evenly"} >
                        <ProgressBar colorMode={colorMode} isConsumate data={percentChiamateUtilizzate} title="Consumate" />
                        <ProgressBar colorMode={colorMode} isConsumate={false} data={percentChiamateDisponibili} title="Disponibili" />
                    </Stack>

                </Stack>
               {/* {Platform.OS === "web" ? <Stack display={{
                    base: "none",
                    md: "flex"
                }}  mr={{
                    base: "0",
                    md: "10"
                }} >
                    <PacchettiAcquistati servizioSelezionato={servizioSelezionato} />
                </Stack> : ""} */}
            </Stack>
        </Stack>
    )
}