import * as React from "react"
import Svg, { SvgProps, Rect, Path } from "react-native-svg"
const Email = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <Rect
      width={416}
      height={320}
      x={48}
      y={96}
      fill="none"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={18}
      rx={40}
      ry={40}
    />
    <Path
      fill="none"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={18}
      d="m112 160 144 112 144-112"
    />
  </Svg>
)
export default Email
