import type { AxiosError } from "axios";
import { createQuery } from "react-query-kit";
import type { StatisticaResult } from "./types";
import auth from "../common/client";

type Response = StatisticaResult[] | undefined;

type Variables = {
    codice_servizio: string;
    data_inizio: string;
    data_fine: string;
    tipo_aggregazione: string;
    tipo_statistica: string;
};

export const getStatistiche = createQuery<Response, Variables, AxiosError>({
    primaryKey: "statistiche",
    enabled: (data,variables) => Boolean(variables.codice_servizio),
    queryFn: async ({ queryKey: [primaryKey, variables] }) => {
        return auth
            ?.get(
                `/api/statisticheRiassuntive/?codice_servizio=${variables.codice_servizio}&p_data_inizio=${variables.data_inizio}&p_data_fine=${variables.data_fine}&p_tipo_aggregazione=${variables.tipo_aggregazione}`
            )
            .then((res) => {
                const giorniSettimana = ["lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato", "domenica"];
                //For each day of the week, check if there is a value in the response, if not, add it with value 0
                let dataRes = giorniSettimana.map((giorno) => {
                    let value = res.data?.find((item: any) => item?.intervallo === giorno);
                    return {
                        x: giorno.slice(0, 3),
                        y: value ? parseInt(value?.numero_chiamate / value?.numero_giorni) : 0,
                    };
                });
                
                return dataRes;
            })
            .catch((err) => console.log(err));
    },
});


