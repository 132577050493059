import type { AxiosError } from "axios";
import { createQuery } from "react-query-kit";
import auth from "../common/client";
import type { Chiamate, ChiamateResult } from "./types";
import { createInfiniteQuery } from "react-query-kit";

type Response = ChiamateResult[] | undefined;

type Variables = {
    codice_servizio: string;
    limit: number;
    search: string | undefined;
    scopo: string | undefined;
  };

export const getChiamate = createQuery<Response, Variables, AxiosError>({
    primaryKey: "chiamate",
    enabled: (data,variables) => Boolean(variables.codice_servizio),
    queryFn: async ({ queryKey: [primaryKey, variables] }) => {
        return auth?.get(`/api/chiamate/?limit=${variables.limit}&ordering=-data_registrazione&codice_servizio=${variables.codice_servizio}`).then((response) => response.data?.results).catch((err) => console.log(err));
    }
});

// Infinite scroll with react-query
export const getChiamateInfinite = createInfiniteQuery<Response, Variables, AxiosError>({
    primaryKey: "chiamateInfinite",
    enabled: (data,variables) => Boolean(variables.codice_servizio),
    queryFn: async ({ queryKey: [primaryKey, variables], pageParam = 0 }) => {
        const searchValue = variables.search ? `&search=${variables.search}` : "";
        const scopoValue = variables.scopo ? `&scopo=${variables.scopo}` : "";
        return auth?.get(`/api/chiamate/?limit=${variables.limit}&offset=${pageParam}&ordering=-data_registrazione&codice_servizio=${variables.codice_servizio}${searchValue}${scopoValue}`).then((response) => response.data?.results).catch((err) => console.log(err));
    },
    getNextPageParam: (lastPage, pages) => {
        if (!lastPage || lastPage.length === 0) return false;
        return pages.length * lastPage.length;
    }
}
);



