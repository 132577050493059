import { Pressable, HStack, Text, Menu, Divider, Icon, } from 'native-base';
import React from 'react'
import { Platform } from 'react-native';
import { useDatiContatto } from '../../../../../../core/store/rubrica/dati-contatto/dati-contatto-store';
import { Ionicons } from '@expo/vector-icons';

const Priorita = ({
    enableEdit
}: {
    enableEdit: boolean,
}) => {
    const priorita = useDatiContatto(state => state.priorita);
    return (
        <HStack space={2} w="full" alignItems={"center"}  >

            <Text fontSize={"xl"} fontWeight="600"
                minW="20"
                color="dark" _dark={{ color: "white" }}>
                Priorità
            </Text>

            <Menu
                rounded={"xl"}
                w="100%"
                _dark={{ borderColor: "gray.800", borderWidth: 1,bg:"dark"  }} 
                minW={"150"}
                placement='bottom left'
                trigger={(triggerProps) => {
                    return (
                        <Pressable style={{ flex:1, maxWidth: "100%", paddingVertical: 8, paddingHorizontal: 12 }} disabled={!enableEdit}  {...triggerProps}>
                            <HStack >
                                <Text color={
                                    !enableEdit ? "muted.400" : "dark"
                                } _dark={{ color: !enableEdit ? "muted.400" : "white" }} mr="auto" lineHeight={"xs"} fontSize={"lg"} fontWeight="400">
                                    {priorita}
                                </Text>
                                {enableEdit && <Icon as={<Ionicons name={"chevron-down"} />} color="dark" size="6" _dark={{
                                    color: "white"
                                }} />}
                            </HStack>
                        </Pressable>
                    )
                }
                }>
                <Menu.Item
                    onPress={() => {
                        useDatiContatto.getState().setPriorita("Altissima")
                    }}
                >
                    <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Altissima</Text>
                </Menu.Item>
                <Divider />
                <Menu.Item
                    onPress={() => {
                        useDatiContatto.getState().setPriorita("Alta")
                    }}
                >
                    <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Alta</Text>
                </Menu.Item>
                <Divider />
                <Menu.Item
                    onPress={() => {
                        useDatiContatto.getState().setPriorita("Media")
                    }}
                >
                    <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Media</Text>
                </Menu.Item>
                <Divider />
                <Menu.Item
                    onPress={() => {
                        useDatiContatto.getState().setPriorita("Bassa")
                    }}
                >
                    <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Bassa</Text>
                </Menu.Item>
            </Menu>

        </HStack >
    )
}


export default React.memo(Priorita);