// Query per la rimozione di un nominativo

import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";


type Variables = {
    variables:{
        nominativo_id: number;
    }
  };

export const deleteNominativo = createMutation<void ,Variables, AxiosError>({
    mutationFn: async ({ variables }) => {
        return auth?.post(`/api/deleteRowNominativo`,{id: variables.nominativo_id}).then((response) => response.data).catch((err) => console.log(err));
    }
});