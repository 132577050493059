import type { AxiosError } from "axios";
import { createMutation, createQuery } from "react-query-kit";
import auth from "../../common/client";
import { NominativoResult } from "../types";


type Response = NominativoResult[];

type Variables = {
    id_servizio: string;
    search: string | undefined;
  };


export const getNominativi = createQuery<Response, Variables, AxiosError>({
    primaryKey: "nominativi",
    enabled: (data,variables) => Boolean(variables.id_servizio),
    queryFn: async ({ queryKey: [primaryKey, variables] }) => {
        const searchValue = variables.search ? `?contatto=${variables.search}` : "";
        return auth?.get(`/api/getNominativi/${variables.id_servizio}/${searchValue}`).then((response) => response.data).catch((err) => console.log(err));
    },
    
});