import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createSelectors } from "../utils";

export interface ProfiloStore {
    dati_profilo: any;
    setDatiProfilo: (data: any) => void;
    open_contattaci: boolean;
    onCloseContattaci: () => void;
    setContattaci: (value: boolean) => void;
}

const useProfiloStore = create<ProfiloStore>((set) => ({
    dati_profilo: null,
    setDatiProfilo: (data) => set({ dati_profilo: data }),
    open_contattaci: false,
    onCloseContattaci: () => set({ open_contattaci: false }),
    setContattaci: (value) => set({ open_contattaci: value }),

}));

export const useProfilo = createSelectors(useProfiloStore);