import React from 'react'
import BoxContainer from '../../../ui/components/layout/box-container'
import { ServizioResult } from '../../../api/servizi'
import { HStack } from 'native-base'
import Prepagato from './Prepagato'
import Consumo from './Consumo/index'


type Props = {
    servizioSelezionato: ServizioResult
}

export default function Consumi({ servizioSelezionato }: Props) {

    const isPrepagato = servizioSelezionato?.tipo_pacchetto?.match(/.*iria|prepagato|caren.*/) ? true : false;

    return (
        <BoxContainer>
            <HStack w="full" flexWrap={"wrap"}  justifyContent={"space-between"} space={2} alignItems={"center"}>
                {isPrepagato ? <Prepagato  servizioSelezionato={servizioSelezionato} />
                    : <Consumo  servizioSelezionato={servizioSelezionato} />}
            </HStack>
        </BoxContainer>
    )
}