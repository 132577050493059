import React from "react";
import {
    FormControl,
    Input,
    Box,
    Pressable,
    VStack,
    View,
    Heading,
    Center,
    Text,
    HStack,
    Icon,
    useColorMode
} from "native-base";
import { useForm, Controller } from "react-hook-form";
import { SafeAreaView } from "react-native-safe-area-context";
import { colors } from "../../../ui/theme/generic-theme/colors";
import { Ionicons } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { TouchableOpacity } from "react-native-gesture-handler";
type Props = {
    navigation: any;
};

export default function Registrazione({ navigation }: Props) {


    const { colorMode } = useColorMode();
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,

    } = useForm({
        defaultValues: {
            nome: "",
            cognome: "",
            email: "",
            telefono: "",
            preferenza: "Umano"
        },
    });

    const onSubmit = (data: any) => {
        navigation.navigate("RegistrazioneScegliServizio", {
            ...data,
            colorMode: colorMode
        });
    }



    return (
        <SafeAreaView style={{ width: "100%", height: "100%", backgroundColor: colorMode === "dark" ? colors.dark : "white" }}>
            <View flex={1} maxH={"98%"}>
                <KeyboardAwareScrollView style={{ flex: 1 }} enableOnAndroid={true} enableAutomaticScroll={true} keyboardOpeningTime={0} extraScrollHeight={100} contentContainerStyle={{
                    height: "100%",
                }} >
                    <Pressable onPress={() => navigation.goBack()}  >
                        <HStack mt="5" ml="5" alignItems={"center"} >
                            <Icon as={<Ionicons name={"arrow-back"} />} color="primary.400" size="6" _dark={{
                                color: "white"
                            }} />
                            <Text color="primary.400" fontWeight={"bold"} _dark={{
                                color: "white"
                            }} textAlign={"center"} fontSize={"md"} >Indietro</Text>
                        </HStack>
                    </Pressable>
                    <View flex={1} p="2" mt="2" mx="auto" minW={{
                        base: "90%",
                        md: "800"
                    }} maxW={"800"} paddingLeft={"5%"} maxH={{
                        base: "95%",
                        md: "90%"
                    }} paddingRight={"5%"} >
                        <Heading
                            _dark={{
                                color: "white"
                            }}
                            lineHeight={"xs"}
                            color="dark"
                            fontWeight={"800"}
                            fontSize={"4xl"}
                            mb="2"
                            fontFamily={"body"}
                            textAlign={"left"}
                        >
                            Registrazione
                        </Heading>
                        <Heading
                            _dark={{
                                color: "white"
                            }}
                            color="dark"
                            fontWeight={"500"}
                            fontSize={"md"}
                            lineHeight={"xs"}
                            fontFamily={"body"}
                            textAlign={"left"}
                        >
                            Dacci qualche informazione in più
                        </Heading>
                        <Center w="100%" mt="10">
                            <VStack w="full" space={3} >
                                <FormControl isInvalid={
                                    "nome" in errors
                                } colorScheme={"white"}>
                                    <FormControl.Label _text={{
                                        color: "dark",
                                        fontFamily: "body",
                                    }} _dark={{
                                        _text: {
                                            color: "white"
                                        }
                                    }} >Nome</FormControl.Label>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                placeholder="Inserisci il tuo nome"
                                                _focus={{
                                                    bg: "white",
                                                }}
                                                _light={{
                                                    placeholderTextColor: "blueGray.400",
                                                }}
                                                _dark={{
                                                    color: "dark",
                                                    bg: "white"
                                                }}
                                                h={55}
                                                w={{
                                                    base: "100%",
                                                    md: "100%"
                                                }}
                                                bg="white"
                                                rounded={"lg"}
                                            />
                                        )}
                                        name="nome"
                                        rules={{ required: true }}
                                    />
                                    <FormControl.ErrorMessage _text={{ color: "error.500", fontSize: "xs", fontWeight: 500 }}>
                                        {errors.nome?.message}
                                    </FormControl.ErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={
                                    "cognome" in errors
                                } colorScheme={"white"}>
                                    <FormControl.Label _dark={{
                                        _text: {
                                            color: "white"
                                        }
                                    }} _text={{
                                        color: "dark",
                                        fontFamily: "body",
                                    }} >Cognome</FormControl.Label>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                placeholder="Inserisci il tuo cognome"
                                                _focus={{
                                                    bg: "white",
                                                }}
                                                _light={{
                                                    placeholderTextColor: "blueGray.400",
                                                }}
                                                _dark={{
                                                    color: "dark",
                                                    bg: "white"
                                                }}
                                                h={55}
                                                w={{
                                                    base: "100%",
                                                    md: "100%"
                                                }}
                                                bg="white"
                                                rounded={"lg"}
                                            />
                                        )}
                                        name="cognome"
                                        rules={{ required: true }}
                                    />
                                    <FormControl.ErrorMessage _text={{ color: "error.500", fontSize: "xs", fontWeight: 500 }}>
                                        {errors.email?.message}
                                    </FormControl.ErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={
                                    "email" in errors
                                } colorScheme={"white"} >
                                    <FormControl.Label _dark={{
                                        _text: {
                                            color: "white"
                                        }
                                    }} _text={{
                                        color: "dark",
                                        fontFamily: "body",
                                    }} >Email</FormControl.Label>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                placeholder="Inserisci la tua email"
                                                _focus={{
                                                    bg: "white",
                                                }}
                                                _light={{
                                                    placeholderTextColor: "blueGray.400",
                                                }}
                                                _dark={{
                                                    color: "dark",
                                                    bg: "white"
                                                }}
                                                w={{
                                                    base: "100%",
                                                    md: "100%"
                                                }}
                                                h={55}
                                                bg="white"
                                                rounded={"lg"}
                                            />
                                        )}
                                        name="email"
                                        rules={{ required: true }}
                                    />
                                    <FormControl.ErrorMessage _text={{ color: "error.500", fontSize: "xs", fontWeight: 500 }}>
                                        {errors.password?.message}
                                    </FormControl.ErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={
                                    "telefono" in errors
                                } colorScheme={"white"} >
                                    <FormControl.Label _dark={{
                                        _text: {
                                            color: "white"
                                        }
                                    }} _text={{
                                        color: "dark",
                                        fontFamily: "body",
                                    }} >Telefono</FormControl.Label>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                placeholder="Inserisci la tuo numero di telefono"
                                                _focus={{
                                                    bg: "white",
                                                }}
                                                _light={{
                                                    placeholderTextColor: "blueGray.400",
                                                }}
                                                _dark={{
                                                    color: "dark",
                                                    bg: "white"
                                                }}
                                                w={{
                                                    base: "100%",
                                                    md: "100%"
                                                }}
                                                h={55}
                                                bg="white"
                                                rounded={"lg"}
                                            />
                                        )}
                                        name="telefono"
                                        rules={{ required: true }}
                                    />
                                    <FormControl.ErrorMessage _text={{ color: "error.500", fontSize: "xs", fontWeight: 500 }}>
                                        {errors.password?.message}
                                    </FormControl.ErrorMessage>
                                </FormControl>
                                <FormControl mx="1" colorScheme={"white"} >
                                    <FormControl.Label _dark={{
                                        _text: {
                                            color: "white"
                                        }
                                    }} _text={{
                                        color: "dark",
                                        fontFamily: "body",
                                    }} >La tua preferenza per il servizio</FormControl.Label>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => {
                                            return (
                                                <HStack space={1} _ios={{
                                                    space: 3
                                                }} >
                                                    <TouchableOpacity onPress={() => onChange("Umano")}>
                                                        <View rounded={"md"}  bg={value === "" || value === "Umano" ? "primary.500" : "gray.500"}>
                                                            <Text fontSize="lg" color={"white"} p="1">Umano</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity onPress={() => onChange("Intelligenza Artificiale")}>
                                                        <View rounded={"md"} bg={value === "Intelligenza Artificiale" ? "primary.500" : "gray.500"}>
                                                            <Text fontSize="lg" color={"white"} p="1">Intelligenza Artificiale</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity onPress={() => onChange("Ibdrido")}>
                                                        <View rounded={"md"}  bg={value === "Ibdrido" ? "primary.500" : "gray.500"}>
                                                            <Text fontSize="lg" color={"white"} p="1">Ibdrido</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </HStack>

                                            )
                                        }
                                        }
                                        name="telefono"
                                        rules={{ required: true }}
                                    />
                                </FormControl>
                            </VStack>
                        </Center>
                    </View>
                </KeyboardAwareScrollView>
                <View paddingLeft={"5%"} paddingRight={"5%"} p="2" maxW={"800"} mx="auto" minW={{
                    base: "100%",
                    md: "800"
                }}  >
                    <Pressable onPress={handleSubmit(onSubmit)} bg="primary.400" h="55" rounded={"md"} mt="auto" >
                        {({
                            isHovered,
                            isFocused,
                            isPressed
                        }) =>

                            <Center style={{
                                opacity: isHovered || isFocused || isPressed ? 0.8 : 1,
                                transform: isHovered || isFocused || isPressed ? [{ scale: 0.98 }] : [{ scale: 1 }]
                            }} flex={1}>
                                <Text color="white" fontWeight={"bold"} textAlign={"center"} fontSize={"lg"} >Avanti</Text>
                            </Center>
                        }
                    </Pressable>
                </View>
            </View>
        </SafeAreaView>
    );
}
