import React from 'react'
import { Stack } from 'native-base'
import { SafeAreaView } from 'react-native-safe-area-context'

type Props = {
    children: React.ReactNode
}

export default function BoxContainer({ children }: Props) {
    return (
            <Stack my={5} w={"90%"} mx="auto" space={2} justifyContent='center' alignItems={"center"} >
                {children}
            </Stack>

    )
}