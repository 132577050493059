import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const Voice = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" 
  viewBox="0 0 24 24"
  {...props}>
    <Path
      fill="none"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      d="M12 4v16M8 9v6m12-5v4M4 10v4m12-7v10"
    />
  </Svg>
)
export default Voice
