import React from 'react'
import { createStackNavigator } from '@react-navigation/stack';
import { TabNavigator } from '../mobile/tab-navigator';
import DrawerNavigator from '../web/drawer-navigator';
import { Platform } from 'react-native';
import CambioPassword from '../../screens/autenticazione/cambio-password/cambio-password';
import CambioPasswordSuccess from '../../screens/autenticazione/cambio-password/cambio-password-success';
import DettagliContattoNavigator from './rubrica/dettagli-contatto-navigator';
import RegolaNavigator from './rubrica/regola/regola-navigator';
import Chat from '../../screens/contattaci/chat/chat.native';

type Props = {}

const Stack = createStackNavigator();

export default function MainNavigator({ }: Props) {

    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
                gestureEnabled: false,
                // presentation: Platform.OS === "web" ? "modal" : "card",
            }}
        >
            <Stack.Screen name="App"  component={Platform.OS !== "web" ? TabNavigator : DrawerNavigator} />
            <Stack.Screen name="ContattoRubrica" component={DettagliContattoNavigator} />
            <Stack.Screen name="FormRegola" component={RegolaNavigator} />
            <Stack.Screen name="Chat" component={Chat} />
            <Stack.Screen
                name="Cambio Password"
                component={CambioPassword}
                options={{
                    headerShown: false,
                }}
            />
            <Stack.Screen
                name="CambioPasswordSuccess"
                component={CambioPasswordSuccess}
                options={{
                    headerShown: false,
                }}
            />
        </Stack.Navigator>
    )
}