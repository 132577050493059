import React from 'react'
import { Heading, HStack, View, Pressable, Text, Center, Box, VStack, Icon } from 'native-base'
import { Ionicons } from '@expo/vector-icons'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Image } from 'react-native'
import { Platform } from 'react-native'
import { colors } from '../../../ui/theme/generic-theme/colors'
import BaseLineCall from '../../../ui/svg/icons/baseline-call'
import Calendar from '../../../ui/svg/icons/calendar'
import PhoneForwarded from '../../../ui/svg/icons/phone-forwarded'
import Phone from '../../../ui/svg/icons/phone'
import PhoneIncoming from '../../../ui/svg/icons/phone-incoming'
import HourGlass from '../../../ui/svg/icons/hourglass'
import Voice from '../../../ui/svg/icons/voice'
import PhoneRinging from '../../../ui/svg/icons/phone-ringing'
import authRegistrazione from '../../../api/auth/registrazione/registrazione'
import BotIcon from '../../../ui/svg/icons/bot'

type Props = {
    navigation: any,
    route: any
}

export default function RegistrazioneScegliServizio({ navigation, route }: Props) {

    // const [selected, setSelected] = React.useState("")

    const handleSubmit = async () => {
        navigation.navigate("RegistrazioneSuccess", {
            ...route.params,
        })
        // Chiamata API per registrazione
        const registrazione  = await authRegistrazione({
            nome: route.params?.nome,
            cognome: route.params?.cognome,
            email: route.params?.email,
            telefono: route.params?.telefono,
            preferenza: route.params?.preferenza,
        });
    }


    return (
        <SafeAreaView style={{
            width: "100%", height: "100%", backgroundColor:
                route?.params?.colorMode === "dark" ? colors.dark : "white"
        }}>
            <View flex={1} bg="white" _dark={{
                bg: "dark"
            }} maxH={"98%"}>
                <Pressable onPress={() => navigation.goBack()}  >
                    <HStack mt="5" ml="5" alignItems={"center"} >
                        <Icon as={<Ionicons name={"arrow-back"} />} color="primary.400" size="6" _dark={{
                            color: "white"
                        }} />
                        <Text color="primary.400" fontWeight={"bold"} _dark={{
                            color: "white"
                        }} textAlign={"center"} fontSize={"md"} >Indietro</Text>
                    </HStack>
                </Pressable>
                <View minW={{
                    base: "90%",
                    md: "800"
                }} flex={1} p="2" mt="2" maxW={"800"} mx="auto" paddingLeft={"5%"} maxH={{
                    base: "95%",
                    md: "90%"
                }} paddingRight={"5%"} >
                    <Heading
                        lineHeight={"xs"}
                        color="dark"
                        fontWeight={"800"}
                        fontSize={"4xl"}
                        mb="2"
                        fontFamily={"body"}
                        textAlign={"left"}
                        _dark={{
                            color: "white"
                        }}
                    >
                        Ciao {route.params?.nome}
                    </Heading>
                    <Heading
                        color="dark"
                        fontWeight={"500"}
                        fontSize={"md"}
                        lineHeight={"xs"}
                        fontFamily={"body"}
                        textAlign={"left"}
                        _dark={{
                            color: "white"
                        }}
                    >
                        Ecco qualche informazione in più sul servizio Segreteria Remota
                    </Heading>
                    <Center w="100%" mt="10">
                        <VStack space={2} alignItems={"center"} >
                            <Box maxW="95%" borderColor={"primary.400"} mx="auto" borderWidth={"1"} w="100%" bg={"primary.400"} shadow={"2xl"} rounded={"md"} p="5" >
                                <HStack space={2} mb="4" alignItems={"center"} >
                                    <View mb="auto" minW={90} minH={90} bg="white" alignSelf={"center"} justifyContent={"center"} rounded={"full"} >
                                        <Image source={require("../../../assets/logo-segreteria.png")} resizeMode='contain' style={{
                                            width: 90,
                                            height: 90,
                                        }} />
                                    </View>
                                    <Heading
                                        color={"white"}
                                        fontWeight={"bold"}
                                        fontSize={"3xl"}
                                        lineHeight={"xs"}
                                        maxW={"80%"}
                                        fontFamily={"body"}
                                        textAlign={"left"}
                                    >
                                        Segreteria Remota
                                    </Heading>
                                </HStack>
                                <VStack space={6} >
                                    {/* Section 1 */}
                                    <HStack alignItems="center" space={2}>
                                        <PhoneRinging color={"#fff"} width="50" height={"50"} />
                                        <Text
                                            maxW={Platform.OS === "web" ? { base: "25%", sm: "35%", md: "50%" } : "85%"}
                                            color={"white"}
                                            fontWeight={"500"}
                                            fontSize={"md"}
                                            lineHeight={"xs"}
                                            fontFamily={"body"}
                                            textAlign={"left"}
                                        >
                                            Gestiamo noi tutte le tue chiamate in entrata e in uscita
                                        </Text>
                                    </HStack>

                                    {/* Section 2 */}
                                    <HStack alignItems="center" space={2}>
                                        <HourGlass color={"#fff"} width="50" height={"50"} />
                                        <Text
                                            maxW={Platform.OS === "web" ? { base: "25%", sm: "35%", md: "50%" } : "85%"}
                                            color={"white"}
                                            fontWeight={"500"}
                                            fontSize={"md"}
                                            lineHeight={"xs"}
                                            fontFamily={"body"}
                                            textAlign={"left"}
                                        >
                                            La tua Segreteria Remota è attiva dal lunedì al sabato dalle 08 alle 22, estendibile anche ai festivi e alla notte.
                                        </Text>
                                    </HStack>

                                    {/* Section 3 */}
                                    <HStack alignItems="center" space={2}>
                                        <Voice color={"#fff"
                                        } width="50" height={"50"} />
                                        <Text
                                            maxW={Platform.OS === "web" ? { base: "25%", sm: "35%", md: "50%" } : "85%"}
                                            color={"white"}
                                            fontWeight={"500"}
                                            fontSize={"md"}
                                            lineHeight={"xs"}
                                            fontFamily={"body"}
                                            textAlign={"left"}
                                        >
                                           Puoi attivare dei messaggi personalizzati di Fuori Orario, Attesa in coda e Benvenuto 
                                        </Text>
                                    </HStack>

                                    
                                    {/* Section 4 */}
                                    <HStack alignItems="center" space={2}>
                                        <Calendar color={"#fff"
                                        } width="50" height={"50"} />
                                        <Text
                                            maxW={Platform.OS === "web" ? { base: "25%", sm: "35%", md: "50%" } : "85%"}
                                            color={"white"}
                                            fontWeight={"500"}
                                            fontSize={"md"}
                                            lineHeight={"xs"}
                                            fontFamily={"body"}
                                            textAlign={"left"}
                                        >
                                             Puoi monitorare tutti gli appuntamenti dalla tua app 
                                        </Text>
                                    </HStack>

                                    {/* Section 5 */}
                                    <HStack alignItems="center" space={2}>
                                        <BotIcon color={"#fff"} width="50" height={"50"} />
                                        <Text
                                            maxW={Platform.OS === "web" ? { base: "25%", sm: "35%", md: "50%" } : "85%"}
                                            color={"white"}
                                            fontWeight={"500"}
                                            fontSize={"md"}
                                            lineHeight={"xs"}
                                            fontFamily={"body"}
                                            textAlign={"left"}
                                        >
                                            Integrazione con Intelligenza Artificiale
                                        </Text>
                                    </HStack>

                                </VStack>
                            </Box>
                        </VStack>
                    </Center>
                    <Pressable onPress={handleSubmit} bg="primary.400" h="55" rounded={"md"} mt="auto" >
                        {({
                            isHovered,
                            isFocused,
                            isPressed
                        }) =>

                            <Center style={{
                                opacity: isHovered || isFocused || isPressed ? 0.8 : 1,
                                transform: isHovered || isFocused || isPressed ? [{ scale: 0.98 }] : [{ scale: 1 }]
                            }} flex={1}>
                                <Text color="white" fontWeight={"bold"} textAlign={"center"} fontSize={"lg"} >Richiedi Attivazione</Text>
                            </Center>
                        }
                    </Pressable>
                </View >
            </View >
        </SafeAreaView >
    )
}