
export const colors = {
  white: "#ffffff",
  dark: "#22272E",
  primary: {
    "50": "#009afa",
    "100": "#0085d8",
    "200": "#026fb4",
    "300": "#05598e",
    "400": "#04446c",
    "500": "#063756",
    "600": "#062b41",
    "700": "#061e2d",
    "800": "#04121a",
    "900": "#020609",
  },
  secondary: {
    "50": "#ffa585",
    "100": "#ff8c62",
    "200": "#ff7340",
    "300": "#fc5b21",
    "400": "#f74504",
    "500": "#dc4209",
    "600": "#c33e0e",
    "700": "#ab3b12",
    "800": "#943614",
    "900": "#7e3115",
  },
  warning: {
    "50": "#fff3c6",
    "100": "#ffe8a3",
    "200": "#ffdd80",
    "300": "#ffd25d",
    "400": "#ffc73b",
    "500": "#f9b92c",
    "600": "#e0a629",
    "700": "#c89626",
    "800": "#b08523",
    "900": "#97741f",
  },
  danger: {
    "50": "#ffdedc",
    "100": "#ffbeb9",
    "200": "#ff9e97",
    "300": "#fe7e75",
    "400": "#fe5f55",
    "500": "#f94f43",
    "600": "#f34235",
    "700": "#ec3528",
    "800": "#e3291c",
    "900": "#cb2b20",
  },
  info: {
    "50": "#cdd5e5",
    "100": "#bac3d6",
    "200": "#a8b1c5",
    "300": "#98a1b3",
    "400": "#848fa5",
    "500": "#7e8695",
    "600": "#7a7e85",
    "700": "#767675",
    "800": "#716d65",
    "900": "#6c6456",
  },
  success: {
    "50": "#c6f6df",
    "100": "#abefce",
    "200": "#91e5bd",
    "300": "#7adbac",
    "400": "#60d39c",
    "500": "#55c991",
    "600": "#4cbe87",
    "700": "#48ad7c",
    "800": "#489972",
    "900": "#468667",
  },
  error: {
    "50": "#ffdedc",
    "100": "#ffbeb9",
    "200": "#ff9e97",
    "300": "#fe7e75",
    "400": "#fe5f55",
    "500": "#f94f43",
    "600": "#f34235",
    "700": "#ec3528",
    "800": "#e3291c",
    "900": "#cb2b20",
  },
  neutral: {
    "50": "#cad9e8",
    "100": "#b5c8da",
    "200": "#a2b6cb",
    "300": "#90a5ba",
    "400": "#7a93ac",
    "500": "#758a9f",
    "600": "#6f8090",
    "700": "#6d757e",
    "800": "#696b6d",
    "900": "#64615e",
  },
  gray: {
    "10": "rgb(242, 242, 242)",
    "50": "#f5f5f5",
    "100": "#ebebeb",
    "200": "#e1e1e1",
    "300": "#d7d7d7",
    "400": "#cdcdcd",
    "500": "#c3c3c3",
    "600": "#b9b9b9",
    "700": "#afafaf",
    "800": "#a5a5a5",
    "900": "#9b9b9b",
  },
  purple:{
    "50": "#E2D9ED",
    "100": "#DBCBE5",
    "200": "#D7BCDD",
    "300": "#D5AED5",
    "400": "#CDA0C4",
    "500": "#C492B1",
    "600": "#C492B1",
    "700": "#AC7F91",
    "800": "#946C74",
    "900": "#7C5A5A",
  }
};
