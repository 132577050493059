import { FlatList, Image, Text, Divider, HStack, VStack } from 'native-base'
import React from 'react'
import { RectButton } from 'react-native-gesture-handler'
import { ServizioResult } from '../../../api/servizi'
import { getServizioIcon } from '../../../utils/get-icons'

type Props = {
    servizi: ServizioResult[] | undefined,
    onPress: (servizio: ServizioResult) => void,
    navigation: any
}

export default function ServizioModal({ servizi, onPress, navigation }: Props) {

    const onServizioPress = (servizio: ServizioResult) => {
        navigation.goBack();
        onPress(servizio);
    }
    return (
        <FlatList
            contentContainerStyle={{ paddingBottom: 100 }}
            data={servizi}
            ItemSeparatorComponent={() => <Divider />}
            renderItem={({ item, index }) => (
                <RectButton onPress={() => onServizioPress(item)}>
                    <HStack maxW={"75%"} alignItems={"center"}>
                        {getServizioIcon(item.tipo_pacchetto, 80, 80)}
                        <VStack >
                            <Text fontSize={"lg"}  fontWeight={"600"} lineHeight={"xs"} fontFamily={"body"}>{item.descrizione}</Text>
                            <Text fontSize={"sm"} fontWeight={"400"} fontFamily={"body"}>{"Codice Servizio: "+item.codice_servizio}</Text>
                        </VStack>
                    </HStack>
                </RectButton>
            )}
            h="100%"
            keyExtractor={(_item, index) => `${index}`}
        />
    )
}