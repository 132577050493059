import React from 'react'
import {
    HStack,
    Input,
    Text,
} from 'native-base';
import { useDatiContatto } from '../../../../../../core/store/rubrica/dati-contatto/dati-contatto-store';
import List from '../../../../../../ui/components/list/list';

export const EmailCC = ({
    enableEdit
}: {
    enableEdit: boolean
}) => {
    const emailCC = useDatiContatto(state => state.emailCC);
    const setEmailCC = useDatiContatto(state => state.setEmailCC);

    return (
        <HStack space={2} w="full"  alignItems={"center"} >

            <Text fontSize={"xl"} fontWeight="600"
                minW="20"
                color="dark" _dark={{ color: "white" }}>
                Email CC
            </Text>

           <List textContentType={"emailAddress"} autoCapitalize={"none"} placeholder="Indirizzo Email CC" data={emailCC} setter={setEmailCC} enableEdit={enableEdit} />

        </HStack>
    )
}


export default React.memo(EmailCC);