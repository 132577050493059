import { VStack, Text, Stack, HStack, Skeleton } from 'native-base'
import React from 'react'
import { Ionicons } from '@expo/vector-icons';
import { colors } from '../../../../ui/theme/generic-theme/colors';
import { Platform } from 'react-native';

type Props = {
    date: string;
    calls: number | undefined;
    percentage: string | undefined;
    textPercentage: string;
    unitMeaseure: string;
    isLoading: boolean;
    isError: boolean;
    green : string;
    red : string;
}

export default function Statistica({ date, calls, percentage, textPercentage, unitMeaseure,isLoading,isError, green,red }: Props) {


    const signRender = () => {

        if (!percentage) return (<></>)

        if (percentage.includes("-")) {
            return (
                <Stack alignItems={Platform.OS === "web" ? "center" : "flex-start"} justifyContent={"flex-start"} flexDir={Platform.OS === "web" ? "row" : "column"}>
                    <HStack justifyContent={"flex-start"} alignItems={"center"}>
                        <Ionicons name="ios-arrow-down" size={20} md="auto" color={red} />
                        <Text color={red} fontFamily={"body"} fontWeight={"600"} lineHeight={"xs"} fontSize={"md"}>{percentage + "%"}</Text>
                    </HStack>
                    <Text color="info.800" fontFamily={"body"} fontWeight={"300"} ml="1" lineHeight={"xs"} fontSize={"md"}>{textPercentage}</Text>
                </Stack>
            )
        } else {
            return (
                <Stack alignItems={Platform.OS === "web" ? "center" : "flex-start"} flexDir={Platform.OS === "web" ? "row" : "column"}>
                    <HStack alignItems={"center"}>
                        <Ionicons name="ios-arrow-up" size={20} md="auto" color={green} />
                        <Text color={green} fontFamily={"body"} fontWeight={"600"} fontSize={"md"}>{percentage + "%"}</Text>
                    </HStack>
                    <Text ml="1" color="info.800" fontFamily={"body"} fontWeight={"300"} fontSize={"md"}>{textPercentage}</Text>
                </Stack>
            )
        }
    }

    if(isLoading || isError) return (
        <VStack minH={"32"} space={{
            base: 2,
            md: 0,
        }} justifyContent={"space-between"} w="50%" alignItems={"flex-start"}>
            <Skeleton height={5} width={{
                base: 12,
                md: 20,
            }} rounded={"2xl"} />
            <Skeleton h={36} width={{
                base: 24,
                md: 48,
            }} rounded={"2xl"} />
            <Skeleton height={5} width={{
                base: 8,
                md: 12
            }} rounded={"2xl"} />
        </VStack>
            )

    return (
        <VStack minHeight={"24"}  space={{
            base: 2,
            md: 0,
        }} justifyContent={"space-between"} w="50%" alignItems={"flex-start"}>
            <Text fontFamily={"body"} fontWeight={"400"} fontSize={"sm"}  color="dark" _dark={{ color: "white" }} >
                {date}
            </Text>

            <HStack alignItems={"flex-end"} space={1}>
                <Text fontFamily={"body"} lineHeight={"xs"} fontWeight={"700"} fontSize={{
                    base: "4xl",
                    md: "4xl",
                    lg: "4xl",
                }} color="dark"  _dark={{ color: "white" }}>
                    {calls}
                </Text>
            </HStack>
            {signRender()}
        </VStack>
    )
}