import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const Mute = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <Path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={22}
      d="M432 400 96 64"
    />
    <Path
      fill="currentColor"
      d="M400 240v-31.55c0-8.61-6.62-16-15.23-16.43A16 16 0 0 0 368 208v32a111.58 111.58 0 0 1-2.45 23.31 4.05 4.05 0 0 0 1.07 3.69l21.82 21.81a2 2 0 0 0 3.29-.72A143.27 143.27 0 0 0 400 240ZM256 352a112.36 112.36 0 0 1-112-112v-31.55c0-8.61-6.62-16-15.23-16.43A16 16 0 0 0 112 208v32c0 74 56.1 135.12 128 143.11V432h-47.55c-8.61 0-16 6.62-16.43 15.23A16 16 0 0 0 192 464h127.55c8.61 0 16-6.62 16.43-15.23A16 16 0 0 0 320 432h-48v-48.89a143.08 143.08 0 0 0 52-16.22 4 4 0 0 0 .91-6.35L307 342.63a4 4 0 0 0-4.51-.78A110.78 110.78 0 0 1 256 352Zm0-272a47.18 47.18 0 0 1 48 48v74.72a4 4 0 0 0 1.17 2.82L332.59 233a2 2 0 0 0 3.41-1.42V128.91C336 85 301 48.6 257.14 48a79.66 79.66 0 0 0-68.47 36.57 4 4 0 0 0 .54 5l19.54 19.54a2 2 0 0 0 3.25-.63A47.44 47.44 0 0 1 256 80Z"
    />
    <Path
      fill="currentColor"
      d="M207.27 242.9 179.41 215a2 2 0 0 0-3.41 1.42V239a80.89 80.89 0 0 0 23.45 56.9 78.55 78.55 0 0 0 77.8 21.19 2 2 0 0 0 .86-3.35l-24.91-24.91a4.08 4.08 0 0 0-2.42-1.15c-21.65-2.52-39.48-20.44-42.37-42.43a4 4 0 0 0-1.14-2.35Z"
    />
  </Svg>
)
export default Mute
