import { Box, Pressable, ScrollView, Text } from 'native-base'
import { getScopi } from '../../api/statistiche-scopi'
import { useAnimatedStyle, useSharedValue } from 'react-native-reanimated'
import { MotiView } from 'moti'
import { Platform } from 'react-native'


type Props = {
    servizioSelezionato: any,
    setScopo: (val: string) => void,
    scopoSelezionato: string
}

export default function FiltroScopi({
    servizioSelezionato,
    scopoSelezionato,
    setScopo
}: Props) {

    const { data: scopi } = getScopi({
        variables: {
            codice_servizio: servizioSelezionato?.codice_servizio,
            data_inizio: "2021-01-01",
            data_fine: new Date().toISOString().split("T")[0]
        },
    })




    return (
        <ScrollView 
        maxH={"16"} 
        mb="3" 
        mt="2" 
        _web={{
            rounded: "full",
            maxW: "98%"
        }}
        mx={{
            base: "auto",
            sm: "10",
            md: "20"
        }}
        horizontal
        bg="gray.10" 
        contentContainerStyle={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: 5
        }}>
            <Pressable onPress={() => setScopo("")}>
                <Box p="2" minH={"10"} rounded={"3xl"} mx="2" my="2">
                    <Text color={
                        scopoSelezionato == "" ? "primary.400" : "dark"
                    } fontSize={scopoSelezionato == "" ? "md" : "sm"} fontWeight={scopoSelezionato == "" ? "bold" : "400"}>Tutte</Text>
                </Box>
            </Pressable>
            {
                scopi?.map((item: any, index: number) => {
                    return (
                        <Pressable key={index} onPress={() => setScopo(item.scopo)}>
                            <MotiView from={{
                                opacity: 0,
                                translateY: 50,
                            }}
                                animate={{
                                    opacity: 1,
                                    translateY: 0,
                                }}
                                transition={{
                                    type: "timing",
                                    duration: 800,
                                    delay: index * 100
                                }}
                                style={{
                                    backgroundColor: "transparent",
                                    width: "100%",
                                    borderRadius: 50,
                                    margin: 7,
                                    padding: 5

                                }}
                            >
                                <Text textAlign={"center"} color={
                                    item.scopo == scopoSelezionato ? "primary.400" : "dark"
                                } fontSize={item.scopo == scopoSelezionato ? "md" : "sm"} fontWeight={item.scopo == scopoSelezionato ? "bold" : "400"}>{(item.scopo).trim()}</Text>
                            </MotiView>
                        </Pressable>
                    )
                })
            }
        </ScrollView>
    )
}