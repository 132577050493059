import { HStack, Text, VStack, useColorMode } from 'native-base'
import React from 'react'
import { useServizio } from '../../core/store/servizio-store'
import { getServizioIcon } from '../../utils/get-icons'
import MessaggiAudio from './messaggi-audio'
import DatiGenerali from './dati-generali'
import CarouselCustom from '../../ui/components/carousel-custom/Carousel'
import { Platform, useWindowDimensions } from 'react-native'
import PacchettiAcquistati from './pacchetti-acquistati'
import AppContentContainer from '../../ui/components/layout/app-content'
import { replaceUnderscoreWhitespaceToUppercaseAndRemoveNumbers } from '../../utils/utils'

type Props = {}

export default function Servizi({ }: Props) {

  const servizioSelezionato = useServizio(state => state.servizio)
  const dimensions = useWindowDimensions();
  const { colorMode } = useColorMode()

  const DATA = [{ title: "Dati Generali", data: <DatiGenerali  colorMode={colorMode} servizioSelezionato={servizioSelezionato} /> }, { title: "Messaggi Audio", data: <MessaggiAudio  servizioSelezionato={servizioSelezionato} colorMode={colorMode} /> }, {
    title: "Pacchetti Acquistati", data: <PacchettiAcquistati  dataSet={servizioSelezionato.pacchetti_acquistati.reverse()}  servizioSelezionato={servizioSelezionato} />
  }]

  return (
    <AppContentContainer>
      <HStack mx="4" my="4"  space={2} alignItems={"center"}>
        {getServizioIcon(servizioSelezionato?.tipo_pacchetto, 100, 100)}
        <VStack w="full" >
          <Text fontFamily={"body"} textAlign={"left"} maxW={"65%"} fontWeight={"400"} fontSize={"3xl"} lineHeight={"sm"} _web={{
            _dark: {
              color: "white"
            }
          }} color="dark">{
              servizioSelezionato?.descrizione
            }</Text>
          <Text color="dark" lineHeight={"md"} _web={{
            _dark: {
              color: "white"
            }
          }} fontSize={"md"}>Codice Servizio: {servizioSelezionato?.codice_servizio}</Text>
              <Text color="dark" lineHeight={"md"}
            _dark={{
              color: "white"
            }} fontSize={"md"}>{replaceUnderscoreWhitespaceToUppercaseAndRemoveNumbers(servizioSelezionato?.tipo_pacchetto)}</Text>
        </VStack>
      </HStack>
     <CarouselCustom titles={["Dati Generali", "Messaggi Audio", "Pacchetti Acquistati"]} height={"70vh"} enablePagination={true} colorMode={colorMode} width={dimensions.width > 1200 ? 1000 : dimensions.width > 768 && dimensions.width < 1200 ? dimensions.width - 200 : dimensions.width - 40} data={DATA} />
    </AppContentContainer>
  )
}