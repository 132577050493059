import React from 'react'
import { getChiamate } from '../../../api/chiamate';
import BoxContainer from '../../../ui/components/layout/box-container';
import { Divider, FlatList, useColorMode } from 'native-base';
import Chiamata from './Chiamata';
import { ServizioResult } from '../../../api/servizi';
import { ChiamateResult } from '../../../api/chiamate';

type Props = {
  servizioSelezionato: ServizioResult,
}

export default function Chiamate({ servizioSelezionato }: Props) {

  const { colorMode, toggleColorMode } = useColorMode()
  const { data: chiamate, isLoading, isError } = getChiamate({
    variables: {
      search: "",
      scopo: "",
      codice_servizio: servizioSelezionato?.codice_servizio,
      limit: 12
    }
  });
  


  return (
    <BoxContainer >
      <FlatList
        w="100%"
        overflowX={"auto"}
        maxW={"100%"}
        horizontal
        data={isLoading || isError ? null : chiamate as ArrayLike<ChiamateResult>}
        renderItem={({ item }) => <Chiamata isLoading={isLoading} isError={isError} colorMode={colorMode} item={item} />}
        keyExtractor={(item) => item?.id?.toString()}
        ItemSeparatorComponent={() => <Divider h="70%" orientation={'vertical'} alignSelf={"center"} />}
        decelerationRate={"fast"}
        snapToInterval={285}
      />
    </BoxContainer>
  )
}
