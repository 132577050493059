import React from "react";
import { HStack, Text, Switch, VStack } from "native-base";
import { useDatiContatto } from "../../../../../../../core/store/rubrica/dati-contatto/dati-contatto-store";
import { useTrasferimenti } from "../../../../../../../core/store/rubrica/trasferimenti/trasferimenti-store";

const TrasferimentoSms = ({
    editSezioneContatto
}: {
    editSezioneContatto: boolean | undefined,
}) => {

    const trasferimentoSms = useTrasferimenti(state => state.trasferimentoSms);
    const telefono = useDatiContatto(state => state.telefono);
    const setTrasferimentoSms = useTrasferimenti(state => state.setTrasferimentoSms);

    return (
      <HStack space={2} alignItems={"center"} w="full" >
        <VStack maxW={"90%"} space={2} w="full" >
          <Text fontSize={"xl"} fontWeight="600" minW="24" color="dark" _dark={{ color: "white" }}>
            SMS
          </Text>
          <Text  lineHeight={"xs"} fontSize={"lg"} fontWeight="400" color="dark" _dark={{ color: "white" }}>
            {trasferimentoSms ? telefono : "Non inviare"}
          </Text>
        </VStack>
        <Switch ml="auto"  mx="2"  size="md" colorScheme="success" isChecked={trasferimentoSms} onToggle={(val) => setTrasferimentoSms(val)} isDisabled={!editSezioneContatto} />
      </HStack>
    )
  }

  export default React.memo(TrasferimentoSms);