import { Divider, Text, VStack, Spinner, Stack } from 'native-base'
import React from 'react'
import { ServizioResult } from '../../../../api/servizi'
import { Platform } from 'react-native'
import { getStatisticheLight } from '../../../../api/statistiche-chiamate'
import AnimatedLinearChart from '../../../../ui/components/animated-linear-chart'

type Props = {
    servizioSelezionato: ServizioResult
}

export default function Consumo({ servizioSelezionato}: Props) {

    const { data: statistiche, isLoading: isLoadingStatistiche, isError: isErrorStatistiche } = getStatisticheLight({
        variables: {
            codice_servizio: servizioSelezionato?.codice_servizio
        }
    });


    if (isLoadingStatistiche || isErrorStatistiche) {
        return (
            <Spinner />
        )
    }


    return (
        <Stack flexDir={{
            base: "column",
            md: "row"
        }} w="full" justifyContent={"space-between"} space={4}   >
            <VStack alignItems={"center"} justifyContent={"center"} py="2" flex={1}  >
                <Text textAlign={"center"} color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"300"} fontSize={"xl"} >
                    {new Date().toLocaleString('it-IT', { month: 'long' }).charAt(0).toUpperCase() + new Date().toLocaleString('it-IT', { month: 'long' }).slice(1)}
                </Text>
                <Text textAlign={"center"} color={"dark"} _dark={{ color: "white" }} mb="6" lineHeight={"xs"} fontWeight={"400"} fontSize={"4xl"} >
                    {servizioSelezionato?.minuti_conversati_mese_corrente} Minuti
                </Text>
                <AnimatedLinearChart isScaglioni={servizioSelezionato?.tipo_pagamento === "consumo-scaglione"} value={servizioSelezionato?.minuti_conversati_mese_corrente} />
                {servizioSelezionato?.tipo_pagamento === "consumo-scaglione" && <Text textAlign={"center"} py="2" color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"300"} fontSize={"lg"} >
                    {servizioSelezionato?.minuti_conversati_mese_corrente <= 100 ? "Fascia Bassa (0 - 100 Minuti)" : servizioSelezionato?.minuti_conversati_mese_corrente <= 300 ? "Fascia Media (100 - 300 Minuti)" : "Fascia Alta (+300 Minuti)"}
                </Text>}
            </VStack>
            {servizioSelezionato?.tipo_pagamento === "consumo-scaglione" && Platform.OS !== "web" && <Divider variant={"horizontal"} />}
            {isLoadingStatistiche || isErrorStatistiche ? <Spinner /> : <VStack alignItems={"center"} justifyContent={"center"} py="2" space={2} flex={1}  >
                <Stack maxW={{
                    base: "full",
                    md: "full"
                }} space={2} flexDir={"row"} w="full" alignItems={"center"} justifyContent={"space-evenly"} >
                    <VStack space={1} justifyContent={"center"}  >
                        <Text textAlign={"left"} color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"300"} fontSize={"md"} >
                            Oggi
                        </Text>
                        <Text textAlign={"left"} color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"400"} fontSize={"2xl"} >
                            {(statistiche?.oggi.secondi / 60).toFixed(0) + " Minuti"}
                        </Text>
                    </VStack>
                    <Divider w="0.6" h="full" variant={"vertical"} />
                    <VStack space={1} justifyContent={"center"}  >
                        <Text textAlign={"right"} color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"300"} fontSize={"md"} >
                            Questa Settimana
                        </Text>
                        <Text textAlign={"right"} color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"400"} fontSize={"2xl"} >
                            {(statistiche?.settimana.secondi / 60).toFixed(0) + " Minuti"}
                        </Text>
                    </VStack>
                </Stack>
                {Platform.OS === "web" && <>
                    <Divider variant={"horizontal"} />
                    <VStack space={1} justifyContent={"center"}  >
                        <Text textAlign={"right"} color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"300"} fontSize={"md"} >
                            Scorso Mese
                        </Text>
                        <Text textAlign={"right"} color={"dark"} _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"400"} fontSize={"2xl"} >
                            {(statistiche?.scorso_mese.secondi / 60).toFixed(0) + " Minuti"}
                        </Text>
                    </VStack>
                </>}
            </VStack>}
        </Stack>
    )
}