import React from "react";
import {
    FormControl,
    Input,
    Box,
    Pressable,
    VStack,
    View,
    Heading,
    Center,
    Text,
    HStack,
    useColorMode,
    Icon
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { useForm, Controller } from "react-hook-form";
import { SafeAreaView } from "react-native-safe-area-context";
import authCambioPassword from "../../../api/auth/cambio-password/cambio-password";
import { useProfilo } from "../../../core/store/profilo-store";
import authConfermaRecuperoPassword from "../../../api/auth/recupero-password/conferma-recupero-password";
import { colors } from "../../../ui/theme/generic-theme/colors";

type Props = {
    navigation: any;
    route: any;
};

export default function CambioPassword({ navigation, route }: Props) {

    const isRecuperoPassword = route.params?.url?.includes("recuperoPassword");
    const { colorMode } = useColorMode();

    const { mutate: cambioPassword } = authCambioPassword(
        {
            onSuccess: (data) => {
                navigation.navigate("CambioPasswordSuccess", {
                    colorMode: colorMode
                });
            },
            onError: (error) => {
                const errorResponse = error?.response?.data;
                const key = Object.keys(errorResponse as object)[0];
                const value = (errorResponse as Record<string, any>)[key][key] ? (errorResponse as Record<string, any>)[key][key] : (errorResponse as Record<string, any>)[key][0];
                const field = key === "old_password" ?
                    "passwordAttuale" : key === "new_password" ? "nuovaPassword" : "ripetiNuovaPassword";
                setError(field, { message: key + ": " + value });
            }
        }
    );

    const { mutate: recuperoPassword } = authConfermaRecuperoPassword(
        {
            onSuccess: (data) => {
                //clean the url from the params
                navigation.navigate("CambioPasswordSuccess");
            },
            onError: (error) => {
                const errorResponse = error?.response?.data as Record<string, any>;
                const key = Object.keys(errorResponse)[0];
                const value = errorResponse[key][key] ? errorResponse[key][key] : errorResponse[key][0];
                const field = key === "old_password" ?
                    "passwordAttuale" : key === "new_password" ? "nuovaPassword" : "ripetiNuovaPassword";
                setError(field, { message: key + ": " + value });
            }
        }
    );


    const { dati_profilo } = useProfilo.getState();
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,

    } = useForm(isRecuperoPassword ? {
        defaultValues: {
            nuovaPassword: "",
            ripetiNuovaPassword: "",
        }
    } : {
        defaultValues: {
            passwordAttuale: "",
            nuovaPassword: "",
            ripetiNuovaPassword: "",
        }
    });

    // const [error, setError] = React.useState<string | null>(null);
    const onSubmit = async (data: any) => {
        if (data.nuovaPassword !== data.ripetiNuovaPassword) {
            setError("ripetiNuovaPassword", { message: "Le password non coincidono" });
            setError("nuovaPassword", { message: "Le password non coincidono" });
            return;
        }

        if (data.nuovaPassword.length < 8) {
            setError("nuovaPassword", { message: "La password deve contenere almeno 8 caratteri" });
            return;
        }

        if (!data.nuovaPassword.match(/[a-z]/g)) {
            setError("nuovaPassword", { message: "La password deve contenere almeno una lettera minuscola" });
            return;
        }

        if (!data.nuovaPassword.match(/[A-Z]/g)) {
            setError("nuovaPassword", { message: "La password deve contenere almeno una lettera maiuscola" });
            return;
        }

        if (!data.nuovaPassword.match(/[0-9]/g)) {
            setError("nuovaPassword", { message: "La password deve contenere almeno un numero" });
            return;
        }

        if (isRecuperoPassword) {

            // Split the URL by "/"
            const urlParts = route.params?.url.split("/");
            //take the params from the url and send them to the api
            // Get the tokens from the URLParts array
            const uid = urlParts[4]; // MzM0
            const token = urlParts[5]; // bsas49-ceab607b593e7d0509f4cbb375765227
            recuperoPassword({
                variables: {
                    uid: uid,
                    token: token,
                    nuova_password: data.nuovaPassword,
                }
            });
            return;
        }

        cambioPassword({
            variables: {
                id_user: dati_profilo.results[0].id,
                vecchia_password: data.passwordAttuale,
                nuova_password: data.nuovaPassword,
            }
        });


    };




    return (
        <SafeAreaView style={{ width: "100%", height: "100%", backgroundColor: colorMode === "light" ? "white" : colors.dark }}>
            <View flex={1} bg="white" _dark={{
                bg: "dark"
            }} maxH="98%">
                <Pressable onPress={() => navigation.goBack()}  >
                    <HStack mt="5" ml="5" alignItems={"center"} >
                        <Icon as={<Ionicons name={"arrow-back"} />} color="primary.400" _dark={{
                            color: "white"
                        }} size="6" />
                        <Text _dark={{
                            color: "white"
                        }} color="primary.400" fontWeight={"bold"} textAlign={"center"} fontSize={"md"} >Indietro</Text>
                    </HStack>
                </Pressable>
                <View flex={1} p="2" mt="2" mx="auto" minW={{
                    base: "90%",
                    md: "600"
                }} maxW={"800"} paddingLeft={"5%"} maxH={{
                    base: "95%",
                    md: "90%"
                }} paddingRight={"5%"} >
                    <Heading
                        _dark={{
                            color: "white"
                        }}
                        lineHeight={"xs"}
                        color="dark"
                        fontWeight={"800"}
                        fontSize={"4xl"}
                        mb="2"
                        fontFamily={"body"}
                        textAlign={"left"}
                    >
                        Cambio Password
                    </Heading>
                    <Heading
                        _dark={{
                            color: "white"
                        }}
                        color="dark"
                        fontWeight={"500"}
                        fontSize={"md"}
                        lineHeight={"xs"}
                        fontFamily={"body"}
                        textAlign={"left"}
                    >
                        La tua password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola e un numero.
                    </Heading>
                    {/* <Center w="100%" mt="10"> */}
                    <Box w="100%" mt="5" alignSelf={"center"} justifyContent={"center"}>
                        <VStack w="full" space={3} >
                            {!isRecuperoPassword ? <FormControl isInvalid={
                                "passwordAttuale" in errors
                            } colorScheme={"white"}>
                                <FormControl.Label _text={{
                                    color: "dark",
                                    fontFamily: "body",
                                }} _dark={{
                                    _text: {
                                        color: "white"
                                    }
                                }}  >Password Attuale</FormControl.Label>
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Input

                                            onBlur={onBlur}
                                            onChangeText={onChange}
                                            value={value}
                                            placeholder="Password Attuale"
                                            // type={show ? "text" : "password"}
                                            _light={{
                                                placeholderTextColor: "blueGray.400",
                                            }}
                                            _focus={{
                                                bg: "white",
                                            }}
                                            _dark={{
                                                color: "dark",
                                                bg: "white"
                                            }}

                                            h={55}
                                            w="90%"
                                            bg="white"
                                            rounded={"lg"}
                                        />
                                    )}
                                    name="passwordAttuale"
                                    rules={{ required: true }}
                                />
                                <FormControl.ErrorMessage _text={{ color: "error.500", fontSize: "xs", fontWeight: 500 }}>
                                    {errors.passwordAttuale?.message}
                                </FormControl.ErrorMessage>
                            </FormControl> : ""}
                            <FormControl isInvalid={
                                "nuovaPassword" in errors
                            } colorScheme={"white"}>
                                <FormControl.Label _text={{
                                    color: "dark",
                                    fontFamily: "body",
                                }} _dark={{
                                    _text: {
                                        color: "white"
                                    }
                                }} >Nuova Password</FormControl.Label>
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Input
                                            onBlur={onBlur}
                                            onChangeText={onChange}
                                            value={value}
                                            placeholder="Nuova Password"
                                            _focus={{
                                                bg: "white",
                                            }}
                                            _dark={{
                                                color: "dark",
                                                bg: "white"
                                            }}
                                            _light={{
                                                placeholderTextColor: "blueGray.400",
                                            }}
                                            // shadow={2}
                                            h={55}
                                            w="90%"
                                            bg="white"
                                            rounded={"lg"}
                                        />
                                    )}
                                    name="nuovaPassword"
                                    rules={{ required: true }}
                                />
                                <FormControl.ErrorMessage _text={{ color: "error.500", fontSize: "xs", fontWeight: 500 }}>
                                    {errors.nuovaPassword?.message}
                                </FormControl.ErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={
                                "ripetiNuovaPassword" in errors
                            } colorScheme={"white"} >
                                <FormControl.Label _text={{
                                    color: "dark",
                                    fontFamily: "body",
                                }} _dark={{
                                    _text: {
                                        color: "white"
                                    }
                                }}  >Ripeti Nuova Password</FormControl.Label>
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Input
                                            onBlur={onBlur}
                                            onChangeText={onChange}
                                            value={value}
                                            placeholder="Ripeti Nuova Password"
                                            _focus={{
                                                bg: "white",
                                            }}
                                            _dark={{
                                                color: "dark",
                                                bg: "white"
                                            }}
                                            _light={{
                                                placeholderTextColor: "blueGray.400",
                                            }}
                                            w="90%"
                                            h={55}
                                            bg="white"
                                            rounded={"lg"}
                                        />
                                    )}
                                    name="ripetiNuovaPassword"
                                    rules={{ required: true }}
                                />
                                <FormControl.ErrorMessage _text={{ color: "error.500", fontSize: "xs", fontWeight: 500 }}>
                                    {errors.ripetiNuovaPassword?.message}
                                </FormControl.ErrorMessage>
                            </FormControl>
                        </VStack>
                    </Box>
                    {/* </Center> */}
                    <Pressable bg="primary.400" h="55" rounded={"md"} mt="auto" onPress={handleSubmit(onSubmit)}>
                        {({
                            isHovered,
                            isFocused,
                            isPressed
                        }) =>

                            <Center style={{
                                opacity: isHovered || isFocused || isPressed ? 0.8 : 1,
                                transform: isHovered || isFocused || isPressed ? [{ scale: 0.98 }] : [{ scale: 1 }]
                            }} flex={1}>
                                <Text color="white" fontWeight={"bold"} textAlign={"center"} fontSize={"lg"} >Salva Password</Text>
                            </Center>
                        }
                    </Pressable>
                </View>
            </View>
        </SafeAreaView>
    );
}
