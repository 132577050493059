import React from "react";
import { AuthNavigator } from "./auth-navigator";
import { createStackNavigator } from "@react-navigation/stack";
import { useAuth } from "../core/auth/auth-context";
import Modals from "./modals";
import MainNavigator from "./main-navigator/main-navigator";
import { View } from "react-native";
import Contattaci from "../screens/contattaci";

const Stack = createStackNavigator();

export const Root = () => {
  const status = useAuth.use.status();




  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
        animation: "none",
      }}
    >
    
            <>
              <Stack.Group>
                {status === "signOut" ? (
                  <Stack.Screen name="Auth" component={AuthNavigator} />
                ) : <Stack.Screen name="Main" component={MainNavigator} />}
              </Stack.Group>
              <Stack.Group screenOptions={{ presentation: "modal" }}>
                <Stack.Screen
                  name="Modals"
                  options={{ headerShown: false }}
                  component={Modals}
                />
              </Stack.Group>
            </>
    </Stack.Navigator>
  );
};

export const RootNavigator = () => (
  <View style={{
    flex: 1,
    height: "100%",
  }}>
    <Root />
    <Contattaci />
  </View>
);
