import React from 'react'
import {
    HStack,
    Input,
    Text,
    VStack,
} from 'native-base';
import { useDatiContatto } from '../../../../../../core/store/rubrica/dati-contatto/dati-contatto-store';

export const Nome = ({
    enableEdit
}: {
    enableEdit: boolean
}) => {
    const nome = useDatiContatto(state => state.nome);
    const setNome = useDatiContatto(state => state.setNome);
    const errors = useDatiContatto(state => state.errors);

    return (
        <VStack space={2} w="full" alignItems={"flex-start"} >
            <HStack space={2} w="full" alignItems={"center"} >

                <Text fontSize={"xl"} fontWeight="600"
                    minW="20"
                    color="dark" _dark={{ color: "white" }}>
                    Nome
                </Text>

                <Input  textContentType='name' value={nome} onChangeText={(val: string) => setNome(val)} fontSize={"lg"} fontWeight="400" lineHeight={"xs"} variant={"unstyled"} maxW={"70%"} numberOfLines={2} w="full" placeholder="Inserisci Nome" isDisabled={!enableEdit} />


            </HStack>
            {
                errors?.nome && <Text fontSize={"xs"} fontWeight={"400"} color="danger.600"  >{errors?.nome}</Text>
            }
        </VStack>
    )
}

export default React.memo(Nome);