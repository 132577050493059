import React, { useState } from 'react';
import { Modal, View } from 'react-native';
import { Button, HStack, Icon, Text } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
type CustomModalProps = {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    modalContent: string;
    onSuccess: () => void;
};

const CustomModal = (
    {
        modalVisible,
        setModalVisible,
        modalContent,
        onSuccess
    }: CustomModalProps
) => {

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                setModalVisible(!modalVisible);
            }}


        >
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <View style={{
                    backgroundColor: 'white', padding: 20, paddingTop:30, paddingBottom:30, borderRadius: 20, shadowColor: "#22272E",
                    shadowOffset: {
                        width: 0,
                        height: 12,
                    },
                    shadowOpacity: 0.58,
                    shadowRadius: 16.00,
                }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', position: "absolute", right: 7, top: 7 }}>
                        <Icon as={<Ionicons name="close" />} color={"danger.500"} size="lg" onPress={() => setModalVisible(!modalVisible)} />
                    </View>
                    <Text fontSize={"lg"} >{modalContent}</Text>
                    <Button  mt="2" bg="success.500" minW={"90%"} onPress={() => onSuccess}>
                        <Text color={"white"}>Confermo</Text>
                    </Button>
                </View>
            </View>
        </Modal>
    );
};

export default CustomModal;
