import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { colors } from '../../ui/theme/generic-theme/colors';
import { View, Text, Icon, Menu, Pressable, Divider, VStack, Spinner, Avatar } from 'native-base';
import { routes } from '../utils';
import { TabParamList, tabsIcons } from '../utils';
import { useAuth } from '../../core/auth/auth-context';
import { Ionicons } from '@expo/vector-icons';
import { getProfilo } from '../../api/profilo';
import { getFirstLetterToUppercase } from '../../utils/utils';
import { useServizio } from '../../core/store/servizio-store';
import { HStack } from 'native-base';
import { Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelectedTheme } from '../../core/selected-theme';

import { useProfilo } from '../../core/store/profilo-store';
import Contattaci from '../../screens/contattaci/index.native';
import { deleteProfilo } from '../../api/profilo/delete-profilo';
import CustomModal from '../../ui/components/modal/modal';
const Tab = createBottomTabNavigator<TabParamList>();

type BarIconType = {
  name: keyof TabParamList;
  color: string;
};

const BarIcon = ({ color, name, ...reset }: BarIconType) => {
  const Icon = tabsIcons[name];
  return <Icon size={24} color={color} {...reset} />;
};

export const TabNavigator = () => {

  const { data, isLoading, isError } = getProfilo();
  const { selectedTheme, setSelectedTheme } = useSelectedTheme();
  // const {badgeCount} = useNotification();

  return (
    <View h="full" flex={1} >
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ color }) => <BarIcon name={route.name} color={color} />,
          tabBarActiveTintColor: selectedTheme === "dark" ? colors.white : colors.primary[400],
          tabBarInactiveTintColor: colors.neutral[600],
          tabBarStyle: [
            { backgroundColor: selectedTheme === "dark" ? colors.dark : colors.white },
            { paddingBottom: Platform.OS === "ios" ? 25 : 15, paddingTop: 10, height: Platform.OS === "ios" ? 80 : 70 },
          ],

          headerShown: true,
          header: (props) => <TopMenu colorMode={selectedTheme} setSelectedTheme={setSelectedTheme} loading={isLoading || isError} profilo={data} {...props} />
        })}
      >
        <Tab.Group
        >
          {routes.map(({ name, component, label }) => {
            return (
              <Tab.Screen
                key={name}
                name={name}
                component={component}
                options={{
                  title: label,
                }}
              />
            );
          })}
        </Tab.Group>
      </Tab.Navigator>
      <Contattaci titolo="Contattaci" descrizione='Hai bisogno di aiuto oppure vuoi segnalarci un problema? Contattaci!' />
    </View>
  );
};

export const TopMenu = ({
  navigation,
  route,
  profilo,
  loading,
  setSelectedTheme,
  colorMode
}:
  {
    navigation: any,
    route: any,
    profilo: any,
    loading: boolean,
    setSelectedTheme: any,
    colorMode: string
  }) => {
  const signOut = useAuth.use.signOut();
  const { setContattaci } = useProfilo(state => state);
  const { servizio } = useServizio(state => state);
  const [showModal, setShowModal] = React.useState(false);

  const { mutate: removeProfilo } = deleteProfilo(
    {
      onSuccess: () => {
        signOut();
      }
    }
  )

  const deleteAccount = () => {
    removeProfilo({
      variables: {
        id: profilo?.results[0]?.id,
        nome: profilo?.results[0]?.first_name,
        cognome: profilo?.results[0]?.last_name,
        email: profilo?.results[0]?.email,
        servizio: servizio?.codice_servizio
      }
    })

  }
  return (
    <SafeAreaView style={{
      backgroundColor: colorMode === "dark" ? colors.dark : colors.white,
      paddingVertical: 5,
      paddingHorizontal: 5,
      maxHeight: 125,
    }} >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: 5,
          paddingLeft: 10
        }}
        bg="white"
        _dark={{
          bg: 'dark',
        }}
      >
        <Text
          pt="1"
          fontSize={"3xl"}
          lineHeight={"xs"}
          fontWeight={"bold"}
          color="neutral.800"
          _dark={{
            color: 'white',
          }}
        >
          {route?.name}
        </Text>
        {loading ?
          <Spinner color={colorMode === "light" ? "dark" : "white"} /> :
          <Menu
            rounded={"xl"}
            _dark={{ borderColor: "gray.800", borderWidth: 1, bg: "dark" }}
            placement='bottom left'
            right={3}
            top={2}
            trigger={(triggerProps) => {
              return (
                <Pressable {...triggerProps}>
                  <Avatar bg="neutral.600" size="lg" >

                    <Text fontSize="xl" fontWeight="bold" color="white" p="2" >{getFirstLetterToUppercase(profilo?.results[0]?.first_name) + "" + getFirstLetterToUppercase(profilo?.results[0]?.last_name)}</Text>
                  </Avatar>
                </Pressable>
              )
            }}
          >

            <VStack alignItems={"flex-start"} p="2" pl="4"  >
              <Text color="dark" lineHeight={"xs"} _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">{profilo?.results[0]?.first_name + " " + profilo?.results[0]?.last_name}</Text>
              <Text color="gray.800" _dark={{ color: "white" }} fontSize={"md"} fontWeight="400">{profilo?.results[0]?.email}</Text>
            </VStack>
            <Divider />
            <Menu.Item
              onPress={() => {
                setSelectedTheme(colorMode === "dark" ? "light" : "dark");
              }}
            >
              {colorMode === "dark" ?
                <HStack space={4}>
                  <Ionicons name="sunny-outline" size={24} color="white" />
                  <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Modalità Light</Text>
                </HStack>
                :
                <HStack space={4}>
                  <Icon as={<Ionicons name="moon-outline" />} color="dark" size="6" />
                  <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Modalità Dark</Text>
                </HStack>
              }
            </Menu.Item>
            <Divider />
            <Menu.Item
              onPress={() => {
                navigation.navigate("Cambio Password");
              }}
            >
              <Icon as={<Ionicons name="key-outline" />} color="dark" size="6" _dark={{
                color: "white"
              }} />
              <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Cambio Password</Text>
            </Menu.Item>
            <Divider />
            <Menu.Item
              onPress={() => {
                setContattaci(true)
              }}
            >
              <Icon as={<Ionicons name="send-outline" />} color="dark" size="6" _dark={{
                color: "white"
              }} />
              <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Contattaci</Text>
            </Menu.Item>
            <Divider />
            <Menu.Item
              onPress={() => {
                setShowModal(true)
              }}
            >
              <Icon as={<Ionicons name="remove-circle-outline" />} color="danger.500" size="6" _dark={{
                color: "white"
              }} />
              <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Elimina Account</Text>
            </Menu.Item>
            <Divider />
            <Menu.Item
              onPress={() => {
                signOut();
              }}
            >
              <Icon as={<Ionicons name="log-out-outline" />} color="dark" size="6" _dark={{
                color: "white"
              }} />
              <Text color="dark" _dark={{ color: "white" }} fontSize={"lg"} fontWeight="400">Esci</Text>
            </Menu.Item>
          </Menu>}
          <CustomModal modalVisible={showModal} setModalVisible={setShowModal} modalContent={"Sei sicuro di voler eliminare il tuo account?"} onSuccess={deleteAccount} />
      </View>
    </SafeAreaView>
  )
}

