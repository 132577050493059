import type { AxiosError } from "axios";
import { createQuery } from "react-query-kit";
import type { Servizio, ServizioResult } from "./types";
import auth from "../common/client";
import { useServizio } from "../../core/store/servizio-store";

type Response = ServizioResult[];

export const getServizi = createQuery<Response, void, AxiosError>({
  primaryKey: "servizio",
  queryFn: async () => {
    return auth
      ?.get("/api/servizi/")
      .then(function (response) {
        const servizio = useServizio.getState();
        servizio.setListaServizi(response.data);
        return response.data;
      })
      .catch((err) => console.log(err));
  },
});